import { Box, Skeleton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useContext } from 'react';
import {caroselContex} from './CaroselContex';
import { useNavigate } from 'react-router-dom';




function Live() {

  const navigate = useNavigate()
  
  const {live} = useContext(caroselContex)
   
const array = new Array(3).fill(0)
// useEffect(() => {
  
//    try{
//     setInterval(function() {
//       var blink = document.getElementById('blink');
//         blink.style.opacity = (blink.style.opacity == 0 ? 1 : 0);
//     }, 1500);
//    } catch(err){
//     console.log(err.message)
//    }
        
//   }
// , [])

if(live.length <= 0 ){
  return(  <Box sx={{my:"2rem"}}>
  <Typography  sx={{fontSize:"30px", color:"#fff", fontWeight:"700", transition:"0.5sec", msTransitionTimingFunction:"ease"}}>Active</Typography>
<Carousel autoPlay={false} interval={2000} infiniteLoop={false} showThumbs={false} showIndicators={false} showStatus={false} >

<Box  sx={{display:"flex", flexDirection:"row", alignItem:"center", overflow:"hidden"}}>
{
  array.map((data)=>{
      return( 
        <Box key={data.id} sx={{display:"flex", flexDirection:"column", mt:3, border:"solid 0.5px #24182F", borderRadius:"20px", mr:2}}>
                       <Box sx={{   "&:hover":{
                    transform:`scale(1.1)`}}}> <Skeleton variant="rounded" width={218} height={165}/></Box>
                      
                        <Box sx={{}}>
                        <Skeleton variant="text" width={"100%"} height={25}/>
                        <Skeleton variant="text" width={"100%"} height={25}/>
                        </Box>
                   
                   
                
                
                    </Box>)
  })
}

</Box>







</Carousel>
</Box>)
}

  return (
    <Box sx={{my:"2rem"}}>
          <Typography  sx={{fontSize:"30px", color:"#fff", fontWeight:"700", transition:"0.5sec", msTransitionTimingFunction:"ease"}}>Active</Typography>
 <Carousel autoPlay={false} interval={2000} infiniteLoop={false} showThumbs={false} showIndicators={false} showStatus={false} >
    
    <Box  sx={{display:"flex", flexDirection:"row", alignItem:"center", overflow:"hidden"}}>
    {
          live.map((data)=>{
              return( 
                  <Box key={data.id} sx={{display:"flex", flexDirection:"column", mt:3, border:"solid 0.5px #24182F", borderRadius:"20px", mr:2, position:"relative" }} onClick={()=>{navigate(`/launchpadNow/${data.name}`)}}>
                 <Box sx={{   "&:hover":{
            transform:`scale(1.02)`,
           }}}>
             <img src={data.image} alt="" style={{width:"218px", height:"165px", borderRadius:"20px", }}/>
           </Box>
                 
                  <Box sx={{}}>
                  
                      <Typography sx={{color:"#fff", fontSize:"14px"}}>{data.name}</Typography>
                      <Typography sx={{color:"#19669E", fontSize:"14px"}}>{data.name}</Typography>
                  </Box>
             
                  <Typography id="blink"  sx={{fontSize:"10px", color:"pink", fontWeight:"700", transition:"0.5sec", msTransitionTimingFunction:"ease", position:"absolute", top:"5px", right:"10px"}}>Live</Typography>
          
          
              </Box>)
          })
       }
  
       </Box>
     
  
  
      
      
  
      
      </Carousel>
    </Box>
   
  )
}

export default Live