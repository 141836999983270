import { Box, Typography } from '@mui/material'
import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

function Policy() {
  return (
 

    <Box>
    <Navbar/>
<Box sx={{backgroundColor:"#120C18"}}>
   <Box sx={{padding:{xs:"12px 12px", sm:"48px 12vw"}, color:"#ffffff"}}>
   <div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700"}} >Privacy Policy</div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700"}}>Modified: May 23, 2022</div>
<div style={{fontSize:"14.5px", marginBottom:"16px",}}>This Privacy Policy, as amended or otherwise changed from time to time (this “Privacy Policy”), explains the manner in which Blockchain Game Partners, Inc., dba Nftyhills   its subsidiaries, parent companies, affiliates, and other corporate entities under common ownership including, but not limited to, all websites that link this Privacy Policy (“Nftyhills”, “we“, “us“, or “our“) collects, uses, maintains and discloses user information obtained through its websites, applications, brands, services and products (collectively, “Services”). Whenever you access or use the Services, you accept the terms and conditions of this Privacy Policy. If you do not agree with or you are not comfortable with any aspect of this Privacy Policy, you should immediately discontinue access or use of the Services.</div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700"}}>Privacy Policy Updates and Changes</div><div style={{fontSize:"14.5px", marginBottom:"16px",}}>
On occasion, Nftyhills may update and change this Privacy Policy to reflect changes in law, our personal data collection and use practices, the features available through the Services, or advances in technology. If material changes are made to this Privacy Policy, the changes will be prominently posted on websites and applications. Capitalized terms not defined herein shall have the meanings ascribed to them in Nftyhills’s Terms and Conditions.</div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700"}}>Pertinent Definitions</div>
<div style={{fontSize:"14.5px", marginBottom:"16px",}}><span style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700"}}>“Personal Data” </span>means data you may give Nftyhills to identify or contact you, including, for example, your name, address, User ID, telephone number, e-mail address, any blockchain addresses, as well as any other non-public information about you that is associated with or linked to any of the foregoing data.</div>
<div style={{fontSize:"14.5px", marginBottom:"16px",}}><span style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700"}}>“Anonymous Data”</span> means data that is not associated with or linked to your Personal Data; Anonymous Data does not, by itself, permit the identification of individual persons.</div>
<div style={{fontSize:"14.5px", marginBottom:"16px",}}><span style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700"}}>Lawful Basis for Processing</span>
We only collect and process Personal Data about you where we have lawful bases to do so.  Lawful bases include consent where you have given consent, contract, and legitimate interests.</div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700"}}>Information We Collect</div><div >
• We may collect Personal Data from you, such as your first and last name, e-mail and mailing addresses, User ID, date of birth, government issued identification, and password when you register for an account on the Services (“Account”) or otherwise use the Services.</div><div>
• We may also collect other Personal Data provided by third party identity verification services or via social networking websites.
If you tell us where you are (e.g., by allowing your mobile device or computer to send us your location), we may store that information.</div><div>
• We may collect your Personal Data, such as your User ID and it may be used to track you across devices and connect you to alternative datasets that we have, including but not limited to, discord data, your wallet, and game related data.</div><div>
• Certain services, such as two-factor authentication, may require our collection of your phone number. We may associate that phone number to your mobile device identification information.</div><div>
• If you provide us feedback or contact us via e-mail, message chat or similar functionality, we will collect your name and e-mail address, as well as any other content included in the communication, in order to send you a reply.</div><div>
• Transactional information when you request information or purchase a product or service from us.</div><div>
• We also collect other types of Personal Data that you provide to us voluntarily when seeking support services, such as email, information submitted via online form, video conferencing service information, other contact information, or other information provided to support services staff.</div><div>
• We may collect other data, including but not limited to, referral URLs, your location, blockchain analytics information related to blockchain addresses you provide.</div>
    <div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Some information is collected automatically by our servers:</div><div>
• Our servers (which may be hosted by a third party service provider) collect information from you, including your browser type, operating system, Internet Protocol (“IP”) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time stamp for your visit.</div><div>
• As is true of most websites, we gather certain information automatically and store it in log files. This information includes IP addresses, browser type, Internet service provider (“ISP”), referring/exit pages, operating system, date/time stamp, and clickstream data.</div><div>
• Like many online services, we use cookies to collect information. “Cookies” are small pieces of information that a website sends to your computer’s hard drive while you are viewing the website.</div><div>
• We retain information on your behalf, such as transactional data and other session data linked to your Account.</div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>How We Use Your Information</div>

<div>We will only use your Personal Data when the law allows.
Please note that we may process your Personal Data without your knowledge or consent where this is required or permitted by law.</div>
<div>In general, Personal Data you submit to us is used either to respond to requests that you make, or to aid us in serving you better. We use your Personal Data in the following ways:</div><div>
• facilitate the creation of and secure your Account on the network;</div><div>
• identify you and perform identity verification through a service provider;</div><div>
• provide improved administration of our websites and Services;</div><div>
• improve the quality of experience when you interact with our websites and Services;</div><div>
• send you a welcome e-mail to verify ownership of the e-mail address provided when your Account is created;</div><div>
• send you administrative e-mail notifications, such as Account activity, transaction processing, security or support and maintenance advisories;</div><div>
• identify, prevent, and report potentially suspicious, fraudulent, or illegal activities;
• notify you about important changes to our Terms and Conditions or Privacy Policy; and respond to your inquiries or other requests.</div><div>
• We use IP addresses to make our website and Services more useful to you, and to perform identity verification.</div>
<div>All data collected automatically will be used to administer or improve our Services.</div><div>
• We use IP addresses to make our website and Services more useful to you, and to perform identity verification.</div><div>
• We use information from log files to analyze trends, administer the Services, track users’ movements around the Services, gather demographic information about our user base as a whole, and better tailor our Services to our users’ needs. Except as noted in this Privacy Policy, we do not link this automatically-collected data to Personal Data.</div><div>
• We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience with our Services. This type of information is collected to make the Services more useful to you and to tailor the experience with us to meet your special interests and needs.</div>
<div>We may create Anonymous Data records from Personal Data by excluding information (such as your name) that makes the data personally identifiable to you. We use this Anonymous Data to analyze request and usage patterns so that we may enhance the content and navigation of our Services. We reserve the right to use Anonymous Data and aggregated and other de-identified information for any purpose and disclose Anonymous Data to third parties in our sole discretion.</div>
<div>Nftyhills does not sell user data to any third party.</div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Retention of Personal Data.</div>
<div>Unless the law specifies a different retention period, we will only keep your Personal Data for the time strictly necessary to carry out the operations for which said Data has been collected by us.</div>
<div>Personal Data destruction procedures and methods</div>
<div>In principle, we destroy the Personal Data without delay after the purpose of collection and use of Personal Data is achieved. However, this is not the case when preservation is required according to laws and regulations.</div>
<div>The destruction procedure and method are as follows:</div><div>
• Personal Data printed on paper: shredded with a shredder or incinerate</div><div>
• Personal Data stored in electronic file format: Deleted using a technical method that cannot reproduce the record.</div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Marketing</div><div>
We strive to provide you with choices regarding certain Personal Data uses, particularly around marketing and advertising. We have established the following Personal Data control mechanisms:</div><div>
We may use your Personal Data to form a view on what Services we think may be of interest to you. You will receive marketing communications from us if (i) you have requested information from us; or (ii) use our Services, in each case, you have chosen to opt-in and not opted out of receiving marketing communications. We may transfer or otherwise make available your Personal Data to authorized third parties, including affiliates, and associate partners/business partners. We will get your express opt-in consent before we share your Personal Data with any company outside of Nftyhills for marketing purposes.</div><div>
You acknowledge by providing your personal information in connection with receiving services from Nftyhills, that you have an established business relationship with Nftyhills. As such, you provide Nftyhills with express consent to contact you using your personal information for all matters relative to your services with Nftyhills. This may include the use of autodialed or pre-recorded telephonic communications for operational communication, which may include the verification of your personal information, the collection of a debt, or any other necessary communication or confirmation in connection with any element of your ongoing service with Nftyhills. You further acknowledge that this established business relationship may extend beyond the standard eighteen months from the last transaction standard. You understand that services with Nftyhills may not have such a transaction within eighteen months, but that the relationship will still be considered as ongoing due to the specific nature of Nftyhills services. The established business relationship which is created upon the initiation of services, and the providing of personal information to Nftyhills shall continue for five years following the providing or updating of any personal information in connection with your services with Nftyhills. With respect to sales and marketing from Nftyhills specifically, you understand that the same existing business relationship standard applies.</div><div>
You can ask us or third parties to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you or by contacting us. However, where you opt out of receiving marketing messages, this will not apply to Personal Data provided to us as a result of your use of our Service, a product/service experience or other transactions. Further, Nftyhills   is not responsible for the collection and use of your Personal Data by any Company outside of Nftyhills. Nftyhills   assumes no liability whatsoever for any disclosure of Personal Data due to unauthorized third-party access or other acts of third parties, or any other acts or omissions beyond the reasonable control of Nftyhills.</div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>How we Share your Information – Personal Data shared with third parties.</div>
<div>We disclose your Personal Data as described below and as described elsewhere in this Privacy Policy. </div><div>
• It may be necessary to disclose your Personal Data to comply with any legal obligation, to defend or investigate any illegal or suspected violations of Nftyhills’s Terms and Conditions, to enforce our Terms and Conditions and this Privacy Policy, or to protect the rights, safety, and security of Nftyhills, our users, or the public. </div><div>
• We may share your Personal Data with third party service providers to provide you with the Services; to conduct quality assurance testing; to facilitate creation of accounts; to provide technical support; and to verify your identity. These third party service providers are required not to use your Personal Data other than to provide the services requested by you. </div><div>
• We may share some or all of your Personal Data with third parties (e.g. the purchaser or new owner) in connection with or during negotiation of any merger, financing, acquisition or dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets. In the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a business asset. If another company acquires Nftyhills assets, that company will possess the Personal Data collected by Nftyhills and will assume the rights and obligations regarding your Personal Data as described in this Privacy Policy. </div><div>
Our Services may contain links to other third party websites which are regulated by their own privacy policies. Nftyhills is not responsible for the privacy policies of these third party websites even if they were accessed using the links from our Services.
Other than as stated in this Privacy Policy, Nftyhills does not disclose any of your Personal Data to third parties unless required to do so by law enforcement, court order, or in compliance with legal reporting obligations. </div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Transfers outside of the EEA </div>
<div>We may share your Personal Data within Nftyhills and other companies that assist in providing Nftyhills Services which are based in various locations globally. If you are based in Europe, this will involve transferring your data outside the European Economic Area (EEA). In addition, many of our external third parties are also based outside of the EEA so their processing of your Personal Data will involve a transfer of data outside the EEA. We may transfer personal data from EEA to third countries outside of EEA, under the following conditions: </div><div>
• Contractual Obligation: Where transfers are necessary to satisfy our obligation to you under our EULA, including to provide you with our services and customer support services, and to optimize Nftyhills  ; and</div><div>
• Consent: where you have consented to the transfer of your Personal Data to a third country. </div><div>
Whenever we transfer your Personal Data out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented: </div><div>
• We will only transfer your Personal Data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, see European Commission: Adequacy of the protection of personal data in non-EU countries. </div><div>
• We may use specific contracts approved by the European Commission which give Personal Data the same protection it has in Europe. For further details, see European Commission: Model contracts for the transfer of personal data to third countries. </div><div>
• In respect of transfers to entities in the US, we may transfer Personal Data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between Europe and the US. For further details, see European Commission: EU-US Privacy Shield. </div><div>
Please contact us if you want further information on the specific mechanism used by us when transferring your Personal Data out of the EEA. If you wish to be informed about what Personal Data we hold and want it removed from our systems, please contact us at support@Nftyhills.com</div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Updating Information</div>
<div>You can update your personal or account information by contacting our support team at support@Nftyhills.com</div><div>
We will retain your information for as long as your Account is not closed or as needed to provide you access to your Account. If you wish to close your Account, open a ticket in our support center. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our Terms and Conditions. </div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Cookies</div><div>
We collect web browser information in order to enhance your experience on our Services and track how the Services are being used. Cookies are small data files that are stored on your computer’s hard drive, and in addition to using cookies to provide you with a better user experience, we use cookies to identify and prevent fraudulent activity. The information collected can include, but is not limited to, your IP address, referral URLs, the type of device you use, your operating system, the type of browser you use, geographic location, and other session data. Cookies are not permanent and will expire after a short time period of inactivity. You may opt to deactivate your cookies, but it is important to note that you may not be able to access or use some features of our Services. Please note that Nftyhills is not responsible and cannot be held liable for any loss resulting from your decision or inability to use such features.
Do Not Track (“DNT”) is an optional browser setting that allows you to express your preferences regarding tracking by advertisers and other third-parties. At this time, we do not respond to DNT signals. </div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Google Analytics</div>
<div>We use Google Analytics to collect and process data. For details on how Google uses such data, please refer to "How Google uses data when you use our partners' sites or apps" located at www.google.com/policies/privacy/partners/.
Additionally we make use of Google Analytics Advertising Features, specifically Data Collection for Google Signals. This feature allows us to collect data on our site and user traffic, which enables us to remarket that traffic through retargeting advertisements. Users may opt-out of the Google Analytics Adverting Features by using ad settings, extensions (such as those offered by Google at https://tools.google.com/dlpage/gaoptout/), or any other available means (such as the NAI's consumer opt-out located at https://optout.networkadvertising.org/?c=1).</div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Security of Information</div>
<div>We take the protection of your Personal Data seriously. We use industry-standard data encryption technology and have implemented restrictions related to the storage of and the ability to access your Personal Data. However, please note that no transmission over the Internet or method of electronic storage can be guaranteed to be 100% secure. Additionally, we aim to enforce internal regulations and rectify issues as soon as they are discovered. </div>

<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Accessing your Information</div>
<div>You will not have to pay a fee to access your Personal Data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.
We may need to request specific information from you to help us confirm your identity and ensure your rights to access your Personal Data (or to exercise any of your other rights). This is a security measure to ensure that Personal Data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated. </div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>California Residents</div>
<div>If you are a California resident, you may have certain rights with respect to your Personal Data, including the following: </div><div>
• the right to know, by way of our Privacy Policy and any specific inquiries you direct to us, the source of the Personal Data we have about you, what we use that information for, whether it is being disclosed or sold, and to whom it is being disclosed or sold;</div><div>
• the right, subject to certain exceptions, to have your personal information deleted from our possession or control; and</div><div>
• right to opt-out from the sale of your Personal Data. As described in the section above, we do not sell Personal Data and have not done so in the past 12 months. </div><div>
• right to not be discriminated against for exercising your rights under the CCPA. </div>

<div>California Civil Code Section 1798.83 permits California residents who use our website to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, or if you have any questions or concerns about your California privacy rights, our Privacy Policy, or our use of your personal information, please send an e‐mail to privacy@Nftyhills.com.
For all California residents, any such inquiries shall be responded to within forty-five (45) days.  We must verify your identity with respect to such inquiries.  Depending on the nature of the Personal Information at issue, we may require additional measures or information from you as part of that verification. </div><div>
For California residents under age 18 who have publicly posted content or information, you may request and obtain removal of such content or information pursuant to California Business and Professions Code Section 22581, provided you are a registered user of any website where this Policy is posted.  To make such a request, please send an email with a detailed description of the specific content or information to privacy@Nftyhills.com. </div>
<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Children’s Privacy</div>
<div>Unfortunately, if you’re under 18, you cannot 
use our Services without parental consent. We do not knowingly 
solicit or collect information from anyone under 18. If we become aware
 that a person under the age of 18 has provided us with Personal Data without 
 verification and parental consent, we will work to delete it immediately. </div>

<div style={{fontSize:"16px", marginBottom:"16px", fontWeight:"700", marginTop:"16px"}}>Contact Us</div>

<div>If you have any queries or complaints about our collection, use or storage of your Personal Data, or if you wish to exercise any of your rights in relation to your personal information, please contact our Data Protection Officer (“DPO”) at privacy@Nftyhills.com. We will investigate and attempt to resolve any such complaint or dispute regarding the use or disclosure of your Personal Data.
Based on the applicable laws of your country, you may have a right to request access to the Personal Data we collect from you, change, or delete the Personal Data. To request the review, update or removal of your Personal Data, please contact privacy@Nftyhills.com. </div>

  </div>

    </Box>
    <Footer/>
</Box>

</Box>


 

  )
}

export default Policy 



