import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import {
  caresolByType,
  launchpadByType,
  listCarosels,
  listCollections,
  listItems,
  listUsers,
} from "../graphql/queries";

export const caroselContex = createContext();

const CaroselType = {
  MARKETPLACE: " MARKETPLACE",
  LAUNCHPAD: "LAUNCHPAD",
};

function CaroselContex({ children }) {
  const [carosel, setCarosel] = useState([]);
  const [carosel2, setCarosel2] = useState([]);
  const [carosel3, setCarosel3] = useState([]);
  const [collections, setCollections] = useState([]);
  const [items, setItems] = useState([]);
  const [live, setLive] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [stats, setStats] = useState([]);
  const [stats2, setStats2] = useState([]);
  const [stats3, setStats3] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCaroseldata();
    fetchCollectiondata();
    fetchItemsdata();
    fetchLaunchpaddata();
    fetchStats();
    setLoading(true);
  }, []);

  const fetchStats = async () => {
    var stats = [];
    var stats2 = [];
    try {
      await axios
        .get("https://api.opensea.io/api/v1/collection/doodles-official/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.name = "Doodles";
          obj.id = "1";
          obj.image =
            "https://lh3.googleusercontent.com/7B0qai02OdHA8P_EOVK672qUliyjQdQDGNrACxs7WnTgZAkJa_wWURnIFKeOh5VTf8cfTqW3wQpozGedaC9mteKphEOtztls02RlWQ=s100-k";
          stats.push(obj);
          stats2.push(obj);
          stats3.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/cryptopunks/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.name = "Crypto Punks";
          obj.id = "2";
          obj.image =
            "https://lh3.googleusercontent.com/BdxvLseXcfl57BiuQcQYdJ64v-aI8din7WPk0Pgo3qQFhAUH-B6i-dCqqc_mCkRIzULmwzwecnohLhrcH8A9mpWIZqA7ygc52Sr81hE=s100-k";
          stats.push(obj);
          stats2.push(obj);
          stats3.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/okay-bears/stats")
        .then(function (response) {
          var obj = response.data.stats;
          obj.id = "3";
          obj.name = "Okay Bears";
          obj.image =
            "https://lh3.googleusercontent.com/cgFAvnBcqoDZCIPUgRdYGQGdPR5HFBHgXOfKsc4-r-1Cpw9a1GEnKELv48wTF5BInT6J5Fyvu-J0ZDEIjb57YaWiwsIhh7k095sxQfE=s100-k";
          stats.push(obj);
          stats2.push(obj);
          stats3.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get(
          "https://api.opensea.io/api/v1/collection/mutant-ape-yacht-club/stats"
        )
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.name = "Mutant Ape Yacht Club";
          obj.id = "4";
          obj.image =
            "https://lh3.googleusercontent.com/lHexKRMpw-aoSyB1WdFBff5yfANLReFxHzt1DOj_sg7mS14yARpuvYcUtsyyx-Nkpk6WTcUPFoG53VnLJezYi8hAs0OxNZwlw6Y-dmI=s100-k";
          stats.push(obj);
          stats2.push(obj);
          stats3.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/proof-moonbirds/stats")
        .then(function (response) {
          var obj = response.data.stats;
          obj.name = "Moon Birds";
          obj.id = "5";
          obj.image =
            "https://lh3.googleusercontent.com/H-eyNE1MwL5ohL-tCfn_Xa1Sl9M9B4612tLYeUlQubzt4ewhr4huJIR5OLuyO3Z5PpJFSwdm7rq-TikAh7f5eUw338A2cy6HRH75=s100-k";
          stats.push(obj);
          stats2.push(obj);
          stats3.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/sandbox/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.name = "Sandbox";
          obj.id = "6";
          obj.image =
            "https://lh3.googleusercontent.com/SXH8tW1siikB80rwCRnjm1a5xM_MwTg9Xl9Db6mioIk9HIlDM09pVoSR7GKJgS6ulSUpgW9BDtMk_ePX_NKgO9A=s100-k";
          stats.push(obj);
          stats2.push(obj);
          stats3.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/thepotatoz/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;

          obj.name = "The Potatoz";
          obj.id = "7";
          obj.image =
            "https://openseauserdata.com/files/7f3cd1b5f71225f4aab4efab5542f253.gif";
          stats.push(obj);
          stats2.push(obj);
          stats3.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/shellzorb/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.id = "8";
          obj.name = "Shellz Orb";
          obj.image =
            "https://openseauserdata.com/files/1757d04c6b053484008b1b7059a4806c.png";
          stats.push(obj);
          stats2.push(obj);
          stats3.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/veefriends/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.id = "9";
          obj.name = "VeeFriends";
          obj.image =
            "https://lh3.googleusercontent.com/5y-UCAXiNOFXH551w5bWdZEYOCdHPwbqmcKb-xa3uVQEjQgxvih3HtZWSmzqDqd0uk7kIqFrZhw32Gt6xPBFg4t_n9BKhpou-dwnOg=s64";
          stats.push(obj);
          stats2.push(obj);
          stats3.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/cool-cats-nft/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.name = "Cool Cats";
          obj.id = "10";
          obj.image =
            "https://lh3.googleusercontent.com/LIov33kogXOK4XZd2ESj29sqm_Hww5JSdO7AFn5wjt8xgnJJ0UpNV9yITqxra3s_LMEW1AnnrgOVB_hDpjJRA1uF4skI5Sdi_9rULi8=s100-k";
          stats.push(obj);
          stats3.push(obj);
          //  stats2.push(obj)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/mfers/stats")
        .then(function (response) {
          var obj = response.data.stats;
          obj.id = "11";
          obj.name = "Mfers";
          obj.image =
            "https://lh3.googleusercontent.com/J2iIgy5_gmA8IS6sXGKGZeFVZwhldQylk7w7fLepTE9S7ICPCn_dlo8kypX8Ju0N6wvLVOKsbP_7bNGd8cpKmWhFQmqMXOC8q2sOdqw=s168";
          stats.push(obj);
          stats3.push(obj);
          //  stats2.push(obj)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get(
          "https://api.opensea.io/api/v1/collection/world-of-women-nft/stats"
        )
        .then(function (response) {
          // handle success

          var obj = response.data.stats;
          obj.id = "12";
          obj.name = "World Of Women";
          obj.image =
            "https://lh3.googleusercontent.com/EFAQpIktMBU5SU0TqSdPWZ4byHr3hFirL_mATsR8KWhM5z-GJljX8E73V933lkyKgv2SAFlfRRjGsWvWbQQmJAwu3F2FDXVa1C9F=s100-k";
          stats.push(obj);
          stats3.push(obj);
          //  stats2.push(obj)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/azuki/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.id = "13";
          obj.name = "Azuki";
          obj.image =
            "https://lh3.googleusercontent.com/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT=s100-k";
          stats.push(obj);
          //  stats2.push(obj)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/godhatesnftees/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.id = "14";
          obj.name = "Godhatesnftees";
          obj.image =
            "https://openseauserdata.com/files/3c5f04e90a7caf0384bffc1e457fb309.jpg";
          stats.push(obj);
          //  stats2.push(obj)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/degods/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.id = "15";
          obj.name = "Degods";
          obj.image =
            "https://lh3.googleusercontent.com/OYvE-daPlxRCqry6lJY2SaXKodbD1-2jTucE7l2iEb50no017kXDuu9uYVt44To6930sL3xtSrm3XpSedtpXbcIydIv-xK0WLIxx=s100-k";
          stats.push(obj);
          //  stats2.push(obj)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/decentraland/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.id = "16";
          obj.name = "Decentraland";
          obj.image =
            "https://lh3.googleusercontent.com/5KIxEGmnAiL5psnMCSLPlfSxDxfRSk4sTQRSyhPdgnu70nGb2YsuVxTmO2iKEkOZOfq476Bl1hAu6aJIKjs1myY=s100-k";
          stats.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/infected-mob/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.id = "17";
          obj.name = "Infected Mob";
          obj.image =
            "https://openseauserdata.com/files/543630193cf32ac09c0356e767d2891e.gif";
          stats.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/superrare/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.id = "18";
          obj.name = "Superrare";
          obj.image =
            "https://lh3.googleusercontent.com/-1VbTF_qOdwTUTxW8KzJbFcMX0-mDF-BJM-gmmRl8ihvoo53PF_1z1m1snLXxwcxVFyJH7wk_kouq-KVyB55N9U=s100-k";
          stats.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/babbys/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.id = "19";
          obj.name = "Babbys";
          obj.image =
            "https://lh3.googleusercontent.com/TjTseridw6eVinTDuAF3oj94uGkqLxefdKFOosok6F6uCnbRAQJqGyTjY8s-JRo3-HmBwC48XXRKcD-nAprPeFOAnDYFNjyVOClRbw=s100-k";
          stats.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      await axios
        .get("https://api.opensea.io/api/v1/collection/ens/stats")
        .then(function (response) {
          // handle success
          var obj = response.data.stats;
          obj.id = "20";
          obj.name = "ENS";
          obj.image =
            "https://lh3.googleusercontent.com/0cOqWoYA7xL9CkUjGlxsjreSYBdrUBE0c6EO1COG4XE8UeP-Z30ckqUNiL872zHQHQU5MUNMNhfDpyXIP17hRSC5HQ=s100-k";
          stats.push(obj);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      console.log(stats);
      setStats(stats);
      setStats2(stats2);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCaroseldata = async () => {
    try {
      // Switch authMode to API_KEY for public access
      const data = await API.graphql({
        query: caresolByType,
        variables: { type: "MARKETPLACE" },
      });

      const data2 = await API.graphql({
        query: caresolByType,
        variables: { type: "LAUNCHPAD" },
      });
      const data3 = await API.graphql({
        query: caresolByType,
        variables: { type: "TOPDROP" },
      });

      const carosels = data.data.caresolByType.items;

      const carosels2 = data2.data.caresolByType.items;
      const carosels3 = data3.data.caresolByType.items;

      setCarosel(carosels);
      setCarosel2(carosels2);
      setCarosel3(carosels3);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCollectiondata = async () => {
    try {
      // Switch authMode to API_KEY for public access
      const { data } = await API.graphql({
        query: listCollections,
        authMode: "API_KEY",
      });
      const collections = data.listCollections.items;

      setCollections(collections);
      console.log(collections);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchItemsdata = async () => {
    try {
      // Switch authMode to API_KEY for public access
      const { data } = await API.graphql({
        query: listItems,
        authMode: "API_KEY",
      });
      const items = data.listItems.items;

      setItems(items);
    } catch (err) {
      console.log(err);
    }
  };



  const fetchLaunchpaddata = async () => {
    try {
      // Switch authMode to API_KEY for public access
      const data = await API.graphql({
        query: launchpadByType,
        variables: { type: "ACTIVE" },
      });

      const data2 = await API.graphql({
        query: launchpadByType,
        variables: { type: "FEATURED" },
      });
      const data3 = await API.graphql({
        query: launchpadByType,
        variables: { type: "UPCOMING" },
      });

      const live = data.data.LaunchpadByType.items;

      const featured = data2.data.LaunchpadByType.items;
      const upcoming = data3.data.LaunchpadByType.items;

      setLive(live);
      setFeatured(featured);
      setUpcoming(upcoming);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <caroselContex.Provider
      value={{
        carosel,
        collections,
        items,
        carosel2,
        carosel3,
        live,
        featured,
        upcoming,
        stats,
        stats2,
        stats3,
        loading,
      }}
    >
      {children}
    </caroselContex.Provider>
  );
}

export default CaroselContex;
