import { Box, Grid, InputBase, Typography } from '@mui/material'
import React from 'react'
import HomeFooter from './HomeFooter'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import degentownlpd from "../img/degentownlpd.gif"
import CategoryIcon from '@mui/icons-material/Category';
import SearchIcon from '@mui/icons-material/Search';
import AccountCard from './AccountCard'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';

function Account() {
  return (
    <Box>
    <Navbar/>
<Box sx={{backgroundColor:"#120C18", pl:2, pr:2}}>
<Grid container>
<Grid item xs={0} sm={2} sx={{display:{xs:"none", sm:"block"}}}><Sidebar/></Grid>

<Grid item xs={12} sm={10} >
<Box sx={{px:"3rem", py:"5rem", display:"flex", flexDirection:'column'}}>
  <img src={degentownlpd} style={{width:"60px", height:"60px", borderRadius:"50%"}} alt=""/>
  <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}}><Typography sx={{color:"#fff", mt:"0.5rem", fontWeight:"bold"}}>DANNYSECURE</Typography> <VerifiedOutlinedIcon sx={{color:"blue", fontSize:'16px'}}/></Box>
  <Box><Typography sx={{color:"#fff", fontSize:"12px"}}>Joined November 12 2021</Typography></Box>

</Box>

<Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
  <Box sx={{borderTop:"solid 0.5px #24182F", width:"90%" ,py:"1rem"}}>
    <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", width:{xs:"100%", sm:"80%"}}}>
     <Box sx={{display:{xs:"none",sm:"flex"}, flexDirection:"row", alignItems:"center"}}><CategoryIcon sx={{color:"#1231B0", fontSize:"18px"}}/> <Typography sx={{color:"#1231B0",ml:"1rem", fontSize:"18px"}}>Collected Items</Typography></Box><Box
          sx={{
            borderRadius: "20px",
            width: {xs:"100%", sm:"60%"}, 
            backgroundColor: "#4E4E55",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "4px",

        
          }}
        >
          <SearchIcon sx={{ marginRight: "10px", color: "#000000" }} />
          <InputBase placeholder="search items" sx={{width:"100%"}} onChange={(e)=>{
          
            } } /> </Box></Box>
  </Box>

  <Box sx={{mt:"2rem", display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:"center"}}>
  
 
<AccountCard/>
<AccountCard/>
<AccountCard/>
<AccountCard/>
<AccountCard/>
<AccountCard/>
<AccountCard/>
<AccountCard/>

  

  
    
  </Box>

</Box>


{/* <HomeFooter/> */}

</Grid>
</Grid>


  </Box>

  </Box>
  )
}

export default Account