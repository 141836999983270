import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from "../img/logo.png"
import flag from "../img/flag.PNG"
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import discord from "../img/discord.png"

function HomeFooter() {
  const navigate = useNavigate()
  return ( 
<Grid container sx={{px:{xs:0, sm:2}, py:10,mb:"5rem", mt:"3rem"}}>
    <Grid item xs={12} sm={5} >
        <Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-between", height:"100%", mb:"2rem" }}>
        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", }} onClick={()=>{navigate("/")}} >
            <img src={logo} alt="" style={{width:"200px", height:"100px", mr:2}} />
            <Typography sx={{color:"#ffffff", fontSize:"22px"}}></Typography>
        </Box>
        <Box
          sx={{
         
          
            flexDirection: "row",
            paddingRight: "30px",
            justifyContent: "center",
            alignItems: "center",
            display:{xs:"none", sm:"flex"}
          }}
        >
          <p style={{ color: "#C7c0ce" }}>Language:</p>
          <Button
            sx={{
              backgroundColor: "#C9D6DF",
              width: "94px",
              height: "32px",
              color: "#383A45",
              marginLeft: "7px",
              borderRadius: "5px",
              flexDirection:"row"
            }}
            variant="contained"
          
          >
            <img src={flag} style={{marginRight:"7px"}} alt=""/>
            ENG
          </Button>
        </Box>
        </Box>
    </Grid>

    <Grid item xs={12} sm={7}>
    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <Box sx={{}} >
            <Typography sx={{color:"#ffffff", fontSize:"18px", mb:1, }}>Marketplace</Typography>
       <Box sx={{my:0.6}}><Link to="/collections" style={{textDecoration:"none", color: "#C7c0ce"}}><Typography >Collections</Typography></Link></Box>  
       <Box sx={{my:0.6}}><Link to="/launchpad" style={{textDecoration:"none", color: "#C7c0ce"}}><Box >Launchpad</Box></Link></Box>   
        <Box sx={{my:0.6}}><Link to="/applylisting" style={{textDecoration:"none", color: "#C7c0ce"}}><Box >Listing</Box></Link> 
        </Box> 
        </Box>

        <Box sx={{ml:"0.8rem"}}>
            <Typography sx={{color:"#ffffff", fontSize:"18px", mb:1}}>Stats</Typography>
        <Box sx={{my:0.6}}><Link to="/stats" style={{textDecoration:"none", color: "#C7c0ce"}}><Box>Rankings</Box></Link> </Box> 
       
          

        </Box>

        <Box>
            <Typography sx={{color:"#ffffff" , fontSize:"18px", mb:1}}>Resources</Typography>
         <Box sx={{my:0.6}}><Link to="/about" style={{textDecoration:"none", color: "#C7c0ce"}}><Box >About Us</Box></Link> </Box>
         <Box sx={{my:0.6}}><Link to="/career" style={{textDecoration:"none", color: "#C7c0ce"}}><Box >Careers</Box></Link> </Box>
        <Box sx={{my:0.6}}> <Link to="/education" style={{textDecoration:"none", color: "#C7c0ce"}}><Box >Education</Box></Link> </Box>
        <Box sx={{my:0.6}}> <Link to="/policy" style={{textDecoration:"none", color: "#C7c0ce"}}><Box >Copyright Policy</Box></Link> </Box>
         <Box sx={{my:0.6}}><Link to="/terms&conditions" style={{textDecoration:"none", color: "#C7c0ce"}}><Box >Terms of Service</Box></Link> </Box>
     
        </Box>
        
        
    </Box>

    <Box
          sx={{
         
            display:{xs:"flex", sm:"none"},
            flexDirection: "row",
            paddingRight: "30px",
            justifyContent: "start",
            alignItems: "center",
           mt:"6rem"

          }}
        >
          <p style={{ color: "#C7c0ce" }}>Language:</p>
          <Button
            sx={{
              backgroundColor: "#C9D6DF",
              width: "94px",
              height: "32px",
              color: "#383A45",
              marginLeft: "7px",
              borderRadius: "5px",
              flexDirection:"row"
            }}
            variant="contained"
          
          >
            <img src={flag} style={{marginRight:"7px"}} alt=""/>
            ENG
          </Button>

         
        </Box>
          <a href="https://twitter.com/nfthills1" target="_blank"> <TwitterIcon sx={{mr:"1rem",fontWeight:"300", fontSize:"19px", color:"#3758A4"}}/></a> 
         <a href="https://discord.gg/P7VPdYg97m"   target="_blank"> <img src={discord}  style={{height:"20px", width:"20px",}}/></a> 
    </Grid>
</Grid>
  )
}

export default HomeFooter