import { Box, Button,  Typography } from '@mui/material'
import React, { useEffect } from 'react'
import logo from "../img/logo.png"
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Search from './Search';
import { useNavigate } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { connectors } from './connectors';
import { toHex, truncateAddress } from "./utils";
import Modal from './Modal';

function Navbar() {

  const [signedInUser, setSignedInUser] = React.useState(false);
  const [show, setShow]= useState(true)
  const [openModal, setOpenModal] = useState(false);
  const [hasMetamask, setHasMetamask] = useState(false);


  const {
    library,
    chainId,
    account,
    activate,
    deactivate,
    active
  } = useWeb3React();


  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      setHasMetamask(true);
    }
  });


  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
 
  };

  const disconnect = () => {
    refreshState();
    deactivate();
  };




  React.useEffect(() => {
    authListener();
  });
  async function authListener() {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          return setSignedInUser(true);
        case "signOut":
          return setSignedInUser(false);
      }
    });
    try {
      await Auth.currentAuthenticatedUser();
      setSignedInUser(true);
    } catch (err) {}
  }


  const navigate = useNavigate()
  return (
    <Box sx={{display:"flex", flexDirection:"row", backgroundColor:"#120C18", position:"sticky",top:"0px", zIndex:"10", justifyContent:"space-between", alignItems:"center", padding:"12px" }}>
        {show &&<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", }}>
          <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}} onClick={()=>{navigate("/")}}>
          <img src={logo} alt="" style={{width:"100px", height:"50px", mr:2}} /><Typography sx={{fontSize:"20px", color:"#fff",display:{xs:"none", sm:"block"} }}></Typography>
          </Box>
           
           
        </Box>}
           < Box sx={{width:"45%", display:{xs:"none", sm:"block"}}}><Search/></Box>
       {

        
        !show &&  <Box sx={{display:{xs:"block", sm:"none",  width:"80%"}}}><Search/></Box> } 
 
   
<Box sx={{display:'flex', flexDirection:"row", alignItems:"center"}}>
< SearchIcon sx={{color:"#fff", ml:"0.5rem", display:{xs:"block", sm:"none"}}} onClick={()=>{setShow(!show)}}/>
{ active &&
     show && <Button variant="outlined"  onClick={disconnect}>
  {truncateAddress(account)}
</Button>}

        { !active && show && <div><Button variant="outlined"   onClick={() => setOpenModal(true)} >
  Connect Wallet
</Button>

<Modal
      open={openModal} 
      onClose={() => setOpenModal(false)} />
</div> }
</Box>
     
    </Box>
  )
}

export default Navbar