import { Box, Grid, Skeleton, Typography } from '@mui/material'
import React, { useState } from 'react'
import HomeFooter from './HomeFooter'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { useContext } from 'react';
import {caroselContex} from './CaroselContex';
import { useNavigate } from 'react-router-dom'


function Collections() {

    const {collections} = useContext(caroselContex)
    const navigate = useNavigate()
    const array = new Array(50).fill(0)

    if(collections.length <= 0){
      return(
        <Box>
        <Navbar/>

        <Box sx={{backgroundColor:"#120C18", pl:2, pr:2}}>
<Grid container>
  <Grid item xs={0} sm={2} sx={{display:{xs:"none",sm:"block"}}}><Sidebar/></Grid>
  
  <Grid item xs={12} sm={10}>
  <Grid container spacing={2.5} sx={{pl:"1rem"}}>
  
{array.map((data)=>{
    return( 
    <Grid key={data.id} item xs={12} sm={6}  sx={{width:"100%"}} onClick={()=>{navigate(`/collections/${data.name}`)}} >

        <Box sx={{mb:"1rem" , borderRadius:"15px", border:"solid 0.5px #24182F", position:"relative"}}>
         
         <Box  sx={{position:"relative", height:"288px" , "&:hover":{transform:`scale(1.01)`}}}>
        
        
 
           <Skeleton variant="rounded" width={"100%"} height={"100%"}   />
         
        
         </Box>
       
       <Box sx={{px:"12px",display:"flex", flexDirection:"column", alignItems:"center"}}>
        <Skeleton variant="text" width={"80%"} height={30}/>
        <Skeleton variant="text" width={"80%"} height={30}/>
       
       </Box>
       <Skeleton variant="rounded" sx={{width:"100px",borderRadius:"10px", height:"100px", border:"solid 0.5px #24182F", position:"absolute", bottom:"0px", left:"10px", zIndex:"8"}}/>
       </Box>
       
        </Grid>)
})

}
 
    
  
    
      
    </Grid>
  <HomeFooter/>
 
  </Grid>
</Grid>


    </Box>


    </Box>
      )
    }

   
  return (
   
    <Box>
        <Navbar/>

        <Box sx={{backgroundColor:"#120C18", pl:2, pr:2}}>
<Grid container>
  <Grid item xs={0} sm={2} sx={{display:{xs:"none",sm:"block"}}}><Sidebar/></Grid>
  
  <Grid item xs={12} sm={10}>
  <Grid container spacing={2.5} sx={{pl:"1rem"}}>
  
{collections.map((data)=>{
    return( 
    <Grid key={data.id} item xs={12} sm={6}  sx={{width:"100%"}} onClick={()=>{navigate(`/collections/${data.name}`)}} >

        <Box sx={{mb:"1rem" , borderRadius:"15px", border:"solid 0.5px #24182F", position:"relative"}}>
         
         <Box  sx={{position:"relative", height:"288px" , "&:hover":{transform:`scale(1.01)`}}}>
        
        
 
           
         <img src={data.backgroundimage} alt="" style={{ width:"100%", height:"100%", borderRadius:"15px"} }/>
        
         </Box>
       
       <Box sx={{px:"12px",display:"flex", flexDirection:"column", alignItems:"center"}}>
       <Typography sx={{color:"#fff" , fontWeight:"700", fontSize:"16px"}}>{data.name}</Typography>
       <Typography sx={{color:"#1231B0", fontSize:"12px"}}>{data.name}</Typography>
       
       </Box>
       <img src={data.image} style={{width:"100px",borderRadius:"10px", height:"100px", border:"solid 0.5px #24182F", position:"absolute", bottom:"0px", left:"10px", zIndex:"8"}}/>
       </Box>
       
        </Grid>)
})

}
 
    
  
    
      
    </Grid>
  <HomeFooter/>
 
  </Grid>
</Grid>


    </Box>


    </Box>
 
  )
}

export default Collections