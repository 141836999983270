import { Box, Button, Grid, InputBase, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import HomeFooter from './HomeFooter'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import degentownlpd from "../img/degentownlpd.gif"
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { withAuthenticator } from "@aws-amplify/ui-react";
import uuid from 'react-uuid'
import {
    NavLink,
    Outlet
  } from "react-router-dom";
import { useState } from 'react'
import Axios from 'axios'
import { Auth, Hub } from 'aws-amplify'
import config from '../aws-exports'
import { useRef } from 'react'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useNavigate } from 'react-router-dom'
import welcome from "../img/welcome.jpg"
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { API, graphqlOperation } from "aws-amplify";
import { listUsers, usersByUsername } from '../graphql/queries'




function Dashboard() {
   const [show, setShow] = useState(false)
   const [hover, setHover] = useState(false)
   const [datett, setDatett] = useState("")
   const [response, setResponse] = useState([])
   const [user, setUser] = useState({})
   
  const [signedInUser, setSignedInUser] = React.useState(false);
 
 
   const [accountDetails, setAccountDetails] = useState({ 
    username: "", email:"", twitter:"", description: "", 
    image: "", backgroundimage: ""});
  const navigate = useNavigate ()
  
  var {username, email, twitter, description, images, backgroundimage} =accountDetails
  
  useEffect(() => {


   

    var date = new Date();
    var datett= date.toDateString()
   
    setDatett(datett)
     
    fetchuser()
    fetchUserdata()

 
    
   
   },[])


   React.useEffect(() => {
    authListener();
  });
  async function authListener() {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          return setSignedInUser(true);
        case "signOut":
          return setSignedInUser(false);
      }
    });
    try {
      await Auth.currentAuthenticatedUser();
      setSignedInUser(true);
    } catch (err) {}
  }





   const  fetchuser= async() => {
    const user = await Auth.currentAuthenticatedUser();
      setUser(user)
   }

   
 

   const fetchUserdata = async () => {
    try {

      const user = await Auth.currentAuthenticatedUser();
      accountDetails.username = user.username;
      const { data } = await API.graphql({
        query: usersByUsername,
        variables: { username: user.username },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    
      const userdata = data.usersByUsername.items[0];
       setAccountDetails(userdata)
          
    } catch (err) {
      console.log(err);
    }
  };
  

  return (
    <Box>
    <Navbar/>
<Box sx={{backgroundColor:"#120C18", pl:2, pr:2}}>
<Grid container>
<Grid item xs={0} sm={2} sx={{display:{xs:"none", sm:"block"}}}><Sidebar/></Grid>

<Grid item xs={12} sm={10} >

  
<Box sx={{px:{xs:"1rem",sm:"3rem"}, py:"5rem", display:"flex", flexDirection:'column',height:"40vh" ,backgroundImage: accountDetails.backgroundimage? `url(${ accountDetails.backgroundimage})` :`url(${welcome})`, backgroundRepeat:"no-repeat", width:"100%", backgroundSize:"100% 40vh", position:"relative"}}>
    <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between",}}>
   <Typography></Typography>
  
    <Box sx={{position:"absolute", zIndex:"10", top:"10px", right:"10px" ,}}  >
  
    {<ModeEditOutlineOutlinedIcon  sx={{color:"#1231B0", "&:hover":{color:"#fff"} }} onClick={()=>{navigate("/edit")}}/>} 
  
   
    </Box>
  
  <Box sx={{position:"absolute", bottom:"-100px", zIndex:"5", display:"flex", flexDirection:"column", alignItems:"center"}}>
 
  {accountDetails.images ? <img src={accountDetails.images} style={{width:"100px", height:"100px", borderRadius:"10px", color:"#fff", border:"5px solid #fff"}} /> :<AccountBoxOutlinedIcon sx={{width:"100px", height:"100px", borderRadius:"50%", color:"#fff", }} alt=""/>} 
   
   
    <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}}><Typography sx={{color:"#fff", fontWeight:"bold"}}>{user.username}</Typography> <VerifiedOutlinedIcon sx={{color:"blue", fontSize:'16px'}}/></Box>
  <Box><Typography sx={{color:"#fff", fontSize:"12px"}}> {datett}</Typography></Box>
 
  
  </Box>
    
    
    </Box>


</Box>

<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-end", mt:"0.5rem"}}>
   <TwitterIcon  sx={{color:"#1DA1F2", fontSize:"24px", }}/>
    <FacebookIcon  sx={{color:"#3758A4", fontSize:"24px", ml:"0.5rem"}} />
    <InstagramIcon  sx={{color:"#9934B1", fontSize:"24px", ml:"0.5rem"}} />
    { signedInUser &&
     <Button variant="outlined" sx={{ml:"0.5rem"}} onClick={()=>{ Auth.signOut(); navigate("/")}}>
  Sign Out
</Button>}
</Box>

{}

<Box>
<Box sx={{mb:"2rem", ml:"5%", display:"flex", flexDirection:"row", alignItem:"center", mt:"6rem", flexWrap:"wrap"}}>
 <NavLink to='/dashboard/collections' style={({isActive})=>{
    return {
        color:isActive ? "#1231B0" :"#fff",  
        fontSize:"18px",
        fontWeight:"700",
        textDecoration:"none",
        marginRight:"1rem",
        marginBottom:"1rem"
    }
 }}><Button variant="outlined">Create NFT</Button></NavLink> 

<NavLink to='/dashboard/created' style={({isActive})=>{
    return {
        color:isActive ? "#1231B0" :"#fff",  
        fontSize:"18px",
        fontWeight:"700",
        textDecoration:"none",
        marginRight:"1rem",
        marginBottom:"1rem"

    }
 }}><Button variant="outlined">My ASSETS</Button></NavLink> 



<NavLink to='/dashboard/wallet' style={({isActive})=>{
    return {
        color:isActive ? "#1231B0" :"#fff",  
        fontSize:"18px",
        fontWeight:"700",
        textDecoration:"none",
        marginRight:"1rem",
        marginBottom:"1rem"
    }
 }}><Button variant="outlined">Wallet</Button></NavLink> 

 
</Box>
<Typography sx={{fontSize:"16px", ml:"5%", color:"#fff", mb:"0.5rem" }}>{accountDetails.description}</Typography>
</Box>


<Outlet/>


<HomeFooter/>

</Grid>
</Grid>


  </Box>

  </Box>
  )
}

export default withAuthenticator(Dashboard)