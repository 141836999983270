import { Box } from '@mui/material'
import React from 'react'
import NFT from "../img/NFT.pdf"


function Education() {
  return (
    <Box sx={{width:"100vw", height:"100vh"}}>
        <embed type="text/html" src={NFT} width="100%" height="100%" />
    </Box>
  )
}

export default Education