import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 80001],
});

const walletconnect = new WalletConnectConnector({
  rpcUrl:
    "https://eth-mainnet.g.alchemy.com/v2/-QHs-DWnXqd4KhBxEeNQsgske3P5crFd",
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
};
