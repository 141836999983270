import { Box, Typography } from "@mui/material";
import { fontWeight } from "@mui/system";
import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

function Terms() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ backgroundColor:"#120C18"}}>
        <Box
          sx={{
            padding: { xs: "12px 12px", sm: "48px 12vw" },
            color: "#ffffff",
          }}
        >
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            Terms and Conditions
          </div>
          <div
            style={{
              fontSize: "14.5px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            These Terms and Conditions constitute the User Agreement and Terms
            of Service (hereafter the “Terms & Conditions”) between Blockchain
              Partners and  Nftyhills   (referred to as “ Nftyhills” or the “Site”)
            and any person, customer, or entity (referred as the “User”)
            utilizing the website,   ETHEREUMS blockchain and any products, features
            and services provided thereon (“ Nftyhills Services”). These Terms &
            Conditions do not create any agency, partnership, or joint venture
            between  Nftyhills and User. By signing up for an account through  Nftyhills or
            any associated websites, APIs, or mobile applications, the User has
            read and consents to the Terms & Conditions. The User also agrees to
             Nftyhills’s Privacy Policy and Code of Conduct. These Terms & Conditions
            may be amended and updated from time to time at the sole discretion
            of  Nftyhills. Revised versions will be considered effective as of the
            date and time posted on the  Nftyhills Site.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            1.  Nftyhills SERVICES.{" "}
          </div>
          <div
            style={{
              fontSize: "15px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
             Nftyhills is a decentralized distributed application on a blockchain
            network, using smart contracts (each a “Smart Contract”) to enable
            its Users to own, buy, sell, transfer, and share unique digital
            rewards that can be visualized on the  Nftyhills site through the  Nftyhills
            App.{" "}
          </div>
          <div>
          </div>
          <div style={{ fontSize: "15px", marginBottom: "16px" }}>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
              }}
            >
              1.2   ETHEREUMS BLOCKCHAIN SERVICE.{" "}
            </span>
            The   ETHEREUMS Blockchain Service allows each User to store NFTs, characters,
            digital rewards, and other attributes on the  Ethereum Blockchain public
            ledger that allows for decentralized, immutable record of ownership.
            Each User owns the non-fungible token (an “NFT”) for any of the
            in-  items on the  Ethereum Blockchain. When a User purchases, earns,
            or receives any NFT or digital reward the Smart Contract process,
            the User owns completely and outright the NFT and/or digital reward.
            For the avoidance of doubt, the User acknowledges and agrees that
            while the User may own an NFT or digital reward, the underlying
            embedded intellectual property rights may be subject to certain
            licensing requirements, and  Nftyhills can only convey such rights to
            Users so long as  Nftyhills holds a valid license to the intellectual
            property. If such license terminates,  Nftyhills, and by extension the
            User, may not have any rights to the underlying embedded
            intellectual property in the User's NFTs or Platform Assets, and the
            underlying embedded intellectual property may be changed or removed
            at  Nftyhills's determination.{" "}
          </div>
          <div style={{ fontSize: "15px", marginBottom: "16px" }}>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
              }}
            >
              1.3  Nftyhills REWARD.
            </span>{" "}
            Each User of any   on the   ETHEREUMS blockchainor the Site or any  
            contained on the Site or associated websites, and associated  Nftyhills
              Node, may be able to earn or receive the  Nftyhills Reward. The “ Nftyhills
            Reward” consists of a representation of  Nftyhills token, or other  
            tokens and NFTs, reflected on the   ETHEREUMS blockchainand/or   Nftyhills User Dashboard 
            and the Site, which may or may not be bridged to other blockchains
            (including but not limited to Ethereum) through a minting process
            solely at the option and action of the User. When referencing the
            NFTs on the platform themselves in the singular and not specifically
            as a part of the  Nftyhills Reward, same shall be referred to herein as
            “Platform Asset.” The  Nftyhills Reward is a digital reward and not a
            Security Token. The  Nftyhills Reward is not being offered to investors
            and there is no Initial Coin Offering (ICO) to promote the  Nftyhills
            Reward. Like BTC (which is the digital reward generated from the
            Bitcoin blockchain), the  Nftyhills reward is created through a unique
            blockchain protocol. Each User that participates in the  Nftyhills
            Blockchain protocol shall receive 100% of the User’s allocated  Nftyhills
            Reward and any other digital reward or NFT, which is deposited in
            the User’s   Nftyhills User Dashboard . The  Nftyhills Reward, any NFT, and any other digital
            reward may reside solely within the  Nftyhills Blockchain, and may or may
            not have value.  Nftyhills cannot, and does not, control whether any of
            the  Nftyhills Reward, any NFT, and any other digital reward has monetary
            value on any other blockchain. On the  ETHEREUM Blockchain, the Site, or
            any associated website, neither the  Nftyhills Reward nor NFT nor other
            digital reward may be exchanged for currency.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            1.5   Nftyhills USER DASHBOARD
          </div>

          <div
            style={{
              fontSize: "15px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            1.5.1.  Nftyhills will provide each User with a   Nftyhills User Dashboard .  Nftyhills will never
            take custody or control over any NFT or digital reward stored in a
            User’s   Nftyhills User Dashboard . The   Nftyhills User Dashboard  does not store, send or receive any
            NFT or reward, but such transactions occur directly on the  Nftyhills
            Blockchain or other supported blockchains. Instead of the   Nftyhills User Dashboard ,
            the User may use any compatible digital app or wallet provided by a
            third party company that safely connects to the  Nftyhills Blockchain. A
            User may be able to use the   Nftyhills User Dashboard  or other compatible wallet to
            send and receive any NFT or digital reward with other users of the
             Nftyhills Blockchain.  Nftyhills does not offer any User the ability to
            exchange one form of currency for any other form of currency (fiat
            or digital).   Nftyhills User Dashboard  is not a custodian, exchange or money
            transmitter.
          </div>
          <div style={{ fontSize: "15px", marginBottom: "16px" }}>
            1.5.2. When a User creates a   Nftyhills User Dashboard , the   ETHEREUMS blockchainsoftware
            generates a cryptographic private key and public key pair that the
            User must use to send and receive any NFT or digital reward
            supported on the  Nftyhills Blockchain. The User is solely responsible for
            storing, outside of  Nftyhills’s Services, a backup of any User’s  Nftyhills
            App, private key(s), or transaction information that the User
            maintains in the   Nftyhills User Dashboard  or otherwise with the  Nftyhills Services. If
            the User does not backup the   Nftyhills User Dashboard , the User may not be able to
            access the  Nftyhills in the event that  Nftyhills discontinues some or all of
            the  Nftyhills Services.{" "}
          </div>
          <div style={{ fontSize: "15px", marginBottom: "16px" }}>
            1.5.3. In order for a transaction to be validated on the  Nftyhills
            Blockchain, any  Nftyhills transaction through the   Nftyhills User Dashboard  must be
            confirmed and recorded in the distributed ledger associated with the
             Nftyhills Blockchain. The   ETHEREUMS blockchainis a decentralized,
            peer-to-peer network supported by the users of the  Nftyhills Blockchain,
            which is not owned, controlled or operated by  Nftyhills.
          </div>
          <div style={{ fontSize: "15px", marginBottom: "16px" }}>
            1.5.4.  Nftyhills has no control over any NFT or digital reward (including
            the  Nftyhills Reward) generated through the   ETHEREUMS blockchainor the  Nftyhills
            App or Site, and cannot ensure that any transaction details a User
            submits via the  Nftyhills Services and   Nftyhills User Dashboard  or Site will be confirmed
            or processed on the  Nftyhills Blockchain. The User agrees and understands
            that the transaction details submitted by the User via the  Nftyhills
            Services and   Nftyhills User Dashboard  may not be completed or may be delayed by the
              ETHEREUMS blockchainor any supporting blockchain used to process the
            transaction.  Nftyhills does not guarantee that the   Nftyhills User Dashboard  can transfer
            title or right in any NFT or digital reward, and  Nftyhills makes no
            warranties of title of any kind. Once transaction details have been
            submitted to the  Nftyhills Blockchain,  Nftyhills cannot assist the User to
            cancel or otherwise modify such transaction or details.
          </div>
          <div style={{ fontSize: "15px", marginBottom: "16px" }}>
            1.5.5. In the event of a fork of the   ETHEREUMS blockchainor any other
            supported blockchain,  Nftyhills may not be able to support the User’s
            activity related to  Nftyhills or any other supported NFT or reward. The
            User agrees and understands that in the event of a fork of the  Nftyhills
            Blockchain, any transaction associated with the   Nftyhills User Dashboard  or  Nftyhills
            Services may not be completed, may be partially completed,
            incorrectly completed, or substantially delayed.  Nftyhills is not
            responsible for any loss incurred by any User caused in whole or in
            part, directly or indirectly, by a fork of the  Nftyhills Blockchain.
          </div>
          <div style={{ fontSize: "15px", marginBottom: "16px" }}>
            1.5.6. With respect to the   Nftyhills User Dashboard ,  Nftyhills does not receive or store
            a User’s   Nftyhills User Dashboard  password, or any keys, network addresses or
            transaction history.  Nftyhills cannot assist any User with   Nftyhills User Dashboard 
            password retrieval. The User is solely responsible for remembering,
            storing and keeping secret the User’s   Nftyhills User Dashboard  PIN (password), keys
            and address. Any NFT or digital reward a User has stored within its
              Nftyhills User Dashboard  may become inaccessible if the User does not know or keep
            secret its   Nftyhills User Dashboard  keys and PIN. Any third party with knowledge of
            one or more of a User’s credentials (including, without limitation,
            a backup phrase, App identifier, PIN, or keys) can dispose of the
            NFT or digital rewards in the User’s   Nftyhills User Dashboard .
          </div>
          <div style={{ fontSize: "15px", marginBottom: "16px" }}>
            1.5.7.  Nftyhills does not currently charge a fee for the   Nftyhills User Dashboard ,
            receiving, sending, or storing  Nftyhills. However,  Nftyhills reserves the
            right to do so in the future, and in such case any applicable fees
            will be displayed prior to the User incurring such fee.
            Notwithstanding, the   ETHEREUMS blockchain may have Blockchain Transaction
            Fees required to transact NFT or digital reward transactions through
            the  Nftyhills Blockchain.  Nftyhills may attempt to calculate for the User any
            Blockchain Transactions Fees, though such calculation may be
            inaccurate or excessive. The User may select a greater or lesser
            fee, and the User is solely responsible for paying any Blockchain
            Transaction Fees required on the  Nftyhills Blockchain.  Nftyhills will neither
            advance nor fund any Blockchain Transaction Fee on any User’s
            behalf, nor be responsible for any excess or insufficient fee
            calculation.
          </div>
          <div style={{ fontSize: "15px", marginBottom: "16px" }}>
            1.5.8. From time to time, there may be programs in place to allow
            User to receive a reward, in the form of  Nftyhills Reward, NFT, or
            otherwise, based on actions taken by the User, whether it be by
            referral or other program in place at the time (“Additional
            Rewards”). User understands that  Nftyhills makes no representations or
            warrants regarding the Additional Rewards. Notwithstanding or
            limiting the foregoing,  Nftyhills further makes no representations or
            warrants surrounding the operation of any  Nftyhills Services or that said
             Nftyhills Services/Additional Rewards will be error-free or
            uninterrupted. User accepts the sole responsibility for taking any
            and all actions to obtain any Additional Rewards, and holds  Nftyhills
            harmless for any opportunity cost or losses that occur as a result
            of the non-receipt of anticipated Additional Rewards, monetary or
            otherwise.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            1.6  Nftyhills LAUNCHER
          </div>
          <div style={{ fontSize: "15px", marginBottom: "16px" }}>
            The User understands and acknowledges that by downloading the
            application entitled “ Nftyhills Launcher” that it has read and understood
            and agrees to be bound to the Limited Software Warranty and License
            Agreement.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            2. PAYMENT TERMS.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            2.1. Annual License and Monthly Service Fees.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            The User shall pay a one-time  Nftyhills   Node license fee.  Nftyhills or
            any of its affiliates or third parties may be the party that sells
            the software or hardware for any  Nftyhills   Node.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            2.2. Software License.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            The User may be required to enter into a software license agreement
            with  Nftyhills or any third party for the use of any required software
            for each  Nftyhills   Node. The User shall pay all associated software
            license fees.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            2.3. Equipment Purchase.{" "}
          </div>
          <div
            style={{
              fontSize: "14.5px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            The User may enter into an equipment purchase agreement as it
            relates to the purchase of any hardware for an  Nftyhills   Node. The
            User is not required to purchase such hardware, but may use any
            equipment technology (e.g. computer, GPU, cell phone, or other
            technology) that can appropriately and safely access and connect to
            the  Nftyhills Blockchain.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            2.4. Maintenance and Hosting Fees.
          </div>
          <div
            style={{
              fontSize: "14.5px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
             Nftyhills does not maintain or host any  Nftyhills   Node, but may refer the
            User to one or more third-party hosting companies that may or may
            not be affiliated with  Nftyhills. If the User elects and is part of a
            separate hosting agreement, the User may be required to pay certain
            data center operations, maintenance and power fees (“Maintenance
            Fees”).
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            2.5. Blockchain Transaction Fees.
          </div>
          <div
            style={{
              fontSize: "14.5px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            The delivery and receipt of any of the User’s NFT or digital rewards
            through the   Nftyhills User Dashboard  may be subject to network or transaction fees
            charged by the blockchain associated with the User-selected
            algorithm (“Blockchain Transaction Fees”), which are non-refundable.
            Blockchain Transaction Fees are paid to emit, record, verify, and
            process a transaction on the blockchain. Any withdrawal or transfer
            of the User’s NFT or digital reward are subject to Blockchain
            Transaction Fees.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            2.6. Other Third-Party Fees.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            Certain digital apps, app addresses, tools, and third-party software
            and devices (“Third-Party Fees”) used by the User may also charge
            the User a fee, including a per transaction or transfer fee, which
            are non-refundable. The User is responsible for satisfying any such
            fee(s). The User should note that any such fees may significantly
            reduce the User’s rewards and therefore the User is responsible for
            managing the selection, use, rate and frequency of their receipt of
            rewards to any such Third-Party Fees.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            2.7. Taxes.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            The User is responsible for any taxes, and the User will pay for
             Nftyhills Services without any reduction for taxes. If the User is
            required by law to withhold any taxes from its payments to  Nftyhills, the
            User must provide  Nftyhills with an official tax receipt or other
            qualified documentation to support such withholding, including value
            added tax (“VAT”), if applicable. The User will be liable to pay (or
            reimburse  Nftyhills) for any taxes, interest, penalties or fines which
            may arise from any mis-declaration made by the User. The User shall
            pay  Nftyhills for all taxes and governmental fees  Nftyhills is required to
            collect or pay upon sale or delivery of  Nftyhills Services. POTENTIAL
            USERS OF NFTS OR DIGITAL REWARDS, INCLUDING BUT NOT LIMITED TO
            BITCOIN, ARE FOREWARNED OF POSSIBLE FINANCIAL LOSS AT THE TIME SUCH
            REWARDS ARE EXCHANGED FOR FIAT CURRENCY DUE TO AN UNFAVORABLE
            EXCHANGE RATE. MOREOVER, A FAVORABLE EXCHANGE RATE AT THE TIME OF
            EXCHANGE MAY RESULT IN A TAX LIABILITY. USERS SHOULD CONSULT A TAX
            ADVISOR REGARDING ANY TAX CONSEQUENCES ASSOCIATED WITH THE PURCHASE,
            SALE, TRADE, RECEIPT OR OTHER USE OF DIGITAL REWARDS.{" "}
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3. USER OBLIGATIONS
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.1. Software and Equipment Allocation.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            The User is responsible for the allocation of the User’s software
            and equipment and selected optimization decisions. The User
            acknowledges that  Nftyhills is not responsible for the selection or
            timing of blockchain protocols, nor is  Nftyhills responsible for the
            protocols selected for use in connection with  Nftyhills Services. The
            User is solely responsible for these decisions and shall monitor and
            allocate strategies through the User’s   Nftyhills User Dashboard . The User
            acknowledges that the node transactions on any blockchain may vary
            and will not be uniform across each  Nftyhills   Node.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.2. Log-in Credentials.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            The User represents and warrants that the User is responsible for
            the preservation of confidentiality of the User’s login credentials
            on the   Nftyhills User Dashboard  and any other login for the  Nftyhills Services. Login
            credentials generated for the User by  Nftyhills are for the User’s
            internal use only and the User is strictly prohibited from selling,
            transferring, or sub-licensing them to any other entity or person.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.3. Blockchain Network Risk.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            The User represents and warrants that the User accepts the risks of
            blockchain protocol and network, including instability, congestion,
            high transaction costs, network latency, information security,
            regulatory risk, and technological and operational error. The User
            understands these risks may result in delay or failure to process
            transactions and potentially high Blockchain Transaction Fees or
            Third Party Fees. The User represents and agrees that  Nftyhills is not
            responsible for any diminished  Nftyhills Services, related features, or
            capabilities resulting from blockchain network risk. In the event of
            a material increase or decrease to Blockchain Transaction Fees,
            Third Party Fees, or operational degradation, congestion, failure or
            other disruption of the blockchain network used by the User,  Nftyhills
            may, at its sole discretion and upon notice to the User, make any
            adjustments to the  Nftyhills Services.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.4. Blockchain Modification Risk.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            The User represents and warrants that the User is familiar with and
            accepts the risks associated with blockchain development and code
            changes. Blockchain technologies are still under development and may
            undergo significant changes over time. Blockchain contributors may
            make changes to features and specifications of the algorithm
            selected by the User, and may fork the   ETHEREUMS blockchainprotocol.
            Such changes may include or result in the elimination or support for
            specific algorithms and applications.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.5. Trade Compliance.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            User shall comply with all applicable import, re-import, sanctions,
            anti-boycott, export, and re-export control laws and regulations,
            including all such laws and regulations that apply to European Union
            and U.S. companies, such as the Export Administration Regulations,
            the International Traffic in Arms Regulations, and economic
            sanctions programs implemented by the Office of Foreign Assets
            Control and the European Union’s Common Foreign and Security Policy
            (collectively, “Trade Sanctions Laws”). The User represents and
            warrants that the User and the User’s financial institutions, or any
            party that owns or controls the User or the User’s financial
            institutions, are not subject to sanctions or otherwise designated
            on any list of prohibited or restricted parties, including but not
            limited to the lists maintained by the United Nations Security
            Council, the U.S. Government (e.g., the Specially Designated
            Nationals List and Foreign as Evaders List of the U.S. Department of
            Treasury, and the Entity List of the U.S. Department of Commerce),
            the European Union or its Member States, or other applicable
            government authority.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.6 Compliance with Local Laws and Regulations.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            User shall not attempt to purchase any  Nftyhills products restricted by
            local laws and regulations.  Nftyhills may from time to time geoblock or
            otherwise restrict access to this site as necessary to ensure
            compliance with laws and regulations.  Nftyhills is not responsible for
            any loss as a result of such compliance with local laws and
            regulations.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.7 Ownership Restrictions.
          </div>
          <div>
            User acknowledges and agrees that  Nftyhills, any   developer, or third
            party contact provider (or, as applicable, any licensors) own all
            legal right, title and interest in and to all elements of their
            respective intellectual property rights therein. The visual
            interfaces, graphics (including, without limitation, all art and
            drawings associated with the), design, systems, methods,
            information, computer code, software, services, “look and feel”,
            organization, compilation of the content, code, data, and all other
            elements of any content provided on the Site,   Nftyhills User Dashboard , or  Nftyhills
            Blockchain are protected by copyright, trade dress, patent, and
            trademark laws, international conventions, other relevant
            intellectual property and proprietary rights, and applicable laws.
            All such intellectual property are the property of its owners or
            licensors, and all trademarks, service marks, and trade names are
            proprietary to its owner or licensors. Except as expressly set forth
            herein, the use of the  Nftyhills Services and   Nftyhills User Dashboard  does not grant
            User any ownership of or any other rights with respect to any
            content, code, data, or other materials that you may access on or
            through the   Nftyhills User Dashboard . When a User purchases or owns a Platform
            Asset, the User owns the underlying NFT completely for as long as
            the User owns the Platform Asset, subject to the terms and
            restrictions of this Agreement and any accompanying license
            restrictions for the Platform Asset. Ownership of the NFT is
            mediated entirely by the smart contract and Ethereum Network (or any
            other applicable network): at no point may  Nftyhills seize, freeze, or
            otherwise modify the ownership of the Platform Asset. The User
            acknowledges and agrees that while the User may own a Platform
            Asset, the underlying embedded intellectual property rights may be
            subject to certain licensing requirements, and  Nftyhills can only convey
            such rights to Users so long as  Nftyhills holds a valid license to the
            intellectual property. If such license terminates,  Nftyhills, and by
            extension the User, may not have any rights to the underlying
            embedded intellectual property in the User's owned Platform Asset,
            and the underlying embedded intellectual property may be changed or
            removed at  Nftyhills's determination.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            3.8 Children.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            USER AFFIRMS HE/SHE IS OVER THE AGE OF 18, AS THE   Nftyhills USER DASHBOARD  AND  Nftyhills
            SERVICES ARE NOT INTENDED FOR CHILDREN UNDER AGE 18.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.9 Responsibility for Conduct.{" "}
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            User takes responsibility for all activities that occur under its
              Nftyhills User Dashboard  and for its use of the  Nftyhills Services, and User accepts all
            risks of any authorized or unauthorized access to its   Nftyhills User Dashboard , to
            the maximum extent permitted by law. User represents and warrants
            that the User is familiar with and accepts the risks associated with
            digital Apps and private keys, including the risks described herein.
            User is solely responsible for its own conduct while accessing or
            using the  Nftyhills Services or   Nftyhills User Dashboard , and for any consequences
            thereof. User agrees to use the  Nftyhills Services,   Nftyhills User Dashboard , and  Nftyhills
            Blockchain for purposes that are legal, proper and in accordance
            with these Terms and any applicable laws or regulations. By way of
            example, and not as a limitation, User may not, and may not allow
            any third party to: (i) send, upload, distribute or disseminate any
            unlawful, defamatory, harassing, abusive, fraudulent, obscene, or
            otherwise objectionable content; (ii) distribute viruses, worms,
            defects, Trojan horses, corrupted files, hoaxes, or any other items
            of a destructive or deceptive nature; (iii) impersonate another
            person (via the use of an email address or otherwise); (iv) upload,
            post, transmit or otherwise make available through the  Nftyhills Services
            any content that infringes the intellectual proprietary rights of
            any party; (v) use the  Nftyhills Services to violate the legal rights
            (such as rights of privacy and publicity) of others; (vi) engage in,
            promote, or encourage illegal activity (including, without
            limitation, money laundering); (vii) interfere with other users’
            enjoyment of the   Nftyhills User Dashboard  or  Nftyhills Blockchain; (viii) exploit the
             Nftyhills Services for any unauthorized commercial purpose; (ix) modify,
            adapt, translate, or reverse engineer any portion of the  Nftyhills
            Services; (x) remove any copyright, trademark or other proprietary
            rights notices contained in or on the   Nftyhills User Dashboard  or   ETHEREUMS blockchainor
            any part of it; (xi) reformat or frame any portion of the   Nftyhills User Dashboard ;
            (xii) display any content on the   Nftyhills User Dashboard  or   ETHEREUMS blockchainthat
            contains any hate-related or violent content or contains any other
            material, products or services that violate or encourage conduct
            that would violate any criminal laws, any other applicable laws, or
            any third party rights; (xiii) use any robot, spider, site
            search/retrieval application, or other device to retrieve or index
            any portion of the   Nftyhills User Dashboard  or the content posted on the   Nftyhills User Dashboard ,
            or to collect information about its users for any unauthorized
            purpose; (xiv) create user accounts by automated means or under
            false or fraudulent pretenses; (xv) trick, defraud, or mislead  Nftyhills
            or Users, with specific to an attempt to learn sensitive account
            information; (xvi) attempt to impersonate another User; (xvii)
            attempt to bypass any security measure of the Site; (xvii) copy or
            adapt the Site; or, (xviii) disparage, tarnish, or otherwise harm,
            in  Nftyhills’s sole discretion,  Nftyhills and/or the Site.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.10 Representations Made By User.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            User acknowledges and agrees that the  Nftyhills Services, including but
            not limited to the  Nftyhills   Node and  Nftyhills Reward, ARE NOT being
            sold as an investment by or of  Nftyhills. THE USER UNDERSTANDS THAT ANY
            REPRESENTATIONS MADE BY THE USER AT ANY TIME THAT ANY OF THE  Nftyhills
            SERVICES ARE AN INVESTMENT IN  Nftyhills (OR OTHERWISE, INCLUDING, BUT NOT
            LIMITED TO, IN GENERAL) TO OTHERS IN A DIRECT ATTEMPT TO MARKET  Nftyhills
            SERVICES IN COORDINATION WITH SUPPLYING USER’S REFERRAL LINK IS A
            DIRECT BREACH OF THE TERMS & CONDITIONS. A determination of same
            will be made at the sole discretion of  Nftyhills.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.11  Nftyhills   NODE REPRESENTATIONS
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
             Nftyhills and the User recognize that the SOLE AND ONLY purpose of the
             Nftyhills   Node is to support the  Nftyhills   Node Ecosystem. Nothing
            contained in this Agreement, exhibit to this Agreement, or any
            representation made outside of this Agreement, conveys on the User
            any ownership interests in  Nftyhills or its subsidiaries, or interests
            otherwise in the  Nftyhills (including, but not limited to voting rights
            in the  Nftyhills), nor any expectation of profits from the efforts of the
             Nftyhills or its principals or subsidiaries. The User understands that
            while it may from time to time receive rewards in line with the
            distribution schedule of the Node Ecosystem (“Distribution
            Rewards'') (subject to change), said reward is solely earned because
            of and as a direct result of work accomplished and performed by the
            User (i.e. work done by the  Nftyhills   Node). The User recognizes
            that should the User never actually run the  Nftyhills   Node, it will
            receive no rewards.  Nftyhills has no control of the method in which
            rewards are distributed to the  Nftyhills   Node. It is anticipated
            that the  Nftyhills   Node will be able to be sold or transferred in
            the future as Non-Fungible Tokens, however,  Nftyhills cannot and does not
            warrant or represent that the resale value of the Nodes will be
            higher than the purchase price should this event occur, nor that it
            will be legally possible to do so. Further,  Nftyhills cannot and does not
            warrant or represent that there will ever be a resale value or
            capability of the  Nftyhills   Node.{" "}
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            3.12 Play to Earn Mechanisms{" "}
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            If the User elects to engage in any play to earn mechanism
            (including, but not limited to, any mechanism that permits the User
            to play any   or use any  Nftyhills Services on the   Nftyhills User Dashboard , Site or
            otherwise), the User will be responsible to pay any and all sales,
            use, value-added or other taxes, duties and assessments now or
            hereinafter claimed or imposed by any governmental authority. The
            User will reimburse  Nftyhills for all national, federal, state, local, or
            other taxes and assessments of any jurisdiction, including
            value-added taxes and taxes as required by international tax
            treaties, customs or other import or export taxes, and amounts
            levied in lieu thereof based on charges set, services performed or
            payments made hereunder, as are now or hereafter may be imposed
            under the authority of any national, state, local or any other
            taxing jurisdiction; and (ii) shall not be entitled to deduct the
            amount of any such taxes, duties or assessments from payments made
            to us pursuant to these Terms. User is solely responsible for
            determining what, if any, taxes apply to User and play to earn
            mechanisms, and any other transactions conducted by the User.  Nftyhills
            does not act as a withholding tax agent in any circumstances. User
            further understands and acknowledges that  Nftyhills is not responsible,
            and User accepts sole responsibility for any and all missed  Nftyhills
            Reward or otherwise, regardless of the source of any error, fault or
            otherwise. Any and all play to earn mechanisms in place (including,
            but not limited to, the method, amounts or otherwise) are not
            permanent and may or may not be changed at the sole discretion of
             Nftyhills at any time.{" "}
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            4. TERMINATION
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            4.1. General.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
             Nftyhills may suspend or terminate the User’s right to access or use  Nftyhills
            Services immediately and without notice if: (i)  Nftyhills determines the
            User’s use of the  Nftyhills Services poses a security risk to  Nftyhills
            Services or any third party, could adversely impact  Nftyhills,  Nftyhills
            Services,   ETHEREUMS blockchainor any other  Nftyhills users, or could subject
             Nftyhills, its affiliates, or any third party to liability, or could be
            fraudulent; (ii) the User is in breach of these Terms & Conditions;
            (iii) the User initiated a chargeback or dispute with respect to any
            payment or purchase of the  Nftyhills Services; (iv) the User has ceased
            to operate in the ordinary course, made an assignment for the
            benefit of creditors or similar disposition assets, or become the
            subject of any bankruptcy, reorganization, liquidation, dissolution
            or similar proceeding; or (v) for any other reason at  Nftyhills’s
            discretion.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            4.2. Effect of Suspension.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            If  Nftyhills suspends the User’s right to access or use any portion or
            all of the  Nftyhills Services, the User shall remain responsible for all
            fees and charges the User incurs during the period of suspension,
            including any Maintenance Fees and Blockchain Transaction Fees; and
            the User may be in violation of the User’s hosting agreement or the
            blockchain protocols, which may prevent or limit the User’s
            entitlement or access to any results or rewards that may have
            occurred during the User’s suspension of the  Nftyhills Services. User
            agrees to pay any fee charged by  Nftyhills to reconnect the  Nftyhills
            Services.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            4.3. Effect of Termination.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            If  Nftyhills terminates the User’s right to access or use any portion or
            all of the  Nftyhills Services, then all of the User’s rights under these
            Terms & Conditions immediately terminate and the User shall remain
            responsible for all fees owed to  Nftyhills incurred through the
            termination date.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            5. GOVERNANCE
          </div>
          <div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
                marginTop: "16px",
              }}
            >
              5.1. Advisory Board.
            </span>{" "}
            The   ETHEREUMS blockchainmay have an advisory board.
          </div>
          <div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
                marginTop: "16px",
              }}
            >
              5.2. Voting for   ETHEREUMS blockchainUpdates and Amendments.{" "}
            </span>
            The   ETHEREUMS blockchainmay have voting protocols.
          </div>
          <div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
                marginTop: "16px",
              }}
            >
              5.3. Consensus Nodes.{" "}
            </span>
            The   ETHEREUMS blockchainmay have consensus nodes, which are the  Nftyhills
              Nodes that approve and validate transactions, and contribute to
            the security and stability of the network. Any User that owns an
             Nftyhills   Node involved in the consensus protocol may be rewarded
            from the   ETHEREUMS blockchainand be able to charge Blockchain
            Transaction Fees.
          </div>
          <div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
                marginTop: "16px",
              }}
            >
              5.4. Community and Decentralized Innovation.{" "}
            </span>
            The   ETHEREUMS blockchaininvites any person or entity throughout the
            world to contribute to the  Nftyhills Blockchain, including coders,
            application developers, marketers, advertisers, and service and
            maintenance providers. Accepted amendments, updates, and innovation
            from individuals or groups of the   ETHEREUMS blockchaincommunity may be
            rewarded from the   ETHEREUMS blockchainrewards.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            6. PUBLICITY
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            The User is permitted to state publicly that it is a customer or
            user of  Nftyhills, consistent with any Trademark Guidelines which may be
            adopted by  Nftyhills from time to time. The User agrees to abide by the
            Privacy Policy and Code of Conduct of  Nftyhills.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            7. REPRESENTATIONS AND WARRANTIES
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            Each party represents and warrants that: (i) it has full power and
            authority to enter into these Terms & Conditions; and (ii) it will
            comply with all laws and regulations applicable to its provision or
            use of  Nftyhills Services. USER ACKNOWLEDGES AND AGREES THAT USER DOES
            NOT RESIDE IN ANY REGION THAT EXPLICITLY BANS THE USE OF LOOT BOXES
            IN   IN ACCORDANCE WITH GAMBLING LAWS.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            8. DISCLOSURES & RISKS
          </div>
          <div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
                marginTop: "16px",
              }}
            >
              8.1 Notification.{" "}
            </span>
             Nftyhills notifies each User of certain disclosures and risks associated
            with blockchain NFT and digital rewards and their associated
            technology and protocols.  Nftyhills Services are not an investment
            product, and no action, notice, communication by any means, or
            omission by  Nftyhills shall be understood or interpreted as such.  Nftyhills
            has no influence whatsoever on the  Nftyhills Blockchain, the transactions
            and consensus protocols, or the NFTs or digital rewards, including
            the  Nftyhills Reward. Ownership of a   Nftyhills User Dashboard  or   Node or the use of
             Nftyhills Services does not represent or constitute any ownership right
            or stake, share or security, debt or equivalent right, or any right
            to receive any future revenue or form of participation in or
            relating to any blockchain or digital reward, including the  Nftyhills
            Blockchain or  Nftyhills Reward.
          </div>
          <div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
                marginTop: "16px",
              }}
            >
              8.2 Digital Rewards.
            </span>{" "}
            Digital rewards are not considered legal tender, are not issued or
            backed by any government, and have fewer regulatory protections than
            traditional currency. Moreover, digital rewards are not insured
            against theft or loss by any insurance corporation or any investor
            protection, including the Federal Deposit Insurance Corporation or
            the Securities Investor Protection Corporation.
          </div>
          <div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
                marginTop: "16px",
              }}
            >
              8.3 Market Risk.{" "}
            </span>
            The value of NFTs and digital rewards are derived from supply and
            demand in the global marketplace, which can rise or fall independent
            of any government currency. Holding NFTs and digital rewards carries
            exchange rate and other types of risk. The value of NFTs and digital
            rewards may be derived from the continued willingness of market
            participants to exchange traditional government currency for digital
            rewards, which may result in the potential for permanent and total
            loss of value of a particular digital reward should the market
            disappear. The volatility and unpredictability of the price and
            value of NFTs and digital rewards, relative to government currency,
            may result in significant loss over a short period of time.  Nftyhills
            cannot guarantee or warrant the value of any NFT, digital reward or
            blockchain, including the   ETHEREUMS blockchainand  Nftyhills Reward, and
            explicitly warns the User that that there is no reason to believe
            that any NFT or blockchain reward will increase in value, and that
            they may hold no value, decrease in value, or entirely lose value.
          </div>
          <div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
                marginTop: "16px",
              }}
            >
              8.4. Regulatory Risk
            </span>
            . Legislative and regulatory changes or actions at the state,
            federal, or international level may adversely affect the use,
            transfer, exchange, and value of virtual NFTs and blockchain
            rewards. The regulatory status of cryptographic tokens, digital
            assets and blockchain technology is unclear or unsettled in many
            jurisdictions. It is difficult to predict how or whether
            governmental authorities will regulate such technologies. It is
            likewise difficult to predict how or whether any governmental
            authority may make changes to existing laws, regulations and/or
            rules that will affect cryptographic tokens, digital assets,
            blockchain technology and its applications. Such changes could
            negatively impact the  Nftyhills Services in various ways, including, for
            example, through a determination that any of the above are regulated
            financial instruments that require registration.  Nftyhills may cease any
            distribution of any of the above, the development of the  Nftyhills  
            platform or cease operations in a jurisdiction in the event that
            governmental actions make it unlawful or commercially undesirable to
            continue to do so. The industry in which  Nftyhills operates is new, and
            may be subject to heightened oversight and scrutiny, including
            investigations or enforcement actions. There can be no assurance
            that governmental, quasi-governmental, regulatory or other similar
            types of (including banking) authorities will not examine the
            operations of  Nftyhills and/or pursue enforcement actions against  Nftyhills.
            Such governmental activities may or may not be the result of
            targeting  Nftyhills in particular. All of this may subject  Nftyhills to
            judgments, settlements, fines or penalties, or cause  Nftyhills to
            restructure its operations and activities or to cease offering
            certain products or services, all of which could harm  Nftyhills’s
            reputation or lead to higher operational costs, which may in turn
            have a material adverse effect on the  Nftyhills Services.
          </div>
          <div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
                marginTop: "16px",
              }}
            >
              8.5 Technology Risk.
            </span>
            Virtual NFT and digital reward transactions may be irreversible and
            losses due to fraudulent or accidental transactions may not be
            recoverable. Some virtual transactions are deemed to be made when
            recorded on a public ledger, which may not necessarily be the date
            or time the user initiated the transaction. The nature of such
            virtual transactions may lead to an increased risk of fraud or
            cyber-attacks.
          </div>
          <div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "16px",
                fontWeight: "700",
                marginTop: "16px",
              }}
            >
              8.6 Changes to  Nftyhills   Node Network.{" "}
            </span>
             Nftyhills and User recognize that from time to time amendments will be
            made to the  Nftyhills   Node Network.  Nftyhills and User both specifically
            agree that  Nftyhills will, from time to time, present the  Nftyhills   Node
            Network with proposed amendments (the “Proposed Amendments'') that
            the Node Network will then vote on whether to implement or not (the
            “Governance Vote”). The User understands that the Proposed
            Amendments will be made at the discretion of  Nftyhills, and  Nftyhills owes the
            User no duty or obligation to make proposals in its best interests.
            The User specifically understands that there may come a time when
             Nftyhills proposes an amendment that is not in or in direct opposition to
            the User’s best interests, financial or otherwise (including, but
            not limited to a change in the distribution calculation), and the
            User has no legal recourse against  Nftyhills should any Proposed
            Amendment be approved and implemented through the Governance Vote.
            The User’s only recourse is to vote against said Proposed Amendment.
             Nftyhills and the User both agree that one such Proposed Amendment may be
            to change the specifications, including, but not limited to the
            internet connection, Random Access Memory, Central Processing Unit
            requirements, extra space, and any other requirements needed to run
            the Nodes (the “Specifications”).  Nftyhills and User further agree that
            while the Specifications may be minimal on the effective date of
            this Agreement, this is subject to change through the Proposed
            Amendment and the Governance Vote. While  Nftyhills does represent and
            covenant that the  Nftyhills   Node will always be able to be run using
            the current specifications,  Nftyhills does not and cannot represent or
            covenant that any rewards earned (financial or otherwise) for doing
            so are not subject to change.{" "}
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop:"16px"
            }}
          >
            9. DISCLAIMER
          </div>
          <div>
            EXCEPT AS EXPRESSLY PROVIDED FOR IN THESE TERMS & CONDITIONS, TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,  Nftyhills, ITS AFFILIATES,
            AND ITS SUPPLIERS DO NOT MAKE ANY OTHER WARRANTY OF ANY KIND,
            WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE AND
            NON-INFRINGEMENT.  Nftyhills, ITS AFFILIATES, AND ITS SUPPLIERS ARE NOT
            RESPONSIBLE OR LIABLE FOR THE DELETION, FAILURE TO STORE, OR ANY
            LOSS OF ANY USER DATA, INCLUDING BLOCKCHAIN DATA, NFT AND DIGITAL
            REWARDS DERIVED, MAINTAINED, OR TRANSMITTED THROUGH USE OF  Nftyhills
            SERVICES. THE USER IS SOLELY RESPONSIBLE FOR SECURING ITS CUSTOMER
            DATA AND DIGITAL REWARDS. NEITHER  Nftyhills, ITS AFFILIATES, NOR ITS
            SUPPLIERS, WARRANTS THAT THE OPERATION OF  Nftyhills SERVICES,  Nftyhills
            BLOCKCHAIN, OR ANY OTHER SUPPORTED BLOCKCHAIN WILL BE ERROR-FREE OR
            UNINTERRUPTED.  Nftyhills, ITS AFFILIATES, AND ITS SUPPLIERS ARE NOT
            RESPONSIBLE OR LIABLE FOR ANY LOSSES OR OPPORTUNITY COSTS RESULTING
            FROM BLOCKCHAIN NETWORK AND PROTOCOL OR THIRD-PARTY SOFTWARE ISSUES,
            WHICH MAY IN TURN RESULT IN THE INABILITY TO PROCESS TRANSACTIONS ON
            ANY BLOCKCHAIN AT ALL OR WITHOUT INCURRING SUBSTANTIAL FEES.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            10. LIMITATION OF LIABILITY
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            10.1. Limitation of Indirect Liability.{" "}
          </div>
          <div>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,  Nftyhills AND THE USER
            SHALL NOT BE LIABLE UNDER THESE TERMS & CONDITIONS FOR LOST
            REVENUES, OPPORTUNITY COSTS, OR INDIRECT, SPECIAL, INCIDENTAL,
            CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF  Nftyhills KNEW OR
            SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF DIRECT
            DAMAGES DO NOT SATISFY A REMEDY. THIS LIMITATION OF LIABILITY DOES
            NOT APPLY TO VIOLATIONS OF  Nftyhills’S INTELLECTUAL PROPERTY RIGHTS,
            INDEMNIFICATION OBLIGATIONS, OR THE USER’S PAYMENT OBLIGATIONS.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            10.2. Limitation of Amount of Liability.
          </div>
          <div>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER  Nftyhills NOR
            ITS AFFILIATES OR SUPPLIERS, MAY BE HELD LIABLE UNDER THESE TERMS &
            CONDITIONS FOR MORE THAN THE AMOUNT PAID BY THE USER TO  Nftyhills UNDER
            THESE TERMS & CONDITIONS FOR THE 12 MONTH PERIOD PRECEDING THE DATE
            THE CLAIM AROSE, MINUS ANY REWARDS GENERATED OR RECEIVED BY THE USER
            AS A RESULT OF THE USE OF  Nftyhills SERVICES.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            11. INDEMNIFICATION
          </div>
          <div>
            Unless prohibited by applicable law, the User will defend and
            indemnify  Nftyhills and its Affiliates against any settlement amounts
            approved by the User and damages and costs finally awarded against
            the User and its affiliates by a court of competent jurisdiction in
            any formal legal proceeding filed by an unaffiliated third party
            before a court or government tribunal (including any appellate
            proceeding) to the extent arising from the User’s use of  Nftyhills
            Services.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            12. MISCELLANEOUS.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            12.1. Assignment.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            The User will not assign or otherwise transfer the User’s rights and
            obligations under these Terms & Conditions, without the prior
            written consent of  Nftyhills, which may be unreasonably withheld. Any
            assignment or transfer in violation of this section will be void. At
            any time and without the need for User’s consent,  Nftyhills may assign
            any obligation, right and these Terms & Conditions. Subject to the
            foregoing, these Terms & Conditions will be binding upon, and inure
            to the benefit of the parties and their respective permitted
            successors and assigns. The User may not merge these Terms &
            Conditions with any other agreements with which  Nftyhills may be a party.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            12.2. Disputes.
          </div>
          <div style={{ fontSize: "14.5px", marginBottom: "16px" }}>
            Any dispute, controversy, difference or claim arising out of or
            relating to these Terms & Conditions or relating in any way to the
            User’s use of  Nftyhills sites or  Nftyhills Services, including the existence,
            validity, interpretation, performance, breach or termination thereof
            or any dispute regarding non-contractual obligations arising out of
            or relating to these Terms & Conditions the parties may agree to
            first attempt mediation before a single mediator, administered by
            the International Centre for Dispute Resolution under its mediation
            rules, to be held in any location agreed to by the parties, or Salt
            Lake City, Utah, USA in the English language. If the parties do not
            agree on mediation, the matter shall be referred to and finally
            resolved by arbitration before a single arbitrator to be held in
            Salt Lake City, Utah, USA administered by the International Centre
            for Dispute Resolution in accordance with International Dispute
            Resolution Rules. The decision of the arbitrator is final and
            binding on the parties, and enforceable in a court of competent
            jurisdiction. The prevailing party shall be entitled to costs and
            reasonable attorneys’ fees for the arbitration. Notwithstanding the
            foregoing  Nftyhills and the User agree that  Nftyhills may bring suit in any
            court of law to enjoin infringement or other misuse of  Nftyhills’s
            intellectual property rights. Any disputes that may arise beyond the
            scope of the arbitration provision shall be exclusively subject to
            the State or Federal Courts located in Salt Lake City, Utah, USA.
            The User and  Nftyhills consent to personal jurisdiction in those courts.
            CLASS ACTION WAIVER: TO THE EXTENT PERMISSIBLE BY LAW, ALL CLAIMS
            MUST BE BROUGHT IN A PARTY’S INDIVIDUAL CAPACITY, AND NOT AS A
            PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE ACTION,
            OR REPRESENTATIVE PROCEEDING (COLLECTIVELY “CLASS ACTION WAIVER”).
            THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS OR
            ENGAGE IN ANY CLASS ARBITRATION. USER ACKNOWLEDGES THAT, BY AGREEING
            TO THESE TERMS, EACH PARTY WAIVES THE RIGHT TO A TRIAL BY JURY AND
            THE RIGHT TO PARTICIPATE IN A CLASS ACTION.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            12.3. Entire Agreement.
          </div>
          <div
            style={{
              fontSize: "14.5px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            These Terms & Conditions sets out all the terms agreed between the
            parties and supersedes all other agreements between the parties
            relating to its subject matter. In entering into these Terms &
            Conditions, neither party has relied on, and neither party will have
            any right or remedy based on, any statement, representation or
            warranty (whether made negligently or innocently), except those
            expressly set out in these Terms & Conditions. The terms may be
            updated on  Nftyhills sites.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            12.4. Force Majeure.
          </div>
          <div
            style={{
              fontSize: "14.5px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
             Nftyhills and its affiliates will not be liable for any failure or delay
            in performance of obligation under these Terms & Conditions where
            the failures or delay results from any cause beyond reasonable
            control, including, but not limited to, acts of God, labor disputes
            or other industrial disturbances, electrical or power outages,
            utilities or other telecommunications failures, earthquake, storms
            or other elements of nature, blockages, embargoes, riots, acts or
            orders of government, acts of terrorism, or war. Force Majeure
            events include, but are not limited to, upgrades to the validation
            rules of a given blockchain (e.g., a “hard fork” or “soft fork”).
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
            }}
          >
            12.5. Governing Law.
          </div>
          <div
            style={{
              fontSize: "14.5px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            Any claim or dispute between the User and  Nftyhills arising out of or
            relating to the User’s use of  Nftyhills sites,  Nftyhills Services, or these
            Terms & Conditions, in whole or in part, shall be governed by the
            laws of the State of California, USA without respect to its conflict
            of laws provisions. The 1980 United Nations Conventions on Contracts
            for the International Sale of Goods does not govern these Terms &
            Conditions.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            12.6. Language.
          </div>
          <div>
            All communications and notices made or given pursuant to these Terms
            & Conditions must be in the English language. If we provide a
            translation of the English language version of these Terms &
            Conditions, the English language version will control if there is
            any conflict.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            12.7. Notices to the User and  Nftyhills.
          </div>
          <div
            style={{
              fontSize: "14.5px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
             Nftyhills may provide any notice to the User under these Terms &
            Conditions by: (i) posting a notice on  Nftyhills Site; or (ii) sending a
            message to the email address associated with the User’s account.
            Notices provided on  Nftyhills Site will be effective upon posting and
            notices provided by email will be effective when the email is sent.
            It is the User’s responsibility to keep the User’s email address
            current. To give  Nftyhills notice under these Terms & Conditions, the
            User must contact  Nftyhills by support@ Nftyhills. .  Nftyhills may update the
            address for notices by posting on the  Nftyhills Site.
          </div>
          <div
            style={{
              fontSize: "16px",
              marginBottom: "16px",
              fontWeight: "700",
              marginTop: "16px",
            }}
          >
            12.8. Severability.
          </div>
          <div
            style={{
              fontSize: "14.5px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            If any portion of these Terms & Conditions is held to be invalid or
            unenforceable, the remaining portions will remain in full force and
            effect.
          </div>
        </Box>
        <Footer />
      </Box>
   
    </Box>
  );
}

export default Terms;
