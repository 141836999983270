import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'

function DetailsCard() {


  return (
    <Box sx={{ ml:{xs:"0rem",sm:"1rem"}, mb:"1rem" , borderRadius:"15px", border:"solid 0.5px #24182F", 
   }}>
  
        <Box sx={{position:"relative",width:"288px", height:"288px"}}>
       {<Box sx={{position:"absolute", top:"5px", left:"5px"}}><Typography sx={{color:"#fff"}}>225/1000</Typography>  </Box>  }
        <img src={require("../img/details1.webp")} alt="" style={{width:"288px", height:"288px" ,borderRadius:"15px"} }/>
        </Box>
 
  <Box sx={{px:"12px"}}>
  <Typography sx={{color:"#fff" , fontWeight:"700"}}>Degen Degen #2314</Typography>
   <Typography sx={{color:"#1231B0"}}>DegenTown</Typography>
   <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", mt:"1rem", mb:"0.5rem"}}><Typography sx={{color:"#fff"}}>0.2 eth</Typography>  <Button variant="outlined" sx={{borderRadius:"5px"}}>Buy</Button></Box>
  </Box>
 
  </Box>
  )
}

export default DetailsCard