import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import persus from "../img/persus.gif";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { Twitter } from "@mui/icons-material";
import { API, graphqlOperation } from "aws-amplify";
import { launchpadByName } from "../graphql/queries";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function Launch() {
  const [nftitem, setNftitem] = useState([])

  const {name} = useParams()
  const navigate = useNavigate()
 


  useEffect(() => {
  
   fetchItems()   

  }, [])

 async function fetchItems(){
  
   try{
     const data =   await API.graphql(graphqlOperation(launchpadByName, {name:name}))
     const nftItems = data.data.LaunchpadByName.items 
    
     console.log(data)

     setNftitem(nftItems)
  
      
    }
    catch(err){
          console.log(err)
   }

 }

  return (
    <Box>
      <Navbar />
      <Box sx={{ backgroundColor: "#120C18", px: "24px"}}>
        <Grid container>
          <Grid
            item
            xs={0}
            sm={2}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Sidebar />
          </Grid>

          {
            nftitem.map((data)=>{
               return(<Grid item xs={12} sm={10}>
                <Grid container sx={{ p: { sm: 2 },mb:"4rem" }}>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: { xs: "100%", sm: "80%" } }}>
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontSize: "32px",
                          fontWeight: "700",
                        }}
                      >
                        CodeName:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontSize: "32px",
                          fontWeight: "700",
                        }}
                      >
                        {data.name}
                      </Typography>
                      <Box sx={{display:"flex", flexDirection:'row',alignItems:"center"}}>
                      <Button variant="outlined" sx={{ my: 2 }}>
                        Doxed
                      </Button>
                      <a href={data.Website} target="_blank">< LanguageOutlinedIcon sx={{ml:"0.5rem", color:"#fff"}}  /></a> 
                        
                        <a href={data.twitter} target="_blank"><Twitter sx={{ml:"0.5rem", color:'#fff'}}/></a> 
                    
                      </Box>
                    
                      <Typography sx={{ color: "#C7c0ce", mb: 4 }}>
                 {data.description}
                      </Typography>
    
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          border: "solid 0.5px #24182F",
                          p: 2,
                          borderRadius: "10px",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 4,
                          }}
                        >
                          <Typography sx={{ color: "#ffffff" }}>
                            Private Sale
                          </Typography>
                          <Typography sx={{ color: "#186ABC" }}>Coming Soon</Typography>
                        </Box>
    
                        <Box
                          sx={{
                            md: 3,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ color: "#ffffff" }}>
                            WHITELIST
                          </Typography>
                          <Typography sx={{ color: "#ffffff" }}>
                            *VARIABLE LIMIT*
                          </Typography>
                          <Typography sx={{ color: "#ffffff" }}>
                            PRICE: (notset)
                          </Typography>
                        </Box>
                      </Box>
    
                    
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{mt:{xs:"2rem", sm:"0rem"}}}>
                    <img
                      src={data.image}
                      alt=""
                      style={{ width: "100%", height: "100%", borderRadius:"20px",  border: "solid 0.5px #24182F", }}
                    />
                  </Grid>
                </Grid>
              </Grid>)
            })
          }
        </Grid>
      </Box>
    </Box>
  );
}

export default Launch;
