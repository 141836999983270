import { Box, Typography,Button, Skeleton} from '@mui/material'
import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import degentownlpd from "../img/degentownlpd.gif"
import { useContext } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {caroselContex} from './CaroselContex';

function TopDrops() {
    const {carosel3} = useContext(caroselContex)

    if(carosel3.length <= 0){
        const array = new Array(5).fill(0)

        return( <Box sx={{my:6}}>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <Typography sx={{fontSize:"30px", color:"#ffffff", fontWeight:"700"}}>Upvoted Drops</Typography>
                <Button sx={{color:"#ffffff", display:{xs:"none", sm:"block"}}}> View all</Button>
            </Box>
        
            {/* desktop */}
              <Box sx={{display:{xs:"none", sm:"block"}}} >
             
            
          <Box  sx={{display:"flex", flexDirection:"row", alignItem:"center", overflow:"hidden"}}>
          {
                array.map((data)=>{
                    return( 
                        <Box key={data.id} sx={{display:"flex", flexDirection:"column", mt:3, border:"solid 0.5px #24182F", borderRadius:"20px", mr:2}}>
                       <Box sx={{   "&:hover":{
                    transform:`scale(1.1)`}}}> <Skeleton variant="rounded" width={218} height={165}/></Box>
                      
                        <Box sx={{}}>
                        <Skeleton variant="text" width={"100%"} height={25}/>
                        <Skeleton variant="text" width={"100%"} height={25}/>
                        </Box>
                   
                   
                
                
                    </Box>)
                })
             }
        
             </Box>
           
        
        
            
            
        
            
           
              </Box>
        
        
              <Box sx={{display:{xs:"block", sm:"none"}}}>
                <Box  sx={{mb:"1rem"}}>
              
            
            <Box sx={{display:"flex", flexDirection:"row", alignItem:"center", overflow:"scroll", pb:"1rem"}}>
           
           {
            array.map((data)=>{
                return(
                <Box key={data.id} sx={{display:"flex", flexDirection:"column", mt:3, border:"solid 0.5px #24182F", borderRadius:"20px", mr:2}}>
                   <Skeleton variant="rounded" width={218} height={165}/>
                <Box sx={{}}>
                <Skeleton variant="text" width={"100%"} height={25}/>
                        <Skeleton variant="text" width={"100%"} height={25}/>
                </Box>
           
           
        
        
            </Box>)
            })
           } 
           
           
        
        
        </Box>
        
        
        
        
        
        
        
                </Box>
         
        
          <Box> <Button variant='outlined' sx={{width:"100%", borderRadius:"20px"}}>View all</Button></Box>
               
              </Box>
          
          </Box>)
    }

  return (
  <Box sx={{my:6}}>
    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <Typography sx={{fontSize:"30px", color:"#ffffff", fontWeight:"700"}}>Upvoted Drops</Typography>
        <Button sx={{color:"#ffffff", display:{xs:"none", sm:"block"}}}> View all</Button>
    </Box>

    {/* desktop */}
      <Box sx={{display:{xs:"none", sm:"block"}}} >
      <Carousel autoPlay={false} interval={2000} infiniteLoop={false} showThumbs={false} showIndicators={false} showStatus={false} >
    
  <Box  sx={{display:"flex", flexDirection:"row", alignItem:"center", overflow:"hidden"}}>
  {
        carosel3.map((data)=>{
            return( 
                <Box key={data.id} sx={{display:"flex", flexDirection:"column", mt:3, border:"solid 0.5px #24182F", borderRadius:"20px", mr:2}}>
              <Box sx={{   "&:hover":{
            transform:`scale(1.1)`}}}>
         <Box sx={{width:"218px", height:"165px",}}>
       <img src={data.image} alt="" style={{width:"100%", height:"100%", borderRadius:"20px"}}/>
       </Box>
                
                </Box>
                
                <Box sx={{display:"column", alignItems:"center"}}>
                    <Typography sx={{color:"#ffffff", fontSize:"14px"}}>{data.name}</Typography>
                    <Typography sx={{color:"#19669E", fontSize:"14px"}}>{data.name}</Typography>
                    <Typography sx={{display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"center", color:"#fff", fontSize:"12px"}}> < FavoriteIcon sx={{color:"#4E4E55"}}/>{data.likes}</Typography>
                   
                </Box>
           
           
        
        
            </Box>)
        })
     }

     </Box>
   


    
    

    
    </Carousel>
      </Box>


      <Box sx={{display:{xs:"block", sm:"none"}}}>
        <Box  sx={{mb:"1rem"}}>
        <Carousel autoPlay={false} interval={2000} infiniteLoop={false} showThumbs={false} showIndicators={false} showStatus={false} >
    
    <Box sx={{display:"flex", flexDirection:"row", alignItem:"center", overflow:"scroll", pb:"1rem"}}>
   
   {
    carosel3.map((data)=>{
        return(
        <Box key={data.id} sx={{display:"flex", flexDirection:"column", mt:3, border:"solid 0.5px #24182F", borderRadius:"20px", mr:2}}>
     <Box sx={{width:"218px", height:"165px",}}>
       <img src={data.image} alt="" style={{width:"100%", height:"100%", borderRadius:"20px"}}/>
       </Box>
        <Box sx={{}}>
            <Typography sx={{color:"#ffffff", fontSize:"14px"}}>{data.name}</Typography>
            <Typography sx={{color:"#19669E", fontSize:"14px"}}>{data.name}</Typography>
            <Typography sx={{display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"center", color:"#fff", fontSize:"12px"}}> < FavoriteIcon sx={{color:"#4E4E55"}}/> {data.likes}</Typography>
        </Box>
   
   


    </Box>)
    })
   } 
   
   


</Box>







        </Carousel>
        </Box>
 

  <Box> <Button variant='outlined' sx={{width:"100%", borderRadius:"20px"}}>View all</Button></Box>
       
      </Box>
  
  </Box>
  )
}

export default TopDrops