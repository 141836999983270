import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import megalogo from "../img/megalogo.png"

function Team() {
  return (
    <Box sx={{display:'flex', flexDirection:"row", justifyContent:"center", px:"24px", backgroundColor:"#120C18", py:"3rem"}}>
    <Box sx={{width:{xs:"100%",sm:"40%"}, display:"flex", flexDirection:"column", alignItems:"center" }}>

      <img src={megalogo} alt="" style={{width:"250px", height:"250px", alignSelf:"center"}}/>

      <Typography sx={{color:"#fff", fontSize:"28px", fontWeight:'700', my:3, }}>Nftyhills Auction Form</Typography>
      <Typography sx={{color:"#fff", fontSize:"18px", mb:3, }}>Thank you for your interest in  Joining Nftyhills team! We are a large team that offer web3 services. fill the form below to be drafted to a part of our team </Typography>
   <form>
   <TextField
          id="filled-basic"
          label="Name"
          variant="filled"
          name="name"
          required
          
          sx={{
            backgroundColor: "#ffffff",
        
            width: "100%",
            marginBottom: "1.5rem",
            borderRadius:"5px",
            height:"50px"
          }}
        />

        <TextField
          id="filled-basic"
          label="Email"
          variant="filled"
          required
          name="email"
          type="email"
        
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            marginBottom: "1.5rem",
            borderRadius:"5px"
            
          }}
        />

<TextField
          id="filled-basic"
          label="Twitter"
          variant="filled"
          name="twitter"
         
        
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            marginBottom: "1.5rem",
            borderRadius:"5px"
            
          }}
        />






        
<TextField
          id="filled-basic"
          label="Description"
          variant="filled"
          name="description"
          placeholder='Describe your profession and role your want to play'
          required
          multiline
          rows={4}
          
      
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            marginBottom: "1rem",
            fontSize:"700",
            borderRadius:"5px"
            
          }}
        />

        <Button
        type="submit"
        
          sx={{
            marginTop: "1rem",
            marginBottom: "1rem",
            height: "3.5rem",
            border:"6px",
            padding:"4px  1.5rem",
            backgroundColor:"#2B2B30",
            fontWeight:"700"

          }}
          variant="contained"
        >
          Submit
        </Button>

   </form>
     



    </Box>
   </Box>
  )
}

export default Team