import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import FeaturedDrop from "./FeaturedDrop";
import HomeFooter from "./HomeFooter";
import Live from "./Live";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import UpcomingDrops from "./UpcomimgDrops";


function LaunchPad() {
 
  return (

    <Box>
    <Navbar/>
<Box sx={{backgroundColor:"#120C18", pl:2, pr:2}}>
<Grid container>
<Grid item xs={0} sm={2} sx={{display:{xs:"none",sm:"block"}}}><Sidebar/></Grid>

<Grid item xs={12} sm={10} sx={{pl:"1rem"}}>

<FeaturedDrop/>
<Live/>
<UpcomingDrops/>
<HomeFooter/>


</Grid>
</Grid>


  </Box>

  </Box>
   
  );
}

export default LaunchPad;
