import React from 'react'
import { Link } from 'react-router-dom'

function Tawin() {
  return (
    <div>
    <nav className="border-b p-6">
      <p className="text-4xl font-bold">Metaverse Marketplace</p>
      <div className="flex mt-4">
        <Link to="/">
          <a className="mr-4 text-pink-500">
            Home
          </a>
        </Link>
        <Link to="/">
          <a className="mr-6 text-pink-500">
            Sell NFT
          </a>
        </Link>
        <Link to="/">
          <a className="mr-6 text-pink-500">
            My NFTs
          </a>
        </Link>
        <Link to="/dashboard">
          <a className="mr-6 text-pink-500">
            Dashboard
          </a>
        </Link>
      </div>
    </nav>
 
  </div>
  )
}

export default Tawin