import { Box, Typography } from '@mui/material'
import React from 'react'
import HomeFooter from './HomeFooter'
import Navbar from './Navbar'
import justape from "../img/justape.gif"
import { useContext } from 'react';
import {caroselContex} from './CaroselContex';

function Stats() {
  const {stats} = useContext(caroselContex)
  return (
    <Box sx={{backgroundColor:"#120C18",}}>
        <Navbar/>
       <Box sx={{my:"1rem", display:"flex", flexDirection:"column", alignItems:"center", px:"24px"}}>
                  <Typography sx={{color:"#fff", fontSize:'38px', fontWeight:"700"}}>TOP NFT</Typography> 
                  <Typography sx={{color:"#fff", fontSize:'16px', textAlign:"center"}}>Popular NFTs collection ranked by volume, floor price, marketcap and other stats</Typography> 
       </Box>

     {/* table heading */}
     <Box sx={{overflowX:"hidden"}}>
      
    


       <Box sx={{mt:"4rem",mb:"1rem",display:"flex", flexDirection:"column", alignItem:"center" ,alignItems:"center"}}>
        <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"space-between", width:"90%"}}>
          <Box><Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>Collection</Typography> </Box>
       
        <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"space-between"}}>
            <Box sx={{width:"150px",display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>Marketcap</Typography> </Box>
            <Box sx={{width:"150px",display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>24hrs %</Typography> </Box>
            <Box sx={{width:"150px",display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>7day %</Typography> </Box>
            <Box sx={{width:"150px",display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>Floor Price</Typography> </Box>
            <Box sx={{width:"150px", display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>Owners</Typography> </Box>
            <Box sx={{width:"150px",display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>Items</Typography> </Box>
           
        </Box>
   
        </Box>

<Box>
    
</Box>

 </Box>


<Box>
  {stats.map((data)=>{
    return( <Box key={data.id} sx={{py:"1rem",display:"flex", flexDirection:"row",alignItems:"center",justifyContent:"center" }}>
     

    {/* stats */}
    <Box sx={{my:"0.3rem", backgroundColor:"#1c1326", px:"4px", py:"4px",borderRadius:"8px", display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"space-between", width:"90%"}}>
    <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", width:"300px"}}>
      <Box><Typography sx={{color:"#ffffff", fontSize:"18px",mr:1, fontWeight:"700"}}></Typography></Box>
      <Box><img src={data.image}  alt="" style={{borderRadius:"50%", height:"64px", width:"64px", marginRight:"8px"}}/></Box>
      <Box sx={{display:"flex", flexDirection:"column", width:'100%'}}>
           <Typography sx={{color:"#ffffff", fontSize:"16px", fontWeight:"700"}}>{data.name}</Typography>
      
      </Box>
     </Box>

     <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"space-between"}}>
          <Box sx={{width:"150px",display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px'}}>{data.market_cap.toFixed(2)}</Typography> </Box>
          <Box sx={{width:"150px",display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color: data.one_day_change > 0 ? "green": "red", fontSize:'16px',}}>{(data.one_day_change*100).toFixed(2)}%</Typography> </Box>
          <Box sx={{width:"150px",display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:data.seven_day_change > 0 ? "green": "red", fontSize:'16px'}}>{(data.seven_day_change*100).toFixed(2)}%</Typography> </Box>
          <Box sx={{width:"150px",display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px'}}>{data.floor_price}</Typography> </Box>
          <Box sx={{width:"150px",display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px'}}>{data.num_owners}</Typography> </Box>
          <Box sx={{width:"150px",display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px'}}>{data.total_sales}</Typography> </Box>
         
      </Box>

    </Box>

   
   
   
   
   
   
   
   
  
    




    </Box>)
  })}
</Box>

      
      </Box>


       {/* <HomeFooter/> */}
    </Box>
  )
}

export default Stats