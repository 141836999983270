import { Alert, Box, Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import newlisting1 from "../img/newlisting1.jpg"
import newlisting2 from "../img/newlisting2.jpg"
import { useContext } from 'react';
import {caroselContex} from './CaroselContex';
import eth from "../img/eth.png"
import { Link, useNavigate, useParams } from "react-router-dom";
import { ethers } from "ethers";
import { useWeb3React } from '@web3-react/core';

function NewListing() {
    const {carosel} = useContext(caroselContex)
    const [count, setcount] = useState(1)
    const [metamask, setMetamask] = useState(false);
    const [namee, setNamee] = useState("")
    
    const {name} = useParams()
    const navigate = useNavigate()
    
  
    const addresspay="0xaeF04B89Ba955790D24858E93c661eA380262Eb5";
    const [error, setError] = useState();
    const [error1, setError1] = useState(false);
    const [txs, setTxs] = useState([]);
    const [txs1, setTxs1] = useState(false);
    const [message, setmessage] = useState("")

    
    const { activate, active,  library, account } = useWeb3React();





    async function Connect() {
  
      try {
        if (!active)
          throw new Error("check if you wallet is connected..");
     
  
       setError(false)
      const  signer =  library.getSigner()
      const  address = await signer.getAddress()
     
      
      try{
    
        const tx = {
          from: address,
          to: addresspay,
          value: ethers.utils.parseEther("0.5"),
          gasLimit: ethers.utils.hexlify(10000000),
          gasPrice: ethers.utils.hexlify(parseInt(await library.getGasPrice())),
      };
      
      signer.sendTransaction(tx).then((transaction) => {
          console.dir(transaction);
         
      });
  
        console.log("tx", tx);
        setTxs1(true)
        setTxs([tx]);
      } catch(err){
        setError1()
        console.log(err.message)
        setmessage(error.message)
      }
       
       
      
      } catch (err) {
        setError(true);
   
      }
    };
  
  
    
  return ( 

    <Box>
          <Typography sx={{fontSize:"30px", color:"#ffffff", fontWeight:"700"}}>Auction</Typography>


          <Grid container spacing={2}>


<Grid item xs={12} sm={4}  sx={{width:"100%"}}>

<Box sx={{mb:"1rem" , borderRadius:"15px", border:"solid 0.5px #24182F",position:"relative" }} onClick={Connect}>


<Box sx={{}}>
   </Box>  
<img src='https://lh3.googleusercontent.com/nte50or2bnbeXYvjWKJCQ1pRcTlOncRYsvEEx0Z97rD_K1Rv8L0RVr5CCfFdu5r2qgVsS1032B_BL6gGg2NXm2TXQ0BdVyG0Z8S2-A=h400' alt="" style={{ width:"100%", height:"70vh", borderRadius:"15px"} }/>


<Box sx={{px:"12px",display:"flex", flexDirection:"column", alignItems:"center"}}>
<Typography sx={{color:"#fff" , fontWeight:"700", fontSize:"16px"}}>MAX PAIN AND FRENS</Typography>
<Typography sx={{color:"#fff" , fontWeight:"700", fontSize:"16px", position:"absolute", top:"5px", left:"10px", backgroundColor:"#000",display:"flex", flexDirection:"row"}}>0.5 <img src={eth} style={{width:"30px", height:"20px"}}/></Typography>
</Box>
  <Button variant="outlined" sx={{position:"absolute", top:"5px", right:"10px", backgroundColor:"#000", width:'30px', height:"20px"}}>Buy</Button>
</Box>
</Grid>

<Grid item xs={12} sm={4}  sx={{width:"100%"}}>

<Box sx={{mb:"1rem" , borderRadius:"15px", border:"solid 0.5px #24182F",position:"relative",  }} onClick={Connect}>


 
<img src="https://lh3.googleusercontent.com/xFedhVNjqHo5iDFeoV7CgD93VBqUgGotrGYwDA9aLShhkcsR7gdv02_MymSqsyXIDAW80mTLyzJgFCf6ueHSiX-S8L-6FHSC3XfCRg=s550" alt="" style={{ width:"100%", height:"70vh", borderRadius:"15px"} }/>


<Box sx={{px:"12px",display:"flex", flexDirection:"column", alignItems:"center"}}>
<Typography sx={{color:"#fff" , fontWeight:"700", fontSize:"16px"}}>Shine like a star</Typography>
<Typography sx={{color:"#fff" , fontWeight:"700", fontSize:"16px", position:"absolute", top:"5px", left:"10px", backgroundColor:"#000",display:"flex", flexDirection:"row"}}>0.5 <img src={eth} style={{width:"30px", height:"20px"}}/></Typography>
</Box>
<Button variant="outlined" sx={{position:"absolute", top:"5px", right:"10px", backgroundColor:"#000", width:'30px', height:"20px"}}>Buy</Button>
</Box>
</Grid>

<Grid item xs={12} sm={4}  sx={{width:"100%"}}>

<Box sx={{mb:"1rem" , borderRadius:"15px", border:"solid 0.5px #24182F",position:"relative" }} onClick={Connect}>


<Box sx={{}}>
   </Box>  
<img src="https://lh3.googleusercontent.com/yF5IFTazZAfM2CjAGBZa_XyFA-lt1cZTOna3PDyami5Tmhv6m_rm4oXwDWwpnxQu9lZ5NMqRhHNbix5jrzzj2U772XhaFgsJiL1f=h400" alt="" style={{ width:"100%", height:"70vh", borderRadius:"15px"} }/>


<Box sx={{px:"12px",display:"flex", flexDirection:"column", alignItems:"center"}}>
<Typography sx={{color:"#fff" , fontWeight:"700", fontSize:"16px"}}>Rektguy</Typography>
<Typography sx={{color:"#fff" , fontWeight:"700", fontSize:"16px", position:"absolute", top:"5px", left:"10px", backgroundColor:"#000",display:"flex", flexDirection:"row"}}>0.5 <img src={eth} style={{width:"30px", height:"20px"}}/></Typography>
</Box>
<Button variant="outlined" sx={{position:"absolute", top:"5px", right:"10px", backgroundColor:"#000", width:'30px', height:"20px"}}>Buy</Button>
</Box>
</Grid>

   </Grid>
   {error &&<Typography sx={{fontSize:"14px", paddingLeft:'4px', color:"#E63132 "}}>check that you have aconnected wallet!</Typography>}
                     
                      {error1 && <Alert severity="error">{error}</Alert>} 
                    
    </Box>
  
   )

}

export default NewListing