import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react'
import { useContext } from "react";
import { caroselContex } from "./CaroselContex";

function FeaturedDrop() {
    const { featured } = useContext(caroselContex);
  return (
    
<Box>
      {featured.map((data) => {
        return (
          <Grid
            key={data.id}
            container
            sx={{ border: "solid 0.5px #24182F", borderRadius: "30px" }}
          >
            <Grid item xs={12} sm={5} sx={{display:{xs:"none", sm:'block '}}}>
            <Button variant="contained"> Featured Drop</Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                  backgroundColor: "#120C18",
                  height: "100%",
                  pl: 4,
                  borderTopLeftRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "48px",
                    color: "#ffffff",
                    textAlign: "start",
                  }}
                >
                  {data.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#A197aa",
                    my: 2,
                    textAlign: "start",
                  }}
                >
                  {data.description}
                </Typography>
              
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Box sx={{ ml: -0.2 }}>
                <img
                  src={data.image}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={5} sx={{display:{xs:"block", sm:'none'}}}>
            <Button variant="contained"> Featured Drop</Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                  backgroundColor: "#120C18",
                  height: "100%",
                  pl: 4,
                  borderTopLeftRadius: "30px",
                  borderBottomLeftRadius: "30px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "48px",
                    color: "#ffffff",
                    textAlign: "start",
                  }}
                >
                  {data.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#A197aa",
                    my: 2,
                    textAlign: "start",
                  }}
                >
                  {data.description}
                </Typography>
              
              </Box>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  )
}

export default FeaturedDrop