import { Box, Button, Grid, Skeleton, Typography } from '@mui/material'
import React from 'react'
import eth from "../img/eth.png"
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react';
import {caroselContex} from './CaroselContex';

function PopularCollection() {
  
  const {stats2} = useContext(caroselContex)
  const {stats3} = useContext(caroselContex)

  const navigate = useNavigate()
 
  if(stats3.length <= 0 ){
      const array = new Array(12).fill(0)
    return(
      <Box sx={{my:6}}>
      <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Typography sx={{fontSize:"30px", color:"#fff", fontWeight:"700"}}>Top Collections</Typography>
          <Button  sx={{color:"#ffffff", display:{xs:"none", sm:"block"}}} > View all</Button>
      </Box>
  
      <Grid container>
           {
            array.map((data)=>{
              return(<Grid key={data.id} item xs={12} sm={4}>
                
         <Box sx={{display:"flex", flexDirection:"row", mt:3, alignItems:"center", width:"300px"}}>
          <Box><Typography sx={{color:"#ffffff", fontSize:"18px",mr:1, fontWeight:"700"}}></Typography></Box>
          <Skeleton variant="circular" width={"64px"} height={"64px"}  />
          <Box sx={{display:"flex", flexDirection:"column", width:'100%'}}>
          <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Skeleton variant="text" width={"100px"} height={"50px"}  />
          <Skeleton variant="text" width={"100px"} height={"50px"}  />
              </Box>
              <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
              <Skeleton variant="text" width={"100px"} height={"50px"}  />
              <Skeleton variant="text" width={"100px"} height={"50px"}  />
              </Box>
          
          </Box>
         </Box>
              </Grid>)
            })
           }
      </Grid>
  
  
      </Box>
    )
  }



  return (
    <Box sx={{my:6}}>
    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <Typography sx={{fontSize:"30px", color:"#ffffff", fontWeight:"700"}}>Top Collections</Typography>
        <Button  sx={{color:"#ffffff", display:{xs:"none", sm:"block"}}} onClick={()=>{navigate("stats")}}> View all</Button>
    </Box>

    <Grid container>
         {
          stats3.map((data)=>{
            return(<Grid key={data.id} item xs={12} sm={4}>
              
       <Box sx={{display:"flex", flexDirection:"row", mt:3, alignItems:"center", width:"300px"}}>
        <Box><Typography sx={{color:"#ffffff", fontSize:"18px",mr:1, fontWeight:"700"}}></Typography></Box>
        <Box><img src={data.image}  alt="" style={{borderRadius:"50%", height:"64px", width:"64px", marginRight:"8px"}}/></Box>
        <Box sx={{display:"flex", flexDirection:"column", width:'100%'}}>
        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <Typography sx={{color:"#fff", fontSize:"16px", display:"flex", flexDirection:'row', alignItems:"center", fontWeight:"bold"}}>{(data.name )} </Typography>
          <Typography sx={{color:"#C7c0ce" , fontSize:"12px",  display:"flex", flexDirection:'row', alignItems:"center" }}><img src={eth} alt="eth"
            style={{width:"25px", height:"20px", }}/>{data.market_cap.toFixed(2)}</Typography>
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <Typography sx={{color:"#C7c0ce", fontSize:"12px", display:"flex", flexDirection:'row', alignItems:"center"}}>Floor <img src={eth} alt="eth"
            style={{width:"30px", height:"20px"}}/>{((data.floor_price*10/10).toFixed(2) )} </Typography>
          <Typography sx={{color:data.one_day_change >= 0 ? "green": "red" , fontSize:"12px" }}> {(data.one_day_change*100).toFixed(2)}%</Typography>
            </Box>
        
        </Box>
       </Box>
            </Grid>)
          })
         }
    </Grid>


    </Box>


  )
}

export default PopularCollection