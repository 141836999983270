import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import Navbar from './Navbar'
import sp1 from "../img/sp1.svg"
import sp2 from "../img/sp2.svg"
import sp3 from "../img/sp3.svg"
import sp4 from "../img/sp4.svg"
import sp5 from "../img/sp5.svg"
import sp6 from "../img/sp6.svg"
import sp7 from "../img/sp7.svg"
import sp8 from "../img/sp8.svg"
import Footer from './Footer'
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { useNavigate } from 'react-router-dom'

export default function About() {

  const navigate = useNavigate()
  return (
    <Box>
        <Navbar/>
    <Box sx={{backgroundColor:"#120C18", pb:6, px:"24px"}}>

      {/* About introduction */}
    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
    <Box sx={{display:"flex", flexDirection:'column',alignItems:"center"}}>
        <Typography sx={{color:"#C7c0ce", my:2, fontSize:"12px", fontWeight:"700"}}>BUILT ON <span>ETHEREUM</span></Typography>
        <Typography sx={{color:"#ffffff",mb:1, fontSize:{xs:"22px", sm:"58px"}, fontWeight:"700"}}>Welcome to Nftyhills</Typography>
        <Typography sx={{color:"#ffffff",mb:4, fontSize:{xs:"18px", sm:"38px"}, fontWeight:"700",textAlign:"center"}}>Home to the next generation of digital creators</Typography>
     <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}}> <Button variant="contained" sx={{mr:4}} onClick={()=>{navigate("/collections ")}}>Explore Market</Button>
        <Button variant="outlined" sx={{}} onClick={()=>{navigate("/launchpad")}}>Apply for Lunchpad</Button>
     </Box>
       
 
    </Box>
   </Box>
    
    {/* Join Community */}
   <Grid container spacing={2} sx={{my:15}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{mt:3, ml:{xs:0, sm:3}, display:"flex" ,flexDirection:"column", justifyContent:{xs:"center",sm:"center"}, alignItems:{xs:"center",sm:"center"}}}><Typography sx={{fontSize:{xs:"32px", sm:"38px"}, lineHeight:1, fontWeight:"700", color:"#1565C0", width:{xs:"100%", sm:"60%"}}}>We are a bringing all the best nft Project to one Marketplace</Typography></Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{mt:20 , display:"flex" ,flexDirection:"column", justifyContent:{xs:"center",sm:"center"}, alignItems:{xs:"center",sm:"center"} }}> <Box sx={{width:{xs:"100%", sm:"70%"}}}><Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>Nftyhills is a system - it's a machine. It moves as a monolith working to help people in this dangerous space; to educate people in an area filled with false prophets; to provide value in the midst of disinformation; to show transparency when so many people wear masks. Above all else we are a family who emphasizes our core values, and we always look out for each other no matter the community we are in.</Typography>
 <Button variant='contained' sx={{marginTop:"2rem"}}>Get Community Pass</Button>
 </Box>

 </Box>
  </Grid>
 
</Grid>

{/* NFT card */}
<Box sx={{display:"flex",flexDirection:"row", justifyContent:"center", alignItems:"center", my:15, }}>
  <Box sx={{width:{xs:"100%", sm:"50%"}}}> <img src={require("../img/card.png")} alt="" style={{width:"100%", height:"100%"}} /></Box>
 
</Box>

{/* statistics */}
<Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
<Box sx={{display:"flex", flexDirection:{xs:"column", sm:"row"}, justifyContent:"space-between", alignItems:"center", width:"80%"}} >
<Box sx={{display:"flex", flexDirection:"column", alignItems:"center", mb:"1rem"}}><Typography sx={{fontSize:"28px",color:"#fff",}}>7,000,000+</Typography>
<Typography sx={{color:"#1565C0"}}>unique monthly visitors</Typography>
</Box>


<Box sx={{display:"flex", flexDirection:"column", alignItems:"center", mb:"1rem"}}><Typography sx={{fontSize:"28px",color:"#fff"}}>8,000+</Typography>
<Typography sx={{color:"#1565C0"}}>collections</Typography>
</Box>

<Box sx={{display:"flex", flexDirection:"column", alignItems:"center", mb:"1rem"}}><Typography sx={{fontSize:"28px",color:"#fff"}}>50,000+</Typography>
<Typography sx={{color:"#1565C0"}}>daily wallet connections</Typography>
</Box>

<Box sx={{display:"flex", flexDirection:"column", alignItems:"center", mb:"1rem"}}><Typography sx={{fontSize:"28px",color:"#fff"}}>1,000,000,000+</Typography>
<Typography sx={{color:"#1565C0"}}>secondary trading volume</Typography>
</Box>

</Box>
</Box>


{/* visions */}
<Grid container spacing={2} sx={{my:15}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{mt:3, ml:{xs:0, sm:3}, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}><Typography sx={{fontSize:"38px", lineHeight:1, fontWeight:"700", color:"#ffffff", width:{xs:"100%", sm:"60%"}}}>Vision</Typography></Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{mt:10 , display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:{xs:"100%", sm:"60%"}}}><Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>Lets turn our shared passion for NFT projects into profit with longevity! Let me introduce to you Nftyhills, our team has many different talents such as Server Developers, Artists, Crypto Day Traders, and a lot of very knowledgable and professional members, who can be valuable assets to your project!  Lets turn our shared passion for NFT projects into profit with longevity!</Typography>
 </Box>

 </Box>
  </Grid>
 
</Grid>

{/* services */}
<Box>
  <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignContent:"center"}}>
  <Typography sx={{ml:{xs:0, sm:"11vw"},fontSize:"38px", color:"#fff", fontWeight:"700"}}>Services</Typography>
  </Box>
 
 <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignContent:"center"}}>
 <Grid container spacing={2} sx={{my:5}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{ml:{xs:0, sm:3}, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:{xs:"center", sm:"center"}}}><Typography sx={{fontSize:"22px", lineHeight:1, fontWeight:"700", color:"#1565C0", width:{xs:"100%", sm:"60%"}}}>Discord Development</Typography></Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{ display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:{xs:"100%", sm:"70%"}}}><Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>Let me introduce to you Nftyhills, our From basic templates to fully customized servers. Every server is created in house and perfectly aligned for each projects individual needs.</Typography>
 <Button variant='contained' endIcon={<ContactSupportOutlinedIcon/>} sx={{marginTop:"2rem"}}>Get in Touch</Button>
 </Box>

 </Box>
  </Grid>
 
</Grid>
<Grid container spacing={2} sx={{my:5}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{ ml:{xs:0,sm:3}, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}><Typography sx={{fontSize:"22px", lineHeight:1, fontWeight:"700", color:"#1565C0", width:{xs:"100%",sm:"60%" }}}>Smart Contracts</Typography></Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{ display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:{xs:"100%",sm:"70%" }}}><Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>
 From basic templates to fully customized servers. Every server is created in house and perfectly aligned for each projects individual needs.</Typography>
 <Button variant='contained' endIcon={<ContactSupportOutlinedIcon/>} sx={{marginTop:"2rem"}}>Get in Touch</Button>
 </Box>

 
 
 
 </Box>
  
  </Grid>
 
</Grid>
<Grid container spacing={2} sx={{my:5}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{ ml:{xs:0,sm:3}, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}><Typography sx={{fontSize:"22px", lineHeight:1, fontWeight:"700", color:"#1565C0", width:{xs:"100%",sm:"60%" }}}>Website Creation</Typography></Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{ display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:{xs:"100%",sm:"70%" }}}><Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>
 Our team of experienced and creative designers, create engaging sites for your members to thrive in easy to understand information and site navigation.
</Typography>
<Button variant='contained' endIcon={<ContactSupportOutlinedIcon/>} sx={{marginTop:"2rem"}}>Get in Touch</Button>
 </Box>

 </Box>
  </Grid>
 
</Grid>

<Grid container spacing={2} sx={{my:5}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{ ml:{xs:0, sm:3}, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}><Typography sx={{fontSize:"22px", lineHeight:1, fontWeight:"700", color:"#1565C0", width:{xs:"100%",sm:"60%" }}}>Marketing</Typography></Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{ display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:{xs:"100%",sm:"70%" }}}><Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>
 Tested strategies, innovative ideas, and consistency are key factors in accessing potential markets, opportunity and investors.</Typography>
 <Button variant='contained' endIcon={<ContactSupportOutlinedIcon/>} sx={{marginTop:"2rem"}}>Get in Touch</Button>
 </Box>

 </Box>
  </Grid>
 
</Grid>

<Grid container spacing={2} sx={{my:5}}>
  <Grid item xs={12} sm={6}>
  <Box sx={{ ml:{xs:0,sm:3}, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}><Typography sx={{fontSize:"22px", lineHeight:1, fontWeight:"700", color:"#1565C0", width:{xs:"100%",sm:"60%" }}}>Moderation</Typography></Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{ display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:{xs:"100%",sm:"70%" }}}><Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>Lets turn our shared passion for NFT projects into profit with longevity! Let me introduce to you Nftyhills,
 Trained and experience Moderators routinely provide top tier engagement, community training and support.</Typography>
 <Button variant='contained' endIcon={<ContactSupportOutlinedIcon/>} sx={{marginTop:"2rem"}}>Get in Touch</Button>
 </Box>

 </Box>
  </Grid>
 
</Grid>


<Grid container spacing={2} sx={{my:5}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{ ml:{xs:0, sm:3}, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}><Typography sx={{fontSize:"22px", lineHeight:1, fontWeight:"700", color:"#1565C0", width:{xs:"100%",sm:"60%" }}}>Consulting</Typography></Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{ display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:{xs:"100%",sm:"70%" }}}><Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>
 Work hand in hand with recognized consultants to get a custom tailored experience in launch strategy, marketing, social media and more!</Typography>
 <Button variant='contained' endIcon={<ContactSupportOutlinedIcon/>} sx={{marginTop:"2rem"}}>Get in Touch</Button>
 </Box>

 </Box>
  </Grid>
 
</Grid>


<Grid container spacing={2} sx={{my:5}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{ ml:{xs:0, sm:3}, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}><Typography sx={{fontSize:"22px", lineHeight:1, fontWeight:"700", color:"#1565C0", width:{xs:"100%", sm:"60%"}}}>Educate</Typography></Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{ display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:{xs:"100%", sm:"70%"}}}><Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>
 Teach the community about why said project will be successful, what to look forward to, how to govern themselves, and how to build a positive community.
Launch
Congrats you did it! With tentative support from the team your members will stay on top of Launch with consistent support and guidance to ensure a smooth lift off!
Understand
Discuss project with founders through discovery calls. Utility, Founders background, goals, ideas, strategies and contracts.
Strategize
Develop strategies for project timeline, rollout plan, marketing, schedules, events. Planning is everything and is the foundation of every launch.
</Typography>
<Button variant='contained' endIcon={<ContactSupportOutlinedIcon/>} sx={{marginTop:"2rem"}}>Get in Touch</Button>
 </Box>

 </Box>
  </Grid>
 
</Grid>


 </Box>

</Box>

{/* Sponsors */}
<Box>
<Grid container spacing={2} sx={{my:15}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{mt:3, ml:{xs:0, sm:3}, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}><Typography sx={{fontSize:"38px", lineHeight:1, fontWeight:"700", color:"#1565C0", width:{xs:"100%",sm:"60%"}}}>We are sponsored by the biggest firm in web3</Typography></Box>
  </Grid>
 </Grid>


 <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
  <Grid container spacing={4} justifyContent="center" sx={{width:"100%", px:"5%"}}>


<Grid item xs={12} sm={3}>
<img src={sp2} style={{width:"250px", height:"80px"}} alt=""/>

</Grid>

<Grid item xs={12} sm={3}>
   <img src={sp3} style={{width:"250px", height:"80px"}} alt="" />
  </Grid>

  <Grid item xs={12} sm={3}>
<img src={sp4} style={{width:"250px", height:"80px"}} alt="" />
</Grid>

<Grid item xs={12} sm={3}>
<img src={sp5} style={{width:"250px", height:"80px"}} alt=""/>
</Grid>

<Grid item xs={12} sm={3}>
<img src={sp6} style={{width:"250px", height:"80px"}} alt=""/>
</Grid>

<Grid item xs={12} sm={3}>
<img src={sp7} style={{width:"250px", height:"80px"}} alt=""/>
</Grid>

<Grid item xs={12} sm={3}>
<img src={sp8} style={{width:"250px", height:"80px"}} alt=""/>
</Grid>

<Grid item xs={12} sm={3}>
<img src={sp1} style={{width:"250px", height:"80px"}} alt=""/>
</Grid>

</Grid>
 </Box>
</Box>

{/* F00ter */}
<Footer/>

    </Box>
   </Box>
   
  
  )
}

 