import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import megalogo from "../img/megalogo.png"
import  { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom'

function ApplyListing() {

  const navigate = useNavigate()
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_o7uvmnj', 'templates/71zdgjb', form.current, 'HUsRrews8SXRfX0AC')
      .then((result) => {
          console.log(result.text);
         
      },
     
      (error) => {
          console.log(error.text);
      });

      navigate("/")
  };




  return (
    <Box sx={{display:'flex', flexDirection:"row", justifyContent:"center", px:"24px", backgroundColor:"#120C18", py:"3rem"}}>
    <Box sx={{width:{xs:"100%",sm:"40%"}, display:"flex", flexDirection:"column", alignItems:"center" }}>

      <img src={megalogo} alt="" style={{width:"250px", height:"250px", alignSelf:"center"}}/>

      <Typography sx={{color:"#fff", fontSize:"28px", fontWeight:'700', my:3, }}>Nftyhills Listing Form</Typography>
      <Typography sx={{color:"#fff", fontSize:"18px", mb:3, }}>Thank you for your interest in listing your collection on Nftyhills! Let your collection command the crowd it deserve!!</Typography>
   <form  ref={form}  onSubmit={sendEmail}>
   <TextField
          id="filled-basic"
          label="Name"
          variant="filled"
          name="name"
          required
          
          sx={{
            backgroundColor: "#ffffff",
        
            width: "100%",
            marginBottom: "1.5rem",
            borderRadius:"5px",
            height:"50px"
          }}
        />

        <TextField
          id="filled-basic"
          label="Email"
          variant="filled"
          required
          name="email"
          type="email"
        
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            marginBottom: "1.5rem",
            borderRadius:"5px"
            
          }}
        />

<TextField
          id="filled-basic"
          label="Twitter"
          variant="filled"
          name="twitter"
         
        
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            marginBottom: "1.5rem",
            borderRadius:"5px"
            
          }}
        />

<TextField
          id="filled-basic"
          label="Discord"
          variant="filled"
          name="discord"
         
        
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            marginBottom: "1.5rem",
            borderRadius:"5px"
            
          }}
        />

<TextField
          id="filled-basic"
          label="Instagram"
          variant="filled"
          name="instagram"
         
        
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            marginBottom: "1.5rem",
            borderRadius:"5px"
            
          }}
        />

<TextField
          id="filled-basic"
          label="Website"
          variant="filled"
          name="website"
         
        
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            marginBottom: "1.5rem",
            borderRadius:"5px"
            
          }}
        />

<TextField
          id="filled-basic"
          label="Twitter"
          variant="filled"
          name="twitter"
          
         
        
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            marginBottom: "1.5rem",
            borderRadius:"5px"
            
          }}
        />

        
<TextField
          id="filled-basic"
          label="Description"
          variant="filled"
          name="description"
          required
          multiline
          rows={4}
          
      
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            marginBottom: "1rem",
            fontSize:"700",
            borderRadius:"5px"
            
          }}
        />

        <Button
        type="submit"
        
          sx={{
            marginTop: "1rem",
            marginBottom: "1rem",
            height: "3.5rem",
            border:"6px",
            padding:"4px  1.5rem",
            backgroundColor:"#2B2B30",
            fontWeight:"700"

          }}
          variant="contained"
        >
          Submit
        </Button>

   </form>
     



    </Box>
   </Box>
  )
}

export default ApplyListing