import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import API from '@aws-amplify/api';
import { listSpendingAddresses } from '../graphql/queries';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));





export default function CustomizedTables() {
const [items, setItems] = React.useState([])


  React.useEffect(() => {
    fetchAddressdata()
}, [])


const fetchAddressdata = async () => {
    try {
      // Switch authMode to API_KEY for public access
      const { data } = await API.graphql({
        query: listSpendingAddresses,
      
      });
      const items = data.listSpendingAddresses.items;
      console.log(items)
      setItems(items);
    } catch (err) {
      console.log(err);
    }
  };
    

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Spengding Address</StyledTableCell>
            <StyledTableCell align="left">Token Symbol</StyledTableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((data, index) => (
            <StyledTableRow  key={index}>
              <StyledTableCell align="left" component="th" scope="row">
                {data.address}
              </StyledTableCell>
              <StyledTableCell align="left">{data.name}</StyledTableCell>
           
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
