import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import { useNavigate } from 'react-router-dom'


function Career() {
   const navigate = useNavigate() 
  return (
    <Box>
 <Navbar/>
 <Box sx={{backgroundColor:"#120C18", px:"24px"}}>

{/* introduction */}
 <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
    <Box sx={{display:"flex", flexDirection:'column',alignItems:"center"}}>
        <Typography sx={{color:"#C7c0ce", my:2, fontSize:"12px", fontWeight:"700"}}>BUILT ON <span>ETHEREUM</span></Typography>
        <Typography sx={{color:"#ffffff",mb:1, fontSize:{xs:"22px",sm:"58px"}, fontWeight:"700"}}>Welcome to Nftyhills</Typography>
        <Typography sx={{color:"#ffffff",mb:4, fontSize:{xs:"18px",sm:"30px"}, fontWeight:"700", textAlign:"center"}}>Join our Ever Growing Team of Web3 Creators </Typography>
     <Box> <Button variant="contained" sx={{mr:4}} onClick={()=>{navigate("/collections")}}>Explore Market</Button>
        <Button variant="outlined" onClick={()=>{navigate("/team")}} sx={{}}>Join Team</Button>
     </Box>
       
 
    </Box>
   </Box> 

   <Grid container spacing={2} sx={{my:15}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{mt:3, ml:3, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
    <Box sx={{width:{xs:"100%", sm:"400px"}, height:{xs:"100%", sm:"400px"}}}>
    <img src={require("../img/career1.webp")} style={{width:"100%", height:"100%"}} />
    </Box>

 </Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{mt:10 , display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:"70%"}}>
    <Typography sx={{color:"#1565C0", fontSize:"20px",fontWeight:'bold', marginBottom:"1rem"}}>Web Developer</Typography>
    <Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>A developer is an individual that builds and creates software and applications. He or she writes, debugs, and executes the 
    source code of a software application. A developer is also known as a software developer, 
    computer programmer, programmer, software coder, or software engineer.</Typography>
    <Button variant="contained" onClick={()=>{navigate("/team")}} startIcon={<SettingsRemoteIcon />}  sx={{marginTop:"3rem"}}>Apply</Button> 
    
 </Box>

 </Box>
  </Grid>
 
</Grid>

<Grid container spacing={2} sx={{my:15}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{mt:3, ml:3, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
   <Box sx={{width:{xs:"100%", sm:"400px"}, height:{xs:"100%", sm:"400px"}}}>
   <img src={require("../img/career2.webp")} style={{width:"100%", height:"100%"}} />
   </Box>
   
 </Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{mt:10 , display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:"70%"}}>
    <Typography sx={{color:"#1565C0", fontSize:"20px",fontWeight:'bold', marginBottom:"1rem"}}>Graphic Designer</Typography>
    <Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>Graphic design is the art or profession of visual communication that combines images, words, and ideas to convey information to an audience, especially to produce a specific effect. In other words, graphic design is communication design; It's a way of conveying ideas through visuals and design.</Typography>
    <Button onClick={()=>{navigate("/team")}} variant="contained" startIcon={<SettingsRemoteIcon />}  sx={{marginTop:"3rem"}}>Apply</Button> 
    
 </Box>

 </Box>
  </Grid>
 
</Grid>

<Grid container spacing={2} sx={{my:15}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{mt:3, ml:3, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
    <Box sx={{width:{xs:"100%", sm:"400px"}, height:{xs:"100%", sm:"400px"}}}>
    <img src={require("../img/career3.webp")} style={{width:"100%", height:"100%"}}/>
    </Box>

 </Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{mt:10 , display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:"70%"}}>
    <Typography sx={{color:"#1565C0", fontSize:"20px",fontWeight:'bold', marginBottom:"1rem"}}>Copywriter</Typography>
    <Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>Copywriters work for agencies or directly for organizations, including private companies, nonprofits, and even the government. Day-to-day, copywriters are tasked with writing clear and concise copy for ads, marketing materials, and websites, for virtually any audience and any industry.

</Typography>
    <Button onClick={()=>{navigate("/team")}} variant="contained" startIcon={<SettingsRemoteIcon />}  sx={{marginTop:"3rem"}}>Apply</Button> 
    
 </Box>

 </Box>
  </Grid>
 
</Grid>

<Grid container spacing={2} sx={{my:15}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{mt:3, ml:3, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
    <Box  sx={{width:{xs:"100%", sm:"400px"}, height:{xs:"100%", sm:"400px"}}} >
    <img src={require("../img/career4.webp")} style={{width:"100%", height:"100%"}} />
    </Box>
 
 </Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{mt:10 , display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:"70%"}}>
    <Typography sx={{color:"#1565C0", fontSize:"20px",fontWeight:'bold', marginBottom:"1rem"}}>Content Marketing Manager</Typography>
    <Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>
    A Digital Marketing Manager is a professional who is responsible for maintaining a brand's online presence and sales by working on various marketing campaigns. Their duties include researching, strategizing with other professionals and creating content 
</Typography>
    <Button onClick={()=>{navigate("/team")}} variant="contained" startIcon={<SettingsRemoteIcon />}  sx={{marginTop:"3rem"}}>Apply</Button> 
    
 </Box>

 </Box>
  </Grid>
 
</Grid>

<Grid container spacing={2} sx={{my:15}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{mt:3, ml:3, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
 <Box  sx={{width:{xs:"100%", sm:"400px"}, height:{xs:"100%", sm:"400px"}}}>
 <img src={require("../img/career5.webp")} style={{width:"100%", height:"100%"}}/>
 </Box>

 </Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{mt:10 , display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:"70%"}}>
    <Typography sx={{color:"#1565C0", fontSize:"20px",fontWeight:'bold', marginBottom:"1rem"}}>Media Buyer (Social Advertising)</Typography>
    <Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>Media buyers negotiate the price of and purchase television and radio air time, and advertising space within newspapers, magazines and other print publications. Typical responsibilities of the job include: negotiating the best price, quality and placement  
</Typography>
    <Button onClick={()=>{navigate("/team")}} variant="contained" startIcon={<SettingsRemoteIcon />}  sx={{marginTop:"3rem"}}>Apply</Button> 
    
 </Box>

 </Box>
  </Grid>
 
</Grid>

<Grid container spacing={2} sx={{my:15}}>
  <Grid item xs={12} sm={6}>
   <Box sx={{mt:3, ml:3, display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
   <Box sx={{width:{xs:"100%", sm:"400px"}, height:{xs:"100%", sm:"400px"}}}>
   <img src={require("../img/career6.webp")} style={{width:"100%", height:"100%"}}/>
   </Box>

 </Box>
  </Grid>
  <Grid item xs={12} sm={6}>
 <Box sx={{mt:10 , display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center" }}> <Box sx={{width:"70%"}}>
    <Typography sx={{color:"#1565C0", fontSize:"20px",fontWeight:'bold', marginBottom:"1rem"}}>Influencer Marketing Manager</Typography>
    <Typography sx={{color:"#C7c0ce", lineHeight:1.5,}}>
    Job purpose. As the Social Media & Influencer Marketing Manager, you'll manage the creation of social media strategies, and the planning 
    and execution of successful social media and influencer campaigns. 
</Typography>
    <Button onClick={()=>{navigate("/team")}} variant="contained" startIcon={<SettingsRemoteIcon />}  sx={{marginTop:"3rem"}}>Apply</Button> 
    
 </Box>

 </Box>
  </Grid>
 
</Grid>

<Footer/>
 </Box>
</Box>
  )
}

export default Career