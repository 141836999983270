import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

function Footer() {
  return (
    <Box >
 <Grid container sx={{mt:20}}>
        <Grid item xs={12} sm={6}>
            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", mb:{xs:"2rem"}}}><Typography sx={{fontSize:{xs:"26px" ,sm:"38px"}, color:"#1565C0", fontWeight:"700", width:"60%"}}>Get in Touch</Typography></Box>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <Box sx={{width:"60%"}}>
                <Box sx={{marginBottom:"1rem"}}>
                <Typography sx={{fontSize:"18px", color:"#fff"}}>Support</Typography>
                <Typography sx={{color:"#A89db4"}}>Need assistance? Contact Nftyhills Online Support </Typography>
              </Box>

              <Box sx={{marginBottom:"1rem"}}>
                <Typography sx={{fontSize:"18px", color:"#fff"}}>Launch</Typography>
                <Typography sx={{color:"#A89db4"}}>Want to launch a Project? Contact for more information!</Typography>
              </Box>

              <Box sx={{marginBottom:"1rem"}}>
                <Typography sx={{fontSize:"18px", color:"#fff"}}>Services</Typography>
                <Typography sx={{color:"#A89db4"}}>Need a team to launch a project? Contact Nftyhills team!</Typography>
              </Box>

              <Box sx={{marginBottom:"1rem"}}>
                <Typography sx={{fontSize:"18px", color:"#fff"}}>Career</Typography>
                <Typography sx={{color:"#A89db4"}}>Want to join the future of web3? Contact Nftyhills to join our ever growing team!</Typography>
              </Box>
                </Box>
            
            </Box>
        </Grid>
    </Grid>

    </Box>
   
  )
}

export default Footer