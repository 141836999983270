import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import { useNavigate } from 'react-router-dom';

function SidebarItem({data}) {
    const [show, setshow] = useState(false)
    const navigate = useNavigate()
  return (
    <Box>
    <Box sx={{display:"flex", flexDirection:"row", color:"#ffffff", alignItems:"center", justifyContent:"space-between", px:1 , height:"44px", my:"0.2rem", "&:hover":{backgroundColor:"#4E4E55", borderRadius:"5px"} }}>
      <Box sx={{display:"flex", flexDirection:"row", color:"#ffffff",  alignItems:"center",   }} onClick={()=>{navigate(data.url)}}>
      {data.Icon}
      <Typography sx={{fontSize:"14px"}}>{data.Tittle}</Typography>
      </Box>
  
      
     { data.Up && <KeyboardArrowUpOutlinedIcon sx={ show ?{display:"block"} : {display:"none"}} onClick={()=>{setshow(!show)}}/>}
      
    
      {  data.Down && <KeyboardArrowDownOutlinedIcon sx={ !show ?{display:"block"} : {display:"none"}} onClick={()=>{setshow(!show)}} />
}

    </Box>
     
    { show && 
    data.subTitle.map((data, index)=>{
        return( 
        
        <Box key={index} sx={{pl:5}} onClick={()=>{navigate(data.url)}}>
            <Typography sx={{color:"#A197aa", "&:hover":{color:"#fff"}, fontSize:"14px"}}>{data.name}</Typography>
            
            </Box>)
    })
   
     } 
   </Box>
  
    )
}

export default SidebarItem