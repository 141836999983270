import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import {theme} from "./theme"
import { ThemeProvider } from '@emotion/react';
import {
  BrowserRouter,

} from "react-router-dom";
import CaroselContex from './components/CaroselContex';
import BottomTabNavigation from './components/BottomTabNavigation';
import { Web3ReactProvider } from "@web3-react/core";
import { Buffer } from 'buffer';
import { ethers } from "ethers";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

 


const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

window.Buffer = window.Buffer || Buffer;






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>  
  <BrowserRouter>
 
 < CaroselContex>
 <Web3ReactProvider getLibrary={getLibrary}>
 <App />
 </Web3ReactProvider>
 </CaroselContex>

  </BrowserRouter>
</ThemeProvider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

