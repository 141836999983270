import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import TelegramIcon from '@mui/icons-material/Telegram';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import discord from '../img/discord.png';

export const sidebarData= [
{
    Tittle:"Marketplace",
    Icon:<StorefrontOutlinedIcon  sx={{mr:"1rem",fontWeight:"300", fontSize:"19px"}}/>,
    subTitle:[{name:"Collection", url:"/collections"}, {name:"All Items", url:"/allitems"}],
    Up:true,
    Down:true,
    url:"#"
    
    

},
{
    Tittle:"Insight",
    Icon:<InsightsOutlinedIcon  sx={{mr:"1rem",fontWeight:"300", fontSize:"19px"}}/>,
    subTitle:[{name:"Stats", url:"/stats"}],
    Up:true,
    Down:true
 
}

,
{
    Tittle:"Launchpad",
    Icon:<RocketLaunchOutlinedIcon   sx={{mr:"1rem", fontWeight:"300", fontSize:"19px"}}/>,
    subTitle:[{name:"Launchpad", url:"/launchpad"}, {name:"Apply for Launchpad", url:"/applylisting"}],
    Up:true,
    Down:true
}

,
{
    Tittle:"Creators",
    Icon:<CreateNewFolderOutlinedIcon  sx={{mr:"1rem", fontWeight:"300", fontSize:"19px"}}/>,
    subTitle:[{name:"Apply for Launchpad", url:"/applylunchpad"}, {name:"Apply for Listing",url:"/applylisting"}, {name:"Start project", url:"/startproject"}],
    Up:true,
    Down:true
 
}

,

{
    Tittle:"Dashboard",
    Icon:<DashboardOutlinedIcon sx={{mr:"1rem", fontWeight:"300", fontSize:"19px"}}/>,
    subTitle:[{name:"My Account", url:"/dashboard"}],
    Up:true,
    Down:true
 
}

,



{
    Tittle:"Resources",
    Icon:<BusinessIcon  sx={{mr:"1rem", fontWeight:"300", fontSize:"19px"}}/>,
    subTitle:[{name:"About", url:"/about"}, {name:"Career", url:'/career'}],
    Up:true,
    Down:true
 
}


,
{
    Tittle:"NFT Education",
    Icon:<LibraryBooksOutlinedIcon sx={{mr:"1rem", fontWeight:"300", fontSize:"19px"}}/>,
    subTitle:[],
    Up:false,
    Down:false,
    url:"/education"
 
}

,

{
    Tittle:"Twitter",
    Icon:           <a href='https://twitter.com/nfthills1' target="_blank"><TwitterIcon  sx={{mr:"1rem",fontWeight:"300", fontSize:"19px", color:"#3758A4"}}/></a>,
    subTitle:[],
    Up:false,
    Down:false,
   
 
}

,

{
    Tittle:"Discord",
    Icon:    <a href="https://discord.gg/P7VPdYg97m"   target="_blank"> <img src={discord}  style={{height:"20px", width:"20px",marginRight:"1rem", color:"red"}}/></a>, 
    subTitle:[],
    Up:false,
    Down:false,
 
}











]