import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {caroselContex} from './CaroselContex';

function UpcomingDrops() {
    const {upcoming} = useContext(caroselContex)
    const navigate = useNavigate()
  return (
    <Box sx={{my:"2rem"}}>

          <Typography sx={{fontSize:"30px", color:"#fff", fontWeight:"700"}}>Upcoming Drops</Typography>

    
    <Box  sx={{display:"flex", flexDirection:"row", alignItem:"center", flexWrap:"wrap"}}>
    {
          upcoming.map((data)=>{
              return( 
                  <Box key={data.id} sx={{display:"flex", flexDirection:"column", mt:3, border:"solid 0.5px #24182F", borderRadius:"20px", mr:2, mb:"1rem"}} onClick={()=>{navigate(`/launchpad/${data.name}`)}}>
                 <Box sx={{   "&:hover":{
            transform:`scale(1.1)`}}}>  <img src={data.image} alt="" style={{width:"218px", height:"165px", borderRadius:"20px"}}/> </Box>
                
                 
                  <Box sx={{}}>
                  
                      <Typography sx={{color:"#fff", fontSize:"14px"}}>{data.name}</Typography>
                      <Typography sx={{color:"#19669E", fontSize:"14px"}}>{data.name}</Typography>
                  </Box>
             
             
          
          
              </Box>)
          })
       }
  
       </Box>
     
  
  
      
      
  
      
  
    </Box>
   
  )
}

export default UpcomingDrops