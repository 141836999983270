/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSpendingAddress = /* GraphQL */ `
  query GetSpendingAddress($id: ID!) {
    getSpendingAddress(id: $id) {
      id
      address
      name
      createdAt
      updatedAt
    }
  }
`;
export const listSpendingAddresses = /* GraphQL */ `
  query ListSpendingAddresses(
    $filter: ModelSpendingAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpendingAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        address
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      twitter
      description
      images
      backgroundimage
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        twitter
        description
        images
        backgroundimage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByUsername = /* GraphQL */ `
  query UsersByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        twitter
        description
        images
        backgroundimage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCarosel = /* GraphQL */ `
  query GetCarosel($id: ID!) {
    getCarosel(id: $id) {
      id
      name
      description
      button
      image
      url
      type
      likes
      createdAt
      updatedAt
      username
    }
  }
`;
export const listCarosels = /* GraphQL */ `
  query ListCarosels(
    $filter: ModelCaroselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarosels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        button
        image
        url
        type
        likes
        createdAt
        updatedAt
        username
      }
      nextToken
    }
  }
`;
export const caresolByType = /* GraphQL */ `
  query CaresolByType(
    $type: CaroselType!
    $sortDirection: ModelSortDirection
    $filter: ModelCaroselFilterInput
    $limit: Int
    $nextToken: String
  ) {
    caresolByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        button
        image
        url
        type
        likes
        createdAt
        updatedAt
        username
      }
      nextToken
    }
  }
`;
export const getCollection = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      name
      description
      image
      backgroundimage
      twitter
      totalitems
      owners
      totalvolume
      floor
      items {
        items {
          id
          name
          image
          owner
          price
          token
          address
          tokenid
          rarityindex
          createdAt
          updatedAt
          collectionItemsId
        }
        nextToken
      }
      address
      Website
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCollections = /* GraphQL */ `
  query ListCollections(
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        backgroundimage
        twitter
        totalitems
        owners
        totalvolume
        floor
        items {
          nextToken
        }
        address
        Website
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const collectionByName = /* GraphQL */ `
  query CollectionByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectionByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        image
        backgroundimage
        twitter
        totalitems
        owners
        totalvolume
        floor
        items {
          nextToken
        }
        address
        Website
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      name
      image
      owner
      price
      token
      address
      tokenid
      rarityindex
      createdAt
      updatedAt
      collectionItemsId
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        owner
        price
        token
        address
        tokenid
        rarityindex
        createdAt
        updatedAt
        collectionItemsId
      }
      nextToken
    }
  }
`;
export const itemByOwner = /* GraphQL */ `
  query ItemByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        image
        owner
        price
        token
        address
        tokenid
        rarityindex
        createdAt
        updatedAt
        collectionItemsId
      }
      nextToken
    }
  }
`;
export const getLaunchpad = /* GraphQL */ `
  query GetLaunchpad($id: ID!) {
    getLaunchpad(id: $id) {
      id
      name
      escrow
      Website
      twitter
      Discord
      address
      type
      price
      description
      amount
      image
      privatesale
      privateprice
      privateamount
      publicsale
      publicprice
      publicamount
      whitelistsale
      whitelistprice
      whitelistamount
      Collection
      date
      Creator
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLaunchpads = /* GraphQL */ `
  query ListLaunchpads(
    $filter: ModelLaunchpadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLaunchpads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        escrow
        Website
        twitter
        Discord
        address
        type
        price
        description
        amount
        image
        privatesale
        privateprice
        privateamount
        publicsale
        publicprice
        publicamount
        whitelistsale
        whitelistprice
        whitelistamount
        Collection
        date
        Creator
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const launchpadByName = /* GraphQL */ `
  query LaunchpadByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLaunchpadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LaunchpadByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        escrow
        Website
        twitter
        Discord
        address
        type
        price
        description
        amount
        image
        privatesale
        privateprice
        privateamount
        publicsale
        publicprice
        publicamount
        whitelistsale
        whitelistprice
        whitelistamount
        Collection
        date
        Creator
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const launchpadByType = /* GraphQL */ `
  query LaunchpadByType(
    $type: LaunchpadType!
    $sortDirection: ModelSortDirection
    $filter: ModelLaunchpadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LaunchpadByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        escrow
        Website
        twitter
        Discord
        address
        type
        price
        description
        amount
        image
        privatesale
        privateprice
        privateamount
        publicsale
        publicprice
        publicamount
        whitelistsale
        whitelistprice
        whitelistamount
        Collection
        date
        Creator
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const launchpadByCreator = /* GraphQL */ `
  query LaunchpadByCreator(
    $Creator: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLaunchpadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LaunchpadByCreator(
      Creator: $Creator
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        escrow
        Website
        twitter
        Discord
        address
        type
        price
        description
        amount
        image
        privatesale
        privateprice
        privateamount
        publicsale
        publicprice
        publicamount
        whitelistsale
        whitelistprice
        whitelistamount
        Collection
        date
        Creator
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
