import { Box, Button, Typography} from '@mui/material'
import React  from 'react'
import {sidebarData} from "./SidebarData"
import SidebarItem from './SidebarItem';
import flag from "../img/flag.PNG"

import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate()
 
  return (
    <Box sx={{borderRight:"solid 2px #24182F", width:"95%", height:"85vh", position:"sticky", top:"95px", pr:1}}>
  <Box sx={{position:"relative", height:"85vh"}}>
  
  {sidebarData.map((data, index)=>{
      return(
     <SidebarItem key={index}  data={data}  />)
  }) } 

  
<Box
          sx={{
         
          
            flexDirection: "row",
            paddingRight: "30px",
            justifyContent: "center",
            alignItems: "center",
            display:{xs:"none", sm:"flex"},
            position:"absolute",
            bottom:"10px"
          }}
        >
          <p style={{ color: "#C7c0ce" }}>Language:</p>
          <Button
            sx={{
              backgroundColor: "#C9D6DF",
              width: "50px",
              height: "22px",
              color: "#383A45",
              marginLeft: "7px",
              borderRadius: "5px",
              flexDirection:"row"
            }}
            variant="contained"
          
          >
            <img src={flag} style={{marginRight:"7px", width:"20px",height:"12px" }} alt=""/>

            ENG
          </Button>
          
        </Box>
        </Box>
  </Box>
  )
}

export default Sidebar
