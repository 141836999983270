import React from 'react'
import Sidebar from "./Sidebar"
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import NftCarosel from './NftCarosel'
import Navbar from './Navbar'
import HomeFooter from './HomeFooter'
import LaunchpadDrops from './LaunchpadDrops'
import PopularCollection from './PopularCollection'
import Live from './Live'
import TopDrops from './TopDrops'
import NewListing from './NewListing'
import BottomTabNavigation from './BottomTabNavigation'
import GetStarted from './GetStarted'
import ClaimDrop from './ClaimDrop'



function Home() {

  
  return (
    <Box>
      <Navbar/>
 <Box sx={{backgroundColor:"#120C18", pl:2, pr:2}}>
<Grid container>
  <Grid item xs={0} sm={2} sx={{display:{xs:"none",sm:"block"}}}><Sidebar/></Grid>
  
  <Grid item xs={12} sm={10} sx={{pl:"1rem"}}><NftCarosel/>




  <PopularCollection />
   

  <ClaimDrop/>

  <LaunchpadDrops/>
 
  <Live/>
  <TopDrops/>
  <NewListing/>
  <GetStarted/>
  <HomeFooter/>
  

  </Grid>
</Grid>


    </Box>
  
    </Box>
   
  )
}

export default Home

