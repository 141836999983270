import React, { useEffect, useState } from 'react'
import CategoryIcon from '@mui/icons-material/Category';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputBase, Typography } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify'
import { collectionByName, itemByOwner, launchpadByCreator } from '../graphql/queries'
import { Auth } from 'aws-amplify'

function Created() {

  const [nftItem, setNftItem] = useState([])
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
   
  fetchdata( )
  
  
  }, [])


  async function fetchdata(){
     
    try{
      const user = await Auth.currentAuthenticatedUser();
 
      const data =   await API.graphql(graphqlOperation(launchpadByCreator, {Creator:user}))
      
      const nftItems = data.data.LaunchpadByCreator.items 
    
      console.log( nftItems)

      setNftItem([...nftItem, ...nftItems])  
   
      console.log(nftItem)
      setLoading(false)
       
     }
     catch(err){
           console.log("user has no items")
    }

  }



  return (
    <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
    <Box sx={{borderTop:"solid 0.5px #24182F", width:"90%" ,py:"1rem"}}>
      <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", width:{xs:"100%", sm:"80%"}}}>
       <Box sx={{display:{xs:"none", sm:"flex"}, flexDirection:"row", alignItems:"center"}}><CategoryIcon sx={{color:"#1231B0", fontSize:"18px"}}/> <Typography sx={{color:"#1231B0",ml:"1rem", fontSize:"18px"}}>Created Items</Typography></Box><Box
            sx={{
              borderRadius: "20px",
              width: {xs:"100%", sm:"60%"}, 
              backgroundColor: "#4E4E55",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "4px",
  
          
            }}
          >
            <SearchIcon sx={{ marginRight: "10px", color: "#000000" }} />
            <InputBase placeholder="search items" sx={{width:"100%"}} onChange={(e)=>{
            
              } } /> </Box>

                          
            </Box>
    </Box>
  
    <Box sx={{mt:"2rem", display:"flex", flexDirection:"row" ,px:"3rem"}}>
    
   
{loading &&
  <Box sx={{ height:"200px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
  <Typography sx={{color:"#fff"}}>None Created</Typography>
  </Box>
}

 
<Box sx={{mt:"2rem", display:"flex", flexDirection:{xs:"column", sm:"row"}, flexWrap:"wrap" , justifyContent:"center", alignItems:"center"}}>

{ !loading &&nftItem.map((data)=>{
 return( 
 
 <Box key={data.item} sx={{ ml:{xs:"0rem",sm:"1rem"}, mb:"1rem" , borderRadius:"15px", border:"solid 0.5px #24182F", 
}} >

    <Box sx={{position:"relative",width:"288px", height:"288px"}}>
 
    <img src={data.image} alt="" style={{width:"288px", height:"288px" ,borderRadius:"15px"} }/>
    </Box>

<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
<Box sx={{px:"12px"}}>
<Typography sx={{color:"#fff" , fontWeight:"700"}}>{data.name}</Typography>
<Typography sx={{color:"#1231B0"}}>{data.Creator}</Typography>

</Box>
   

</Box>


</Box>)
})} 


 

 
   
 </Box>

  
    
  
    
      
    </Box>
  
  </Box>
  )
}

export default Created