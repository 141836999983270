import { Alert, Box, Button, Grid, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';

import { ethers } from "ethers";
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

import { useNavigate, useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getItem } from '../graphql/queries';
import eth from "../img/eth.png"
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Block } from '@mui/icons-material';
import { useWeb3React } from '@web3-react/core';

function Buy() {  


const [count, setcount] = useState(1)
const [metamask, setMetamask] = useState(false);
const [namee, setNamee] = useState("")
const [nftitems, setNftitems] = useState({})
const [transaction, setTransaction] = useState([])



let balance;
let signer;
const addresspay="0xaeF04B89Ba955790D24858E93c661eA380262Eb5";
const [error, setError] = useState(false);
const [error1, setError1] = useState(false);
const [price, setPrice] = useState(1)
const [txs, setTxs] = useState([]);
const [txs1, setTxs1] = useState(false);
const [message, setmessage] = useState("")

const { activate, active,  library, account } = useWeb3React();



const { id } = useParams();
const navigate = useNavigate();

useEffect(() => {
    fetchItems()

  
  }
, [])



async function fetchItems(){
  
    try{
      const data =   await API.graphql(graphqlOperation(getItem, {id:id}))
      const nftItems = data.data.getItem
     
      console.log(nftItems)
 
      setNftitems(nftItems)


      
let etherscanProvider = new ethers.providers.EtherscanProvider("homestead","Z2XYP4D1Z4TU87KHGDX472IEVIIMKKMG4R");


await  etherscanProvider.getHistory(nftItems.address).then((history) => {
    setTransaction(history.slice(0, 30))
   
 

});
console.log(transaction)
       
     }
     catch(err){
           console.log(err)
    }
 
  }
 


async function Connect() {
  
    try {
      if (!active)
        throw new Error("check if you wallet is connected..");
    setPrice(nftitems.price.toString())
    console.log(price)

     setError(false)
    const  signer =  library.getSigner()
    const  address = await signer.getAddress()
   
    
    try{
  
      const tx = {
        from: address,
        to: addresspay,
        value: ethers.utils.parseEther(price),
        gasLimit: ethers.utils.hexlify(10000000),
        gasPrice: ethers.utils.hexlify(parseInt(await library.getGasPrice())),
    };
    
    signer.sendTransaction(tx).then((transaction) => {
        console.dir(transaction);
       
    });

      console.log("tx", tx);
      setTxs1(true)
      setTxs([tx]);
    } catch(err){
      setError1()
      console.log(err.message)
      setmessage(error.message)
    }
     
     
    
    } catch (err) {
      setError(true);
 
    }
  };



  

  


        
      




 


 


  



 

  return (

    <Box sx={{backgroundColor:"#120C18"}}>
        <Navbar/>
<Grid container>
          <Grid
            item
            xs={0}
            sm={2}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Sidebar />
          </Grid>

          <Grid item xs={12} sm={10}>
                <Grid container sx={{ p: { sm: 2 } }}>
                
                  <Grid item xs={12} sm={6} sx={{p:"1rem"}}>
                   <Box sx={{"&:hover":{transform:`scale(1.02)`}}}>
                   <img
                      src={nftitems.image}
                      alt=""
                      style={{ width: "100%", height: "100%", borderRadius:"20px" }}
                    />
                   </Box>
                
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box sx={{ width: { xs: "100%", sm: "80%" } , p:"1rem", mb:"5rem"}}>
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontSize: "26px",
                          fontWeight: "700",
                        }}
                      >
                        ItemName:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontSize: "22px",
                          fontWeight: "700",
                          mb:"1rem"
                        }}
                      >
                        {nftitems.name}
                      </Typography>
                      <Box sx={{display:"flex", flexDirection:'row',alignItems:"center"}}>
                 
                     
                      </Box>
                    
            
    
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          border: "solid 0.5px #24182F",
                          p: 2,
                          borderRadius: "10px",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 4,
                          }}
                        >
                          <Typography sx={{ color: "#ffffff" }}>
                            Blockchain
                          </Typography>
                          <Typography sx={{ color: "#186ABC" }}>ETHEREUM</Typography>
                        </Box>
    
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 4,
                          }}
                        >
                          <Typography sx={{ color: "#ffffff" }}>
                            Token Standard
                          </Typography>
                          <Typography sx={{ color: "#186ABC" }}>ERC- 721</Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 4,
                          }}
                        >
                          <Typography sx={{ color: "#ffffff" }}>
                            Price
                          </Typography>
                          <Typography sx={{ color: "#186ABC", display:"flex", flexDirection:"row", alignItems:"center" }}>{nftitems.price}<img src={eth} style={{width:"30px", height:"20px"}}/></Typography>
                        </Box>
                        
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 4,
                          }}
                        >
                          <Typography sx={{ color: "#ffffff" }}>
                            Creator Earning 
                          </Typography>
                          <Typography sx={{ color: "#186ABC" }}>10%</Typography>
                        </Box>

                          
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 4,
                          }}
                        >
                          <Typography sx={{ color: "#ffffff" }}>
                            Rarity Index  
                          </Typography>
                          <Typography sx={{ color: "#186ABC" }}>{nftitems.rarityindex}</Typography>
                        </Box>

                  
                      </Box>
    
                       <Button variant="outlined" sx={{width:"100%", mb:"1rem"}} onClick={Connect}>Buy</Button>
                       {error && <Alert severity="error">check that you have a wallet connected</Alert>} 
                      {error1 && <Alert severity="error">{error}</Alert>} 
                       
                  
                    </Box>
                  </Grid>
                </Grid>
             
      <Box sx={{display:{xs:"none", sm:"block", }}}>
        <Button variant="outlined" sx={{ml:"1rem"}}>Activities</Button>
      <Box sx={{overflowX:{xs:"show", sm:"hidden"}, width:"100%"}}>
      
      


      <Box sx={{mt:"2rem",mb:"1rem",display:"flex", flexDirection:"column", alignItem:"center" ,alignItems:"center"}}>
    
       <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"space-between", width:"100%"}}>
     
      
       <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", width:"100%",}}>
       <Box sx={{width:{xs:"300px", sm:"25%"},display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>event</Typography> </Box>
           <Box sx={{width:{xs:"300px", sm:"25%"},display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>from</Typography> </Box>
           <Box sx={{width:{xs:"300px", sm:"25%"},display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>to</Typography> </Box>
           <Box sx={{width:{xs:"300px", sm:"25%"},display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'16px', fontWeight:"700"}}>time</Typography> </Box>
        
          
       </Box>
  
       </Box>

<Box>
   
</Box>

</Box>


<Box>
 {transaction.map((data)=>{
   return( <Box key={data.id} sx={{py:"1rem",display:"flex", flexDirection:"row",alignItems:"center",justifyContent:"center",  backgroundColor:"#1c1326", mb:"1rem" }}>
    

   {/* stats */}
   

    <Box sx={{display:"flex", flexDirection:'row', alignItems:"center", width:"100%" }}>
         <Box sx={{width:{xs:"300px", sm:"25%"},display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'10px'}}>Transfer</Typography> < OutputOutlinedIcon sx={{fontSize:"12px", color:"#fff"}}/></Box>
         <Box sx={{width:{xs:"300px", sm:"25%"},display:"flex", flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'10px', textOverflow:"ellipsis"}}>{data.to}</Typography> </Box>
         <Box sx={{width:{xs:"300px", sm:"25%"},display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'10px', textOverflow:"ellipsis",}}>{data.from}</Typography> </Box>
       
         <Box sx={{width:{xs:"300px", sm:"25%"},display:{xs:"none", sm:"flex"}, flexDirection:'row', alignItems:"center", justifyContent:"center"}}> <Typography sx={{color:"#fff", fontSize:'10px', textOverflow:"ellipsis"}}>{ new Date(data.timestamp*1000).toLocaleString()}</Typography> </Box>

        
     </Box>

   </Box>

  
  
  
  
  
  
  
  
 
   




)
 })}
</Box>

     
     </Box>
      </Box>
              </Grid>
        </Grid>

  
    </Box>
 
 
  )
}

export default Buy