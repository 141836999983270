import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Box } from '@mui/system';
import { Button, Grid, Skeleton, Typography } from '@mui/material';
import gcarasol1 from "../img/gcarasol1.PNG"
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react';
import {caroselContex} from './CaroselContex';





function NftCarosel() {

    const {carosel} = useContext(caroselContex)
    const navigate = useNavigate()

    if(carosel.length <= 0){
       return (<Box>

        <Box sx={{ display:{xs:"none", sm:"block"}}}>
       
       
                    
                    <Grid container sx={{border:"solid 0.5px #24182F", borderRadius:"30px",}}>
                    <Grid item xs={12} sm={5} >
                        <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"start", backgroundColor:"#120C18", height:"100%", pl:4, borderTopLeftRadius:"30px", borderBottomLeftRadius:"30px"}}>
                        <Skeleton variant="text" width={"90%"} height={"32px"} sx={{ fontSize: '1rem', }} />
                        <Skeleton variant="text"  width={"90%"} height={"20vh"} sx={{ fontSize: '1rem',}} />
                        <Skeleton variant="text"  width={"90%"} height={"px"} sx={{ fontSize: '1rem',  }} />
                           
            
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                    <Box sx={{ml:-0.2}}>
                    <Skeleton variant="rounded" width={"100%"} height={"90vh"} sx={{}}/>
                           
                    </Box>
                    </Grid>
            
                 </Grid>
          
        
        
                
            
        </Box>
       
       {/* mobile */}
        <Box sx={{ display:{xs:"block", sm:"none", mt:"1rem"}}}>
        
    

      
        <Grid container sx={{border:"solid 0.5px #24182F", borderRadius:"30px",}}>
          
        <Grid item xs={12} sm={7}>
            <Box sx={{ml:-0.2}}>
                <Skeleton variant="rounded" width={"100vh"} height={"100vh"}/>
            </Box>
            </Grid>
        
          
            <Grid item xs={12} sm={5} >
                <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", backgroundColor:"#120C18", height:"100%", pl:{xs:0, sm:4}, borderTopLeftRadius:"30px", borderBottomLeftRadius:"30px", pb:"2rem"}}>
                <Skeleton variant="text" width={"90%"} height={"32px"} sx={{ fontSize: '1rem', }} />
                        <Skeleton variant="text"  width={"90%"} height={"20vh"} sx={{ fontSize: '1rem',}} />
                        <Skeleton variant="text"  width={"90%"} height={"32px"} sx={{ fontSize: '1rem',  }} />
        
                </Box>
            </Grid>
           
        
         </Grid>
      
        </Box>

      </Box>)
        
    }
    
   
   
    
  return (

      <Box>

        <Box sx={{ display:{xs:"none", sm:"block"}}}>
       
        <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
    
            {
              carosel.length >0 &&  carosel.map((data)=>{
                    return(
                    
                    <Grid key={data.id} container sx={{border:"solid 0.5px #24182F", borderRadius:"30px",}}>
                    <Grid item xs={12} sm={5} >
                        <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"start", backgroundColor:"#120C18", height:"100%", pl:4, borderTopLeftRadius:"30px", borderBottomLeftRadius:"30px"}}>
                            <Typography sx={{fontSize:"48px", color:"#ffffff",  textAlign:"start" }}>{data.name}</Typography>
                            <Typography sx={{fontSize:"16px", color:"#A197aa", my:2, textAlign:"start"}}>{data.description}</Typography>
                             <Button variant="contained"   onClick={()=>{navigate("/collections")}}> Explore Collection</Button>
            
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                    <Box sx={{ml:-0.2}}>
                        <img src={data.image} alt="" style={{width:"100%", height:"100%", borderRadius:"20px"}}/>
                    </Box>
                    </Grid>
            
                 </Grid>)
                })
            }
        
        
                </Carousel>
        </Box>
       
       {/* mobile */}
        <Box sx={{ display:{xs:"block", sm:"none", mt:"1rem"}}}>
        <Carousel autoPlay={true} interval={2000} infiniteLoop={true} showThumbs={false} >
    

       {carosel.map((data) =>{
        return(<Grid container key={data.id} sx={{border:"solid 0.5px #24182F", borderRadius:"30px",}}>
          
        <Grid item xs={12} sm={7}>
            <Box sx={{ml:-0.2}}>
                <img src={data.image} alt="" style={{width:"100%", height:"100%", borderRadius:"20px"}}/>
            </Box>
            </Grid>
        
          
            <Grid item xs={12} sm={5} >
                <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", backgroundColor:"#120C18", height:"100%", pl:{xs:0, sm:4}, borderTopLeftRadius:"30px", borderBottomLeftRadius:"30px", pb:"2rem"}}>
                    <Typography sx={{fontSize:"48px", color:"#ffffff"}}>{data.name}</Typography>
                    <Typography sx={{fontSize:"16px", color:"#A197aa", my:2}}>{data.description}</Typography>
                     <Button variant="contained" onClick={()=>{navigate("/collections")}}> Explore Collection</Button>
        
                </Box>
            </Grid>
           
        
         </Grid>)
       })
       
       }
            
        
      
        </Carousel>
        </Box>

      </Box>
  


  )
}

export default NftCarosel

