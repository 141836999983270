import { Box, InputBase, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from 'react';
import {caroselContex} from './CaroselContex';
import { Link, useNavigate } from 'react-router-dom'

function Search() {
    const [query, setQuery] = useState("")
  const [filtered, setFiltered] = useState([])
  const [filtered2, setFiltered2] = useState([])
  const {collections} = useContext(caroselContex)
  const {upcoming} = useContext(caroselContex)

  const navigate = useNavigate()

    
  const filteredCollection = collections.filter((value)=>{
    return value.name.toLowerCase().includes(query.toLowerCase()) 
  })

  const filteredCollection2 = upcoming.filter((value)=>{
    return value.name.toLowerCase().includes(query.toLowerCase()) 
  })

useEffect(() => {
    if (query === ""){
        setFiltered([])
        setFiltered2([])
      
      }else{
        setFiltered(filteredCollection) 
        setFiltered2(filteredCollection2)
     
      }
}, [query])




  return (
    <Box sx={{position:'relative', width: {xs:"100%", sm:"100%"}, ml:{xs:"10%"}}}>
    <Box
    sx={{
      borderRadius: "20px",
      backgroundColor: "#4E4E55",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "4px",

  
    }}
  >
    <SearchIcon sx={{ marginRight: "10px", color: "#000000", display:{xs:"none", sm:"block"} }} />
    <InputBase placeholder="search Collections and Creators " sx={{width:"100%"}} onChange={(e)=>{
      setQuery(e.target.value)
      
      } } />
  </Box>

<Box sx={{position:"absolute", top:"30px", left:"0px", zIndex:10, width:"100%", p:"12px 12px", }}>
{filtered.length > 0 &&  <Box sx={{backgroundColor:"#000"}}>
       <Box sx={{backgroundColor:"#120C18" ,width:"100%", height:"40px", display:"flex", flexDirection:"row", alignItems:"center"}}><Typography sx={{color:"#fff", fontSize:"18px", fontWeight:"700"}}>Collections</Typography></Box>
      <Box>
      { filtered.map((data, index)=>{
         return <Box key={index} sx={{ width:"100%", height:"40px", ml:"1.5rem",display:"flex", flexDirection:"row", alignItems:"center"}} onClick={()=>{navigate(`/collections/${data.name}`)}}>
         <img src={data.image} style={{width:"30px", height:"30px", borderRadius:"50%"}}/>
          <Typography sx={{color:"#fff",ml:"0.2rem"}}>{data.name}</Typography>
          </Box>
       })} 
      </Box>
     
    </Box>}

    {filtered2.length > 0 &&  <Box sx={{backgroundColor:"#000"}}>
       <Box sx={{backgroundColor:"#120C18" ,width:"100%", height:"40px", display:"flex", flexDirection:"row", alignItems:"center"}}><Typography sx={{color:"#fff", fontSize:"18px", fontWeight:"700"}}>Creators</Typography></Box>
        <Box>
        { filtered2.map((data, index)=>{
         return <Box key={index} sx={{ width:"100%", height:"40px", ml:"1.5rem",display:"flex", flexDirection:"row", alignItems:"center"}} onClick={()=>{navigate(`/launchpad/${data.name}`)}}>
          <img src={data.image} style={{width:"30px", height:"30px", borderRadius:"50%"}}/>
          <Typography sx={{color:"#fff",ml:"0.2rem"}}>{data.name}</Typography>
          </Box>
       })}
        </Box>
       
    </Box>}
</Box>
    </Box>
 
  )
}

export default Search