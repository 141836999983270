/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSpendingAddress = /* GraphQL */ `
  mutation CreateSpendingAddress(
    $input: CreateSpendingAddressInput!
    $condition: ModelSpendingAddressConditionInput
  ) {
    createSpendingAddress(input: $input, condition: $condition) {
      id
      address
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateSpendingAddress = /* GraphQL */ `
  mutation UpdateSpendingAddress(
    $input: UpdateSpendingAddressInput!
    $condition: ModelSpendingAddressConditionInput
  ) {
    updateSpendingAddress(input: $input, condition: $condition) {
      id
      address
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpendingAddress = /* GraphQL */ `
  mutation DeleteSpendingAddress(
    $input: DeleteSpendingAddressInput!
    $condition: ModelSpendingAddressConditionInput
  ) {
    deleteSpendingAddress(input: $input, condition: $condition) {
      id
      address
      name
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      twitter
      description
      images
      backgroundimage
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      twitter
      description
      images
      backgroundimage
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      twitter
      description
      images
      backgroundimage
      createdAt
      updatedAt
    }
  }
`;
export const createCarosel = /* GraphQL */ `
  mutation CreateCarosel(
    $input: CreateCaroselInput!
    $condition: ModelCaroselConditionInput
  ) {
    createCarosel(input: $input, condition: $condition) {
      id
      name
      description
      button
      image
      url
      type
      likes
      createdAt
      updatedAt
      username
    }
  }
`;
export const updateCarosel = /* GraphQL */ `
  mutation UpdateCarosel(
    $input: UpdateCaroselInput!
    $condition: ModelCaroselConditionInput
  ) {
    updateCarosel(input: $input, condition: $condition) {
      id
      name
      description
      button
      image
      url
      type
      likes
      createdAt
      updatedAt
      username
    }
  }
`;
export const deleteCarosel = /* GraphQL */ `
  mutation DeleteCarosel(
    $input: DeleteCaroselInput!
    $condition: ModelCaroselConditionInput
  ) {
    deleteCarosel(input: $input, condition: $condition) {
      id
      name
      description
      button
      image
      url
      type
      likes
      createdAt
      updatedAt
      username
    }
  }
`;
export const createCollection = /* GraphQL */ `
  mutation CreateCollection(
    $input: CreateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    createCollection(input: $input, condition: $condition) {
      id
      name
      description
      image
      backgroundimage
      twitter
      totalitems
      owners
      totalvolume
      floor
      items {
        items {
          id
          name
          image
          owner
          price
          token
          address
          tokenid
          rarityindex
          createdAt
          updatedAt
          collectionItemsId
        }
        nextToken
      }
      address
      Website
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCollection = /* GraphQL */ `
  mutation UpdateCollection(
    $input: UpdateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    updateCollection(input: $input, condition: $condition) {
      id
      name
      description
      image
      backgroundimage
      twitter
      totalitems
      owners
      totalvolume
      floor
      items {
        items {
          id
          name
          image
          owner
          price
          token
          address
          tokenid
          rarityindex
          createdAt
          updatedAt
          collectionItemsId
        }
        nextToken
      }
      address
      Website
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCollection = /* GraphQL */ `
  mutation DeleteCollection(
    $input: DeleteCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    deleteCollection(input: $input, condition: $condition) {
      id
      name
      description
      image
      backgroundimage
      twitter
      totalitems
      owners
      totalvolume
      floor
      items {
        items {
          id
          name
          image
          owner
          price
          token
          address
          tokenid
          rarityindex
          createdAt
          updatedAt
          collectionItemsId
        }
        nextToken
      }
      address
      Website
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      name
      image
      owner
      price
      token
      address
      tokenid
      rarityindex
      createdAt
      updatedAt
      collectionItemsId
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      name
      image
      owner
      price
      token
      address
      tokenid
      rarityindex
      createdAt
      updatedAt
      collectionItemsId
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      name
      image
      owner
      price
      token
      address
      tokenid
      rarityindex
      createdAt
      updatedAt
      collectionItemsId
    }
  }
`;
export const createLaunchpad = /* GraphQL */ `
  mutation CreateLaunchpad(
    $input: CreateLaunchpadInput!
    $condition: ModelLaunchpadConditionInput
  ) {
    createLaunchpad(input: $input, condition: $condition) {
      id
      name
      escrow
      Website
      twitter
      Discord
      address
      type
      price
      description
      amount
      image
      privatesale
      privateprice
      privateamount
      publicsale
      publicprice
      publicamount
      whitelistsale
      whitelistprice
      whitelistamount
      Collection
      date
      Creator
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLaunchpad = /* GraphQL */ `
  mutation UpdateLaunchpad(
    $input: UpdateLaunchpadInput!
    $condition: ModelLaunchpadConditionInput
  ) {
    updateLaunchpad(input: $input, condition: $condition) {
      id
      name
      escrow
      Website
      twitter
      Discord
      address
      type
      price
      description
      amount
      image
      privatesale
      privateprice
      privateamount
      publicsale
      publicprice
      publicamount
      whitelistsale
      whitelistprice
      whitelistamount
      Collection
      date
      Creator
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLaunchpad = /* GraphQL */ `
  mutation DeleteLaunchpad(
    $input: DeleteLaunchpadInput!
    $condition: ModelLaunchpadConditionInput
  ) {
    deleteLaunchpad(input: $input, condition: $condition) {
      id
      name
      escrow
      Website
      twitter
      Discord
      address
      type
      price
      description
      amount
      image
      privatesale
      privateprice
      privateamount
      publicsale
      publicprice
      publicamount
      whitelistsale
      whitelistprice
      whitelistamount
      Collection
      date
      Creator
      createdAt
      updatedAt
      owner
    }
  }
`;
