

import {
 
  Routes,
  Route,
} from "react-router-dom";
import Auction from './components/Auction';
import About from './components/About';
import Career from './components/Career';
import Details from './components/Details';
import Account from "./components/Account"
import Dashboard from './components/Dashboard';
import MyCollections from './components/MyCollections';
import Collections from './components/Collections';
import Created from './components/Created';
import Activity from './components/Activity';
import Stats from './components/Stats';
import Home from './components/Home';
import Launch from "./components/Launch";
import LaunchPad from "./components/LaunchPad";
import SignUp from "./components/SignUp";
import Education from "./components/Education";
import Policy from "./components/Policy";
import Terms from "./components/Terms";
import Now from "./components/Now";
import Buy from "./components/Buy";
import StartProject from "./components/StartProject";
import ApplyListing from "./components/ApplyListing";
import ApplyLunchpad from "./components/ApplyLunchpad";
import Edit from "./components/Edit";
import Team from "./components/Team";
import AllItems from "./components/AllItems";
import Admin from "./components/Admin";
import Tawin from "./components/Tawin";
import Wallet from "./components/Wallet";







function App() {





  return (

<Routes> 
    <Route path="/" element={<Home/>} />
    <Route path="details" element={<Details/>}/>
    <Route path="listing" element={<Auction/>}/>
    <Route path="account" element={<Account/>}/>
    <Route path="about" element={<About/>}/>
    <Route path="career" element={<Career/>}/>
    <Route path="collections" element={<Collections/>}/>
    <Route path="signup" element={<SignUp/>}/>
    <Route path="education" element={<Education/>}/>
    <Route path="stats" element={<Stats/>}/>
    <Route path="kcpresh" element={<Admin/>} />
    <Route path="allitems" element={<AllItems/>}/>
    <Route path="policy" element={<Policy/>}/>
    <Route path="startproject" element={<StartProject/>}/>
    <Route path="applylisting" element={<ApplyListing/>}/>
    <Route path="applylunchpad" element={<ApplyLunchpad/>}/>
    <Route path="terms&conditions" element={<Terms/>}/>
   
   <Route path="launch" element={<Launch/>}/>
   <Route path="edit" element={<Edit/>}/>
   <Route path="team" element={<Team/>}/> 
   <Route path="/tawin" element={<Tawin/>}/> 

   <Route path="launchpad" element={<LaunchPad/>}/> 
   <Route path="launchpad/:name" element={<Launch/>} />
   <Route path="launchpadNow/:name" element={<Now/>} />
   

     <Route path="collections/:owner" element={<Details/>} />

     <Route path="collectionsBuy/:id" element={<Buy/>} />
 
 
  
  
 
     <Route path="dashboard" element={<Dashboard/>}>
   
       <Route path="created" element={<Created/>}/>
       <Route path="activity" element={<Activity/>}/>
       <Route path="collections" element={<MyCollections/>}/>
       <Route path="wallet" element={<Wallet/>}/>
     </Route>
 
   
   
     
  </Routes>
   
    
    
  )
}

export default App

