import React from 'react'
import CategoryIcon from '@mui/icons-material/Category';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputBase, Typography } from '@mui/material';

function Activity() {
  return (
    <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", mb:"3rem"}}>
    <Box sx={{borderTop:"solid 0.5px #24182F", width:"90%" ,py:"1rem"}}>
      <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", width:{xs:"100%", sm:"80%"}}}>
       <Box sx={{display:{xs:"none",sm:"flex"}, flexDirection:"row", alignItems:"center"}}><CategoryIcon sx={{color:"#1231B0", fontSize:"18px"}}/> <Typography sx={{color:"#1231B0",ml:"1rem", fontSize:"18px"}}>Activity</Typography></Box><Box
            sx={{
              borderRadius: "20px",
              width: {xs:"100%", sm:"60%"}, 
              backgroundColor: "#4E4E55",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "4px",
  
          
            }}
          >
            <SearchIcon sx={{ marginRight: "10px", color: "#000000" }} />
            <InputBase placeholder="search items" sx={{width:"100%"}} onChange={(e)=>{
            
              } } /> </Box></Box>
    </Box>
  
    <Box sx={{mt:"2rem", display:"flex", flexDirection:"row",px:"3rem"}}>
    
   
  
  
    <Box sx={{ height:"200px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
    <Typography sx={{color:"#fff"}}>No Activity</Typography>
    </Box>
  
    
      
    </Box>
  
  </Box>
  )
}

export default Activity