import { Button, Grid, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { API } from 'aws-amplify';
import React, { useState } from 'react'
import { createSpendingAddress } from '../graphql/mutations';

function Wallet() {
    const [name, setname] = useState("SP")
    const [name2, setname2] = useState("Pk")
    const [address, setaddress] = useState("")
    const [address2, setaddress2] = useState("")


    async function execute(e) {
        e.preventDefault()
      
        try {
            // Switch authMode to API_KEY for public access
          
        const obj = {address:address,   name:name }
        await API.graphql({ query:createSpendingAddress,
          variables: { input:obj} ,
        })
         
        
          } catch (err) {
            console.log(err);
          }
    
    
      }

      async function execute2(e) {
        e.preventDefault()
      
        try {
            // Switch authMode to API_KEY for public access
          
        const obj = {address:address2,   name:name2 }
        await API.graphql({ query:createSpendingAddress,
          variables: { input:obj} ,
        })
         
        
          } catch (err) {
            console.log(err);
          }
    
    
      }

  return (
 <Box sx={{px:"20vw", py:"3vw"}}>
     <Grid container >
  <Grid item xs={12} sm={5} sx={{border:"1px solid #fff", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", px:"1rem", py:"2rem", borderRadius:"20px", }}>
  <TextField
          id="filled-basic"
          label="seedphrase"
          variant="filled"
          name="description"
          required
          multiline
          rows={3}
          onChange={(e)=>{setaddress(e.target.value)}}
          
      
          sx={{
            backgroundColor:"#4e4e55",
            width: "100%",
            marginBottom: "1rem",
            fontSize:"700",
            borderRadius:"2px"
            
          }}
        />

        <Typography sx={{color:"#fff", textAlign:"center", my:"1rem"}}>securely import wallets with seedphrase to make trades</Typography>
        <Button variant='contained' sx={{}} onClick={()=>execute()}>Import Wallet</Button>
  </Grid>

<Grid item  xs={12} sm={2} sx={{height:"20px"}}></Grid>

  <Grid item xs={12} sm={5} sx={{border:"1px solid #fff", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", px:"1rem", py:"2rem", borderRadius:"20px", }}>
  <TextField
          id="filled-basic"
          label="private Key"
          variant="filled"
          name="description"
          required
          onChange={(e)=>{setaddress(e.target.value)}}
         
      
          sx={{
            backgroundColor:"#4e4e55",
            width: "100%",
            marginBottom: "1rem",
            fontSize:"700",
            borderRadius:"2px"
            
          }}
        />

        <Typography sx={{color:"#fff", textAlign:"center", my:"1rem"}}>securely import wallets with private key to make trades</Typography>
        <Button variant='contained' sx={{}}  onClick={()=>execute2()}>Import Wallet</Button>
  </Grid>
     </Grid>
 </Box>
  )
}

export default Wallet
