import { Box, Skeleton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useContext } from 'react';
import {caroselContex} from './CaroselContex';
import { useNavigate } from 'react-router-dom';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';


function GetStarted() {

  const navigate = useNavigate()



  return (
    <Box sx={{my:"2rem", px:"1rem"}}>
          <Typography  sx={{fontSize:"30px", color:"#fff", fontWeight:"700", transition:"0.5sec", msTransitionTimingFunction:"ease"}}>Get Started</Typography>
      <Box sx={{display:"flex", flexDirection:{xs:"column", sm:"row"}, my:"2rem", alignItems:"center", justifyContent:"center"}}>
        <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          <AccountBalanceWalletOutlinedIcon sx={{color:"#4A3B25",  backgroundColor:"#fff"}}/>
          <Typography sx={{color:"#1454D2", my:"1rem", fontWeight:"700"}}>Create an account</Typography>
          <Typography sx={{textAlign:"center", color:"#fff"}}>Once you have signedup up to a Nftyhills account. you have access to your dashboard showing your collected items and collections. Chat with an online representative if any difficulty </Typography>
        </Box>

        <Box sx={{ml:{sm:"1.5rem"}, my:"2.5rem",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          <DashboardCustomizeOutlinedIcon sx={{color:"#4A3B25", backgroundColor:"#fff"}}/>
          <Typography sx={{color:"#1454D2", my:"1rem", fontWeight:"700"}}>Start your project</Typography>
          <Typography  sx={{textAlign:"center", color:"#fff"}}> Fill the start a project form to give start your nft project filling all the right fields. Chat with an online representative if any difficulty</Typography>
        </Box>

        <Box sx={{ml:{sm:"1.5rem"},display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          <RocketLaunchOutlinedIcon sx={{color:"#4A3B25", backgroundColor:"#fff"}}/>
          <Typography sx={{color:"#1454D2", my:"1rem", fontWeight:"700"}}>Apply for launchpad</Typography>
          <Typography  sx={{textAlign:"center", color:"#fff"}}> Fill the apply for launchpad form to be part of our launchpad your nft project filling all the right fields. Chat with an online representative if any difficulty</Typography>
        </Box>


        <Box sx={{ml:{sm:"1.5rem"},my:"2.5rem",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          <CollectionsOutlinedIcon sx={{color:"#4A3B25", backgroundColor:"#fff"}}/>
          <Typography sx={{color:"#1454D2", my:"1rem", fontWeight:"700"}}>List your collection</Typography>
          <Typography  sx={{textAlign:"center", color:"#fff"}}> Fill the apply for listing form to kick start your nft project filling all the right fields. Chat with an online representative if any difficulty</Typography>
        </Box>
      </Box>
    </Box>
   
  )
}

export default GetStarted