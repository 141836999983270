import React, { useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import config from "../aws-exports";
import { createUser, updateUser } from "../graphql/mutations";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import HomeFooter from "./HomeFooter";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { usersByUsername } from "../graphql/queries";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import welcome from "../img/welcome.jpg"
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

const Edit = () => {
  const [image, setImage] = useState(null);
  const [backgroundimage2, setBackgroundImage2] = useState(null);
  const [user, setUser] = useState({})
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [accountDetails, setAccountDetails] = useState({
    username: "",
    email: "",
    twitter: "",
    description: "",
    images: null,
    backgroundimage: null,
  
  });
  const navigate = useNavigate();
  const [datett, setDatett] = useState("");
  const handlefileinput = useRef()



  useEffect(() => {
 
    var date = new Date();
    var datett = date.toDateString();

    setDatett(datett);
    fetchuser()
    fetchUserdata()

  
  }, []);


  async function uploadimage(){
    handlefileinput.current.click()
   }

  const  fetchuser= async() => {
    const user = await Auth.currentAuthenticatedUser();
      setUser(user)
   }

   const fetchUserdata = async () => {
    try {

      const user = await Auth.currentAuthenticatedUser();
      accountDetails.username = user.username;
      const { data } = await API.graphql({
        query: usersByUsername,
        variables: { username: user.username },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    
      const userdata = data.usersByUsername.items[0];
         setAccountDetails(userdata)
          
    } catch (err) {
      console.log(err);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

        const user = await Auth.currentAuthenticatedUser();
        accountDetails.username = user.username;
        const { data } = await API.graphql({
          query: usersByUsername,
          variables: { username: user.username },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        const users = data.usersByUsername.items[0];
        console.log(users)
      if (!accountDetails.username) return;

             users.description = accountDetails.description
             users.twitter = accountDetails.twitter
             users.email = accountDetails.email
             users.images = accountDetails.images
             users.backgroundimage =accountDetails.backgroundimage
      delete users.createdAt;
      delete users.updatedAt;
      console.log(accountDetails.description)
      console.log(users)
 
      await API.graphql({ query: updateUser,
        variables: { input:users} ,
        authMode: "AMAZON_COGNITO_USER_POOLS",}
       
       
      );
      console.log("updated successfully")
        navigate("/dashboard/collections")
    } catch (err) {
      console.log("error updating userprofile:", err);
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const extension = file.name.split(".")[1];
    const name = file.name.split(".")[0];
    const key = `images/${uuid()}${name}.${extension}`;
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`;
    try {
      // Upload the file to s3 with public access level.
      await Storage.put(key, file, {
        level: "public",
        contentType: file.type,
      });
      // Retrieve the uploaded file to display
      const image = await Storage.get(key, { level: "public" });
      setImage(image);
      setAccountDetails({ ...accountDetails, images: url });
    } catch (err) {
      console.log(err);
    }
  };


  
  const handleImageUpload2 = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const extension = file.name.split(".")[1];
    const name = file.name.split(".")[0];
    const key = `images/${uuid()}${name}.${extension}`;
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`;
    try {
      // Upload the file to s3 with public access level.
      await Storage.put(key, file, {
        level: "public",
        contentType: file.type,
      });
      // Retrieve the uploaded file to display
      const backgroundimage = await Storage.get(key, { level: "public" });
      setBackgroundImage2( backgroundimage);
      setAccountDetails({ ...accountDetails, backgroundimage: url });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Navbar />
      <Box sx={{ backgroundColor: "#120C18", pl: 2, pr: 2 }}>
        <Grid container>
          <Grid
            item
            xs={0}
            sm={2}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Sidebar />
          </Grid>

          <Grid item xs={12} sm={10}>
          <Box sx={{px:{xs:"1rem",sm:"3rem"}, py:"5rem", display:"flex", flexDirection:'column',height:"40vh" ,backgroundImage: accountDetails.backgroundimage ? `url(${ accountDetails.backgroundimage})` :`url(${welcome})` , backgroundRepeat:"no-repeat", width:"100%", backgroundSize:"100% 40vh", position:"relative"}} 
       >
    <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between",}}>
    
  
    <Box sx={{position:"absolute", zIndex:"10", top:"10px", right:"10px" ,}}     onMouseEnter={()=>{}}  onMouseLeave={()=>{}} >
  
    { <CameraAltOutlinedIcon  sx={{}} />}
  
   
    </Box>
  
  <Box sx={{position:"absolute", bottom:"-100px", zIndex:"5", display:"flex", flexDirection:"column", alignItems:"center"}}>
 {accountDetails.images ? <img src={accountDetails.images} style={{width:"100px", height:"100px", borderRadius:"10px", color:"#fff", border:"5px solid #fff"}} /> :<AccountBoxOutlinedIcon sx={{width:"100px", height:"100px", borderRadius:"50%", color:"#fff", }} alt=""/>} 
    <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}}><Typography sx={{color:"#fff", fontWeight:"bold"}}>{user.username}</Typography> <VerifiedOutlinedIcon sx={{color:"blue", fontSize:'16px'}}/></Box>
  <Box><Typography sx={{color:"#fff", fontSize:"12px"}}> {datett}</Typography></Box>
   
  
  </Box>
    
    
    </Box>


</Box>

<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-end", mt:"0.5rem"}}>
   <TwitterIcon  sx={{color:"#1DA1F2", fontSize:"24px", }}/>
    <FacebookIcon  sx={{color:"#3758A4", fontSize:"24px", ml:"0.5rem"}} />
    <InstagramIcon  sx={{color:"#9934B1", fontSize:"24px", ml:"0.5rem"}} />
   </Box>



          
          
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                px: "24px",
                backgroundColor: "#120C18",
                py: "3rem",
                mt:"6rem"
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "40%" },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <form onSubmit={handleSubmit}>
                  {image ? (
                    <Box></Box>
                  ) : (
                    <input
                      ref={handlefileinput}
                      type="file"
                      accept="image/jpg"
                      style={{ marginBottom: "1.5rem", color: "#fff" }}
                      onChange={(e) => handleImageUpload(e)}
                    />
                  )}


{backgroundimage2 ? (
                    <Box></Box>
                  ) : (
                    <input
                      type="file"
                      accept="image/jpg"
                      style={{ marginBottom: "1.5rem", color: "#fff" }}
                      onChange={(e) => handleImageUpload2(e)}
                    />
                  )}

                  <TextField
                    id="filled-basic"
                    label="Bio"
                    variant="filled"
                    name="Bio"
                    required
                    multiline
                    rows={4}
                    onChange={(e) =>
                      setAccountDetails({
                        ...accountDetails,
                        description: e.target.value,
                      })
                    }
                    sx={{
                      backgroundColor: "#ffffff",

                      width: "100%",
                      marginBottom: "1.5rem",
                      borderRadius: "5px",
                      height: "50px",
                    }}
                  />

                  <TextField
                    id="filled-basic"
                    label="Email"
                    variant="filled"
                    required
                    name="email"
                    type="email"
                    onChange={(e) =>
                      setAccountDetails({
                        ...accountDetails,
                        email: e.target.value,
                      })
                    }
                    sx={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      marginBottom: "1.5rem",
                      borderRadius: "5px",
                    }}
                  />

                  <TextField
                    id="filled-basic"
                    label="Twitter"
                    variant="filled"
                    name="twitter"
                    onChange={(e) =>
                        setAccountDetails({
                          ...accountDetails,
                          twitter: e.target.value,
                        })
                      }
                    sx={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      marginBottom: "1.5rem",
                      borderRadius: "5px",
                    }}
                  />

                  <TextField
                    id="filled-basic"
                    label="Discord"
                    variant="filled"
                    name="discord"
                    sx={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      marginBottom: "1.5rem",
                      borderRadius: "5px",
                    }}
                  />

                  <TextField
                    id="filled-basic"
                    label="Instagram"
                    variant="filled"
                    name="instagram"
                    sx={{
                      backgroundColor: "#ffffff",
                      width: "100%",
                      marginBottom: "1.5rem",
                      borderRadius: "5px",
                    }}
                  />

                  <Button
                    type="submit"
                    sx={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      height: "3.5rem",
                      border: "6px",
                      padding: "4px  1.5rem",
                      backgroundColor: "#2B2B30",
                      fontWeight: "700",
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Box>

            <HomeFooter />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Edit;
